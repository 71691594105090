import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid, Header, HeaderSubheader, Icon, Image, Modal } from 'semantic-ui-react';
import logotgver2 from '../assets/tglogov5_small.png';

import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../ConnectWS';

import useWindowDimensions from '../hooks/useWindowDimensions';
import AuthContext from '../context/authContext';
import brain from "../assets/move/brain.mp4";

function AIGetStatsEX(props) {
    const [open, setOpen] = useState(false)
    const [openNotAi, setOpenNotAi] = useState(false)
    const { addToast } = useToasts();
    const [loading, setloading] = useState(false)

    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const [isGenOK, setisGenOK] = useState(false)
    const [isNotValid, setisNotValid] = useState(true)
    const [match_id, setmatch_id] = useState(0)
    const [subtournament_id, setsubtournament_id] = useState(0)
    const [ai_info, setai_info] = useState(null)

    const [isAS, setisAS] = useState(false)

    const [isFile, setisFile] = useState(false)

    function checkIsFileUplad(p) {
        try {
            if (p.row.match_info.match_report.value) {
                return true

            }
            return false
        } catch (e) {
            return false
        }
    }
    //ai/getJsonFromFile -> prompt, subtournament_id, match_id
    useEffect(() => {
        checkSubIsActiv()
        if (open) {


            setisGenOK(false)
            setisNotValid(true)
            setmatch_id(props.row.subtournament_league_match_id)
            setsubtournament_id(props.subtournament_id)
            setisFile(checkIsFileUplad(props))
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [open])// eslint-disable-line react-hooks/exhaustive-deps

    function isMobile() {
        return width < 600
    }

    function checkSubIsActiv() {
        try {
            
            if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
                setisAS(true)
            } else {
                setisAS(false)
            }
        } catch (e) {
            setisAS(false)
        }
    }

    function clearValueData(match_details_ex){
        try{
            let jsonTmp = match_details_ex
            if (match_details_ex && match_details_ex.members ) {
                for (const [key] of Object.entries(match_details_ex.members)) {
                    if(jsonTmp.members[key].team1_members.length>0){
                        jsonTmp.members[key].team1_members.forEach(element => 
                            element.value = null
                        );
                        
                    }
                    if(jsonTmp.members[key].team2_members.length>0){
                        jsonTmp.members[key].team2_members.forEach(element => 
                            element.value = null
                        );
                        
                    }
                    
                }
    
    
            }
        
            
            return jsonTmp
        }catch(e){
            console.log(e)
            return match_details_ex
        }
    }

    function sendDataAI() {

        let expected_result_data = null
        try {
            if (props.match_details_ex && props.match_details_ex.version === 2 && props.match_details_ex.members) {
                expected_result_data = clearValueData(props.match_details_ex)
            } else if (props.match_details_ex && props.match_details_ex.members) {
                addToast("Hmm wyglada na to, że wersja wprowadzonych statystyk nie jest wspierana!.", { appearance: 'warning' });
                addToast("Jeżeli chcesz przeanalizować ten pojedynek, kliknij przycisk RESET STATYSTYK.", { appearance: 'info' });
                setTimeout(() => {
                    setOpen(false)
                }, 2000);
                return
            }

        } catch (e) {
            console.log(e)
        }

      
        setloading(true)
        setai_info(null)
        ConnectWS("/ai/getJsonFromFile", authContext.token, { subtournament_id: subtournament_id, match_id: match_id, res_timeout: 120, expected_result_data: expected_result_data }, function (response, logout) {
            if (logout === 1) {
            } else if (response && response.status && Number(response.status) === 200) {


                if(response.data.dataJson && response.data.fiba){
                    setisNotValid(false)
                    props.setmatch_details_ex({fiba:response.data.fiba,dataJson:response.data.dataJson})
                    setTimeout(() => {
                        setOpen(false)
                    }, 1500);
                }else if (response.data.dataJson && response.data.expectedResultData) {
                   // let objdiff = getObjDifferences(response.data.dataJson, response.data.expectedResultData, [], '')
                    console.log(response.data.jsonDiff)
                    if (response.data.jsonDiff === null) {
                        setisNotValid(false)
                        props.setmatch_details_ex(response.data.dataJson)
                        setTimeout(() => {
                            setOpen(false)
                        }, 2500);
                    } else {
                        setai_info(response.data.dataText)
                        setisNotValid(true)
                    }

                } else {
                    setai_info(response.data.dataText)
                    setisNotValid(true)
                }
                setisGenOK(true)
                setloading(false)


            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)


        });

    }







    return (

        <> <div style={{ backgroundColor: "#0a4450", borderRadius: 5, padding: 5, height: 200 }}>
            {open || openNotAi ? "" :
                <div className='myVideoAI' style={{ cursor: "pointer" }} onClick={() => (authContext && authContext.user && authContext.user.ai) || isAS  ? setOpen(true) : setOpenNotAi(true)} >
                    <video style={{ borderRadius: 5 }} src={brain} autoPlay loop muted />
                    <div className="content2" style={{ borderRadius: 5 }}>
                        AI - Wygeneruj statystyki na podstawie protokołu
                    </div>
                </div>
            }
        </div>

            <Modal
                onOpen={() => setOpen(true)}
                open={open}
                centered={true}
            >
                <Modal.Header style={{ backgroundColor: "#061f24" }}>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center' style={{ color: "#fff" }}>
                                    <Icon name='cogs' />
                                    <Header.Content style={{ fontSize: isMobile() ? 13 : 18, lineHeight: "20px" }}>AI Turniej-Generator</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" >
                                <Image alt="logo" floated='right' size='big' centered src={logotgver2} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>
                <Modal.Content style={{ padding: 0, margin: 0, height: 500, backgroundColor: "#072c34" }} >

                    <div className='myVideoAI'>
                        <video src={brain} autoPlay loop muted />
                        <div className="content">
                            {isFile ?
                                loading ?
                                    <div> <Header as='h2' icon style={{ color: "#fff" }}>
                                        <Icon name='spinner' loading />
                                        Oczekiwanie na AI Turniej-Generator
                                        <HeaderSubheader style={{ color: "#fff" }}>
                                            A teraz dzieje się magia...

                                        </HeaderSubheader>
                                    </Header></div>
                                    : isGenOK ?
                                        isNotValid ?
                                            <div> <Header as='h2' icon style={{ color: "#fff" }}>
                                                <Icon name='warning sign' />
                                                Hmm, coś poszło nie tak
                                                <HeaderSubheader style={{ color: "#fff" }}>
                                                    {ai_info ? <div style={{ fontSize: 12, width: "80%", margin: "0 auto", marginTop: 10, marginBottom: 10, maxHeight: 100, overflow: "auto", fontStyle: "italic" }}>{ai_info}</div> : ""}
                                                    Myślę, że możemy spróbować jeszcze raz
                                                    <br />
                                                    <Button onClick={() => sendDataAI()} style={{ marginTop: 10, }} color='blue'>AI - ANALIZUJ PROTOKÓŁ</Button>

                                                </HeaderSubheader>
                                            </Header></div>
                                            : <div> <Header as='h2' icon style={{ color: "#fff" }}>
                                                <Icon name='check circle outline' color='green' />
                                                Statystyki zostały wygenerowane
                                                <HeaderSubheader style={{ color: "#fff" }}>
                                                    Zaraz zostaniesz przeniesiony do wcześniejszego okna w celu ich weryfikacji...

                                                </HeaderSubheader>
                                            </Header></div>
                                        :
                                        <div> <Header as='h2' icon style={{ color: "#fff" }}>
                                            <Icon name='check circle outline' />
                                            Protokół meczowy jest dodany
                                            <HeaderSubheader style={{ color: "#fff" }}>
                                                Jeszcze tylko jeden klik...
                                                <br />
                                                <Button onClick={() => sendDataAI()} style={{ marginTop: 10 }} color='blue'>AI - ANALIZUJ PROTOKÓŁ</Button>
                                            </HeaderSubheader>
                                        </Header></div>
                                :
                                <div> <Header as='h2' icon style={{ color: "#fff" }}>
                                    <Icon name='info circle' />
                                    Brak Protokołu dodanego do tego pojedynku
                                    <HeaderSubheader style={{ color: "#fff" }}>
                                        Proszę dodać Protokół

                                    </HeaderSubheader>
                                </Header></div>}

                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: "#061f24" }}>
                    <Grid>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column width={13}>
                                <div style={{ color: "#2c7b8a", textAlign: "left", fontSize: 12 }}>*** Moduł analizy protokołów oparty na technologii AI jest obecnie w fazie rozwoju.
                                    <br /> W przypadku napotkania jakichkolwiek nieprawidłowości prosimy o kontakt.
                                    <br />  Dziękujemy za pomoc w doskonaleniu naszego rozwiązania!  </div>

                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Button size={'mini'} style={{ backgroundColor: "#2c7b8a" }} fluid disabled={loading} onClick={() => setOpen(false)}>
                                    ZAMKNIJ
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Actions>
            </Modal >
            <Modal
                onOpen={() => setOpenNotAi(true)}
                open={openNotAi}
                centered={true}
            >
                <Modal.Header style={{ backgroundColor: "#061f24" }}>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center' style={{ color: "#fff" }}>
                                    <Icon name='cogs' />
                                    <Header.Content style={{ fontSize: isMobile() ? 13 : 18, lineHeight: "20px" }}>AI Turniej-Generator 1.0</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" >
                                <Image alt="logo" floated='right' size='big' centered src={logotgver2} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>
                <Modal.Content style={{ padding: 0, margin: 0, height: 500, backgroundColor: "#072c34" }} >

                    <div className='myVideoAI'>
                        <video src={brain} autoPlay loop muted />
                        <div className="content">
                            <Header as='h2' icon style={{ color: "#fff" }}>
                                <Icon name='warning sign' />
                                
                                Brak dostępu do funkcjonalności
                                <HeaderSubheader style={{ color: "#fff" }}>
                                Dostęp do AI – tylko w SUBSKRYPCJI PLUS
                                </HeaderSubheader>
                            </Header>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: "#061f24" }}>

                    <Button size={'mini'} disabled={loading} onClick={() => setOpenNotAi(false)}>
                        ZAMKNIJ
                    </Button>
                </Modal.Actions>
            </Modal>


        </>

    )
}

export default AIGetStatsEX;