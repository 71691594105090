import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Card, Container, Form, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';

import "cropperjs/dist/cropper.css";
import { ConnectWS, ConnectWSSendData } from '../ConnectWS';

import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { checkSizeFile, loadingShow, normalizeStringURLIMG } from '../myfunctions';
import MyImageLazy from './MyImageLazy';
import { confirmAlert } from 'react-confirm-alert';



function AddImgMediaUser({ imgUrlTG, setimgUrlTG }) {
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false)
    const authContext = useContext(AuthContext);
    const [loading, setloading] = useState(false);
    const [isLoadingFile, setLoadingFile] = useState(false);

    const [addFileTG, setaddFileTG] = useState(false);
    const [arrMediaData, setarrMediaData] = useState([]);

    const [form, setForm] = useState({
        InputPhoto: "",
        InputPhotoName: "",
    });

    useEffect(() => {

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    function clickOpenModalF(value) {
        if (value) {
            // modal jest otwarty
            getUserGallery()
        } else {

        }
        setOpen(value)
    }

    function getUserGallery() {
        setloading(true)
        ConnectWS("/media/getUserMedia", authContext.token, {file_type:"IMG"}, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

            } else if (response && response.status && Number(response.status) === 200) {

                if (response.data && response.data.length > 0) {
                    setarrMediaData(response.data)
                } else {
                    setarrMediaData([])
                }

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }

            }

            setloading(false)


        });

    }
    const fileInputRef = useRef(null)
    const clearImageChange = () => {

        setForm({ ...form, InputPhoto: null })
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

    }

    const compressImage = async (file, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(imageBitmap, 0, 0);

        // Turn into Blob
        return await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );
    };

    const onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let imgtmp = event.target.files[0]
            let imgtmp1 = await compressImage(imgtmp, {
                // 0: is maximum compression
                // 1: is no compression
                quality: 0.5,
                // We want a JPEG file
                type: 'image/jpeg',
            })
            let imgtmp2 = await compressImage(imgtmp, {
                // 0: is maximum compression
                // 1: is no compression
                quality: 0.3,
                // We want a JPEG file
                type: 'image/jpeg',
            })

            let filename
            try {
                filename = normalizeStringURLIMG(imgtmp.name)
            } catch (e) {
                filename = "Zdjęcie" + new Date().getTime();
            }


            if (checkSizeFile(imgtmp)) {
                setForm({ ...form, InputPhoto: URL.createObjectURL(imgtmp), mtype: "InputPhoto", InputPhotoName: filename })
            } else if (checkSizeFile(imgtmp1)) {
                addToast("Rozmiar przekracza " + Number(process.env.REACT_APP_IN_SIZEFILEKB) + " KB, zmieniam jakoś obrazka v1!", { appearance: 'info' });
                setForm({ ...form, InputPhoto: URL.createObjectURL(imgtmp1), mtype: "InputPhoto", InputPhotoName: filename })
            } else if (checkSizeFile(imgtmp2)) {
                addToast("Rozmiar przekracza " + Number(process.env.REACT_APP_IN_SIZEFILEKB) + " KB, zmieniam jakoś obrazka v2!", { appearance: 'info' });
                setForm({ ...form, InputPhoto: URL.createObjectURL(imgtmp2), mtype: "InputPhoto", InputPhotoName: filename })
            } else {
                addToast("Rozmiar pliku przekracza " + Number(process.env.REACT_APP_IN_SIZEFILEKB) + " KB", { appearance: 'error' });
                clearImageChange()
            }
        }
    }


    async function saveDataDB() {
        const formData = new FormData();
        let file = null
        if (form.InputPhoto === null || form.InputPhoto === "") {

        } else {

            await fetch(form.InputPhoto, file)
                .then(res => res.blob())
                .then(blob => {
                    file = new File([blob], form.InputPhotoName, { type: "image/png" })
                })
        }

        formData.append('fileData', file);
        formData.append('file_type', "IMG");
        setLoadingFile(true)
        ConnectWSSendData("/media/addFileToUserMedia", authContext.token, formData, function (response, logout) {
            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                getUserGallery()
                setaddFileTG(false)
                clearImageChange()
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }

            }

            setLoadingFile(false)

        });
    }


    function chooseFileUrl(url) {
        setimgUrlTG(url)
        clickOpenModalF(false)
    }


    function removeFile(file_id) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować plik?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeFileAcc(file_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });
        function removeFileAcc(file_id) {
            ConnectWS("/media/removeFileFromUserMedia", authContext.token, { file_id: file_id,file_type:"IMG" }, function (response, logout) {

                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    getUserGallery()


                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }

                }

                setloading(false)


            });
        }
    }

    return (
        <>
            <Modal
                onClose={() => ""}
                onOpen={() => clickOpenModalF(true)}
                open={open}
                size='large'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='file image' color="red" />
                                    <Header.Content>Multimedia</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }} scrolling>

                    <Modal.Description >

                        {loading ? loadingShow() : addFileTG ? <>

                            <Form>
                                <Form.Group widths='equal'>
                                    <Button
                                        content="Wybierz plik graficzny/plakat"
                                        labelPosition="left"
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                        fluid
                                        size='mini'
                                        icon="file"
                                        type="button"
                                        onClick={() => fileInputRef.current.click()}
                                    />
                                   
                                </Form.Group>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={onImageChange}
                                />
                                <Container textAlign='center'>
                                    <label>{form.InputPhoto ? "" : "Brak wybranego pliku"}</label>
                                    <Image style={{ margin: "0 auto" }} src={form.InputPhoto ? form.InputPhoto : "https://turniej-generator.pl/img/noimg.png"} size='large' alt="logo" />
                                </Container>
                                <br />
                                <Button type="button" loading={isLoadingFile} disabled={isLoadingFile || !form.InputPhoto} style={{ marginTop: 10, marginBottom: 10 }} color='brown' fluid onClick={() => saveDataDB()} >DODAJ PLIK DO MULTIMEDIÓW</Button>
                            </Form>
                        </> : <>

                            {arrMediaData ? <Card.Group style={{ marginTop: 20 }} centered>
                                {arrMediaData.map((row, i) => <Card key={i}>
                                    <Card.Content textAlign='center' style={{position:"relative"}}>
                                        <Icon className='cursorPointer' size='large' onClick={() => removeFile(row.file_id)} name="trash" style={{position:"absolute",top:5,right:0}} color='brown' title="Skasuj plik" />    
                                        <MyImageLazy openView={true} maxHeight={100} src={process.env.REACT_APP_URL_MEDIA + row.file_url} />
                                        <Card.Meta>{row.file_name}</Card.Meta>
                                    </Card.Content>
                                    <Card.Content extra >
                                        
                                            <Button fluid size='mini' color='blue'  onClick={() => chooseFileUrl(process.env.REACT_APP_URL_MEDIA + row.file_url)}>
                                                WYBIERAM
                                            </Button>
                                            
                                      
                                    </Card.Content>
                                </Card>)}
                            </Card.Group> : ""}
                        </>}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {addFileTG ?
                        <Button floated='left' color='blue' onClick={() => setaddFileTG(false)}>POWRÓT DO GALERII</Button>
                        :
                        <Button floated='left' color='blue' onClick={() => setaddFileTG(true)}>DODAJ PLIK DO MULTIMEDIÓW</Button>
                    }
                    <Button color='black' onClick={() => {
                        clickOpenModalF(false)
                    }}>
                        ZAMKNIJ
                    </Button>

                </Modal.Actions>
            </Modal>
            {open ? "" : <Button size='mini' onClick={() => clickOpenModalF(true)} color='grey'>MULTIMEDIA</Button>}


        </>
    )
}

export default AddImgMediaUser;