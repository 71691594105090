import { Grid, Icon, Table } from "semantic-ui-react";
import { checkDateBeforeDay, getStosunekPTK, ifNullDate } from "./myfunctions";
import { getValueData, getValueDataFibo, getValueDataVer2 } from "./tools/StatsEx/StatsEx";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MyImageLazy from "./tools/MyImageLazy";

export function getTopXMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 1) {
                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , member1_id: element2.team1_member_id
                                , member2_id: element2.team2_member_id
                            })
                        }

                    });
                }
            });
        });



        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file, team_photo_file: element.team_photo_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_ratio: 0,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0

                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {
                    if (element.member_id === element1.member1_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.member_id === element1.member2_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small

            })
            return tmpMT.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small))
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}



export function getTopXPtkMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null) {
                            tmpM.push({ ...element2, details_score: null })
                        }

                    });
                }
            });
        });



        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points: 0, total_points_matches_single: 0, total_points_winners_single: 0, total_points_losers_single: 0,
                        total_points_matches_debel: 0, total_points_winners_debel: 0, total_points_losers_debel: 0

                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points = 0
                let total_points_matches_single = 0
                let total_points_winners_single = 0
                let total_points_losers_single = 0
                let total_points_matches_debel = 0
                let total_points_winners_debel = 0
                let total_points_losers_debel = 0

                tmpM.forEach(element1 => {

                    if (element.member_id === element1.team1_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_single++
                        }

                    } else if ((element.member_id === element1.team1_member1_id || element.member_id === element1.team1_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_debel++
                        }

                    } else if (element.member_id === element1.team2_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        }
                    } else if ((element.member_id === element1.team2_member1_id || element.member_id === element1.team2_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        }
                    }

                })
                element.total_points = total_points
                element.total_points_matches_debel = total_points_matches_debel
                element.total_points_losers_debel = total_points_losers_debel
                element.total_points_winners_debel = total_points_winners_debel
                element.total_points_matches_single = total_points_matches_single
                element.total_points_winners_single = total_points_winners_single
                element.total_points_losers_single = total_points_losers_single
            })

            return tmpMT.sort((a, b) => b.total_points - a.total_points)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}

export function getTopXDebelMembers(jsonData, jsonDataMembers, countMembers) {
    try {
        function getHashM(member1_id, member2_id) {
            return member1_id + "_" + member2_id
        }

        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 2) {

                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , t1_member1_id: element2.team1_member1_id
                                , t1_member2_id: element2.team1_member2_id
                                , t2_member1_id: element2.team2_member1_id
                                , t2_member2_id: element2.team2_member2_id
                                , t1_hash: element2.team1_member1_id + "_" + element2.team1_member2_id
                                , t2_hash: element2.team2_member1_id + "_" + element2.team2_member2_id
                            })
                        }

                    });
                }
            });
        });


        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_name: element.name, team_img: element.img_file, team_id: element.member_id,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_ratio: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        // petla po zawodnikach deblowych z kasowaniem dubli same unikalne wartości
        let memberOfDebel = []
        tmpM.forEach(element => {
            if (!memberOfDebel.filter(function (item) { return item.thash === getHashM(element.t1_member1_id, element.t1_member2_id) || item.thash === getHashM(element.t1_member2_id, element.t1_member1_id) }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t1_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t1_member2_id; })

                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t1_hash, member1_id: element.t1_member1_id, member2_id: element.t1_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id
                    })
                }

            }

            if (!memberOfDebel.filter(function (item) { return item.thash === getHashM(element.t2_member1_id, element.t2_member2_id) || item.thash === getHashM(element.t2_member2_id, element.t2_member1_id) }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t2_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t2_member2_id; })
                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t2_hash, member1_id: element.t2_member1_id, member2_id: element.t2_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id

                    })
                }
            }


        });


        if (memberOfDebel.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            memberOfDebel.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0
                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {

                    if (element.thash === getHashM(element1.t1_member1_id, element1.t1_member2_id) || element.thash === getHashM(element1.t1_member2_id, element1.t1_member1_id)) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.thash === getHashM(element1.t2_member1_id, element1.t2_member2_id) || element.thash === getHashM(element1.t2_member2_id, element1.t2_member1_id)) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
            })

            return memberOfDebel.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small)).slice(0, countMembers)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}

export function getMembersStatsAllFiba(jsonData, jsonDataMembers) {
    try {
        
        let tmpM = []
        // tworzymy tablicę statsow
        let indexTab = 0

        if (jsonData
            && jsonData.length > 0
            && jsonData[jsonData.length - 1].matches
            && jsonData[jsonData.length - 1].matches.length > 0
            && jsonData[jsonData.length - 1].matches[0].stats_cfg
        ) {
            indexTab = jsonData.length - 1
            for (const key in jsonData[indexTab].matches[0].stats_cfg) {
                if (jsonData[indexTab].matches[0].stats_cfg[key].showInMembers === true) {
                    tmpM.push({ key: key, newkey: key, sort: jsonData[indexTab].matches[0].stats_cfg[key].sort, value: 0,showTop:jsonData[indexTab].matches[0].stats_cfg[key].showTop, show_chart: jsonData[indexTab].matches[0].stats_cfg[key].showChart })
                }
            }

        }
      
        let tmpMT = []
        // lista membersów w drużynie
 
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file, team_photo_file: element.team_photo_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        member_team_id: element1.member_team_id,
                        stats: tmpM.sort((a, b) => a.sort - b.sort),
                    })
                });
            }

        });
     
        if (tmpMT.length > 0 && jsonData && tmpM.length > 0) {
           
            // loop po zawodnikach
            let tmpMTtmp = JSON.parse(JSON.stringify(tmpMT));

            tmpMT.forEach((element, j) => {

                // loop po stats
                element.stats.forEach((elementStats, i) => {
                    //count_assist oraz konkretny members
               
                    jsonData.forEach(jsonDataElement => {
                        jsonDataElement.matches.forEach(jsonDataElementMatches => {
                            
                            if (jsonDataElementMatches.match_details_ex && jsonDataElementMatches.match_details_ex.fiba && jsonDataElementMatches.match_details_ex.dataJson) {
                                if (jsonDataElementMatches.match_details_ex.dataJson.team1_id !== null && jsonDataElementMatches.match_details_ex.dataJson.team2_id !== null) {
                                  
                                    if (jsonDataElementMatches.match_details_ex.dataJson.team1_id === element.team_id) {
                                        
                                        tmpMTtmp[j].stats[i].value = getValueDataFibo(tmpMTtmp[j].member_id, jsonDataElementMatches.match_details_ex.dataJson.team1_members, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                    }

                                    if (jsonDataElementMatches.match_details_ex.dataJson.team2_id === element.team_id) {

                                        tmpMTtmp[j].stats[i].value = getValueDataFibo(tmpMTtmp[j].member_id, jsonDataElementMatches.match_details_ex.dataJson.team2_members, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                    }




                                }



                            }
                        })
                    })


                })


            })

            // sortowanie po ostatniej statystyce
            return tmpMTtmp.sort((a, b) => b.stats[a.stats.length - 1].value - a.stats[a.stats.length - 1].value)
        }
        else {
            return []
        }



    } catch (e) {
        return []
    }
}


export function getMembersStatsAll(jsonData, jsonDataMembers) {
    try {

        // sprwadzamy jaki rodzaj statystyk
        let fiba = false
        if (jsonData
            && jsonData.length > 0
            && jsonData[jsonData.length - 1].matches
            && jsonData[jsonData.length - 1].matches.length > 0
            && jsonData[jsonData.length - 1].matches[0].match_details_ex
            && jsonData[jsonData.length - 1].matches[0].match_details_ex
        ) {

            if (jsonData[jsonData.length - 1].matches[0].match_details_ex.fiba === true) {
                fiba = true
            }
        }

        if (fiba) {
            return getMembersStatsAllFiba(jsonData, jsonDataMembers)
        } else {



            function getSortValue(key, value) {
                // zabezpieczenie ponieważ punkty i bramki musza być na koncu
                if (key === "points_scored") {
                    return 101
                } else if (key === "points_scored_goals") {
                    return 100
                }
                return value
            }

            let tmpM = []
            // tworzymy tablicę statsow
            let indexTab = 0
            if (jsonData
                && jsonData.length > 0
                && jsonData[jsonData.length - 1].matches
                && jsonData[jsonData.length - 1].matches.length > 0
                && jsonData[jsonData.length - 1].matches[0].match_details_ex
                && jsonData[jsonData.length - 1].matches[0].match_details_ex.members
            ) {
                indexTab = jsonData.length - 1
                for (const key in jsonData[indexTab].matches[0].match_details_ex.members) {
                    if (key === 'matche_cards') {
                        //yellow: 0, yr: 0, red: 0
                        tmpM.push({ key: key, newkey: "matche_cards_yellow", sort: jsonData[indexTab].matches[0].match_details_ex.members[key].sort + 0.1, value: 0, show_chart: jsonData[indexTab].matches[0].match_details_ex.members[key].show_chart })
                        tmpM.push({ key: key, newkey: "matche_cards_yr", sort: jsonData[indexTab].matches[0].match_details_ex.members[key].sort + 0.2, value: 0, show_chart: jsonData[indexTab].matches[0].match_details_ex.members[key].show_chart })
                        tmpM.push({ key: key, newkey: "matche_cards_red", sort: jsonData[indexTab].matches[0].match_details_ex.members[key].sort + 0.3, value: 0, show_chart: jsonData[indexTab].matches[0].match_details_ex.members[key].show_chart })
                    } else {
                        if (jsonData[indexTab].matches[0].match_details_ex.members[key].hide_viewers === true) {

                        } else {
                            tmpM.push({ key: key, newkey: key, sort: getSortValue(key, jsonData[indexTab].matches[0].match_details_ex.members[key].sort), value: 0, show_chart: jsonData[indexTab].matches[0].match_details_ex.members[key].show_chart })
                        }

                    }

                }

            }




            let tmpMT = []
            // lista membersów w drużynie

            jsonDataMembers.forEach(element => {
                if (element.members_list) {
                    element.members_list.forEach(element1 => {
                        tmpMT.push({

                            team_id: element.member_id, team_name: element.name, team_img: element.img_file, team_photo_file: element.team_photo_file,
                            member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                            member_team_id: element1.member_team_id,
                            stats: tmpM.sort((a, b) => a.sort - b.sort),
                        })
                    });
                }

            });



            if (tmpMT.length > 0 && jsonData && tmpM.length > 0) {
                // loop po zawodnikach
                let tmpMTtmp = JSON.parse(JSON.stringify(tmpMT));

                tmpMT.forEach((element, j) => {

                    // loop po stats
                    element.stats.forEach((elementStats, i) => {
                        //count_assist oraz konkretny members



                        jsonData.forEach(jsonDataElement => {
                            jsonDataElement.matches.forEach(jsonDataElementMatches => {

                                if (jsonDataElementMatches.match_details_ex && jsonDataElementMatches.match_details_ex.members) {

                                    if (jsonDataElementMatches.match_details_ex.members[elementStats.key] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_id === element.team_id) {
                                        try {

                                            if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value) {
                                                tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                            } else if (jsonDataElementMatches.match_details_ex.version === 2) {
                                                tmpMTtmp[j].stats[i].value = getValueDataVer2(element, jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value)

                                            }
                                        } catch (e) {
                                            if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                                console.log(e)
                                            }
                                        }

                                    } else if (jsonDataElementMatches.match_details_ex.members[elementStats.key] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_id === element.team_id) {
                                        try {

                                            if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value) {
                                                tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                            } else if (jsonDataElementMatches.match_details_ex.version === 2) {
                                                tmpMTtmp[j].stats[i].value = getValueDataVer2(element, jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value)

                                            }
                                        } catch (e) {
                                            if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                                console.log(e)
                                            }
                                        }

                                    }

                                }
                            })
                        })


                    })


                })

                // sortowanie po ostatniej statystyce
                return tmpMTtmp.sort((a, b) => b.stats[a.stats.length - 1].value - a.stats[a.stats.length - 1].value)
            }
            else {
                return []
            }
        }

    } catch (e) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(e)
        }
        return []
    }
}


export function getMembersStatsLeague(member_id, jsonData) {
    try {

        if (jsonData.matches) {



            let total_points_winners = 0
            let total_points_losers = 0
            let total_points_winners_small = 0
            let total_points_losers_small = 0
            // tworzymy tablicę wszystkich pojedynków
            jsonData.matches.forEach(element => {
                if (element.matche_ts) {
                    element.matche_ts.forEach(element1 => {
                        if (member_id === element1.team1_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_losers++
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                            }

                        } else if (member_id === element1.team2_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_losers++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            }
                        }
                    });
                }

            });

            return { matches: { win: total_points_winners, lose: total_points_losers }, points: { win: total_points_winners_small, lose: total_points_losers_small } }
        } else {
            return null
        }

    } catch (e) {
        console.log(e)
        return null
    }
}


export function getupComingMatches(json, countDay) {
    try {
        let tmp = []
        if (json && json.length > 0) {
            // runda
            json.forEach((row) => {
                // mecz
                if (row.matches && row.matches.length > 0) {
                    row.matches.forEach((rowm) => {
                        // sprwadzamy czy jest czas ustawiony

                        if (rowm.match_date && checkDateBeforeDay(rowm.match_date, countDay) && rowm.member1_score === null) {

                            tmp.push({ ...rowm, league_revenge_round: row.league_revenge_round, round_nr: row.round_nr })
                        }

                    })
                }

            })
        }
        if (tmp.length > 0) {
            return tmp.sort((a, b) => ifNullDate(a.match_date) - ifNullDate(b.match_date))
        }
        return tmp
    } catch (e) {
        console.log(e)
        return []
    }
}

export function getPtkMembersInMatche(teamName, teamAndMembers, tournament_json, isDarkTheme, width) {
    try {
        function getScoreSum(member_id, arr) {
            try {
                let ret = 0
                arr.forEach(element => {
                    if (element.match_details_ex && element.match_details_ex.matche_ts) {
                        ret = ret + Number(getScore(member_id, element.match_details_ex))
                    }
                });
                return ret
            } catch (e) {
                return 0
            }
        }

        function getScore(member_id, stats) {
            let ret = 0
            let ok = false
            stats.matche_ts.forEach(element => {
                if (element.team1_member_id === member_id && element.type === 1) {
                    ok = true
                    if (element.score_member1 > element.score_member2) {
                        ret++
                    }
                } else if (element.team2_member_id === member_id) {
                    ok = true
                    if (element.score_member1 < element.score_member2) {
                        ret++
                    }
                } else if (element.type === 2 && (element.team1_member1_id === member_id || element.team1_member2_id === member_id)) {
                    ok = true
                    if (element.score_member1 > element.score_member2) {
                        ret = ret + 0.5
                    }
                } else if (element.type === 2 && (element.team2_member1_id === member_id || element.team2_member2_id === member_id)) {
                    ok = true
                    if (element.score_member1 < element.score_member2) {
                        ret = ret + 0.5
                    }
                }
            });

            return ok ? ret : ""
        }


        function getInicjaly(name) {
            try {
                let sp = name.split(" ")
                return sp[0].charAt(0) + sp[1].charAt(0)
            } catch (e) {
                return ""
            }

        }

        let arrMatches = []
        if (tournament_json) {
            tournament_json.forEach(element => {
                element.matches.forEach(element1 => {
                    if (element1.member1_name === teamName && element1.member2_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member2_name, scoreW: element1.member1_score, scoreL: element1.member2_score, score: element1.member1_score !== null ? element1.member1_score + ":" + element1.member2_score : "", status_m: getMi(element1.member1_score, element1.member2_score), match_details_ex: element1.match_details_ex, member_id: element1.member1_id })
                    } else if (element1.member2_name === teamName && element1.member1_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member1_name, scoreW: element1.member2_score, scoreL: element1.member1_score, score: element1.member1_score !== null ? element1.member2_score + ":" + element1.member1_score : "", status_m: getMi(element1.member2_score, element1.member1_score), match_details_ex: element1.match_details_ex, member_id: element1.member2_id })
                    }
                });
            });
        }

        // console.log(arrMatches, teamName, teamAndMembers, tournament_json)
        if (arrMatches.length > 0) {
            let imgTeamPhoto = getImageTeam(teamName, teamAndMembers)
            return <>

                <Grid unstackable>
                    <Grid.Row verticalAlign="middle" >
                        {imgTeamPhoto ? <Grid.Column textAlign="center" width={width < 900 ? 16 : 5}> <MyImageLazy openView={true} maxHeight={400} src={imgTeamPhoto} />  </Grid.Column> : ""}
                        <Grid.Column width={width < 900 || imgTeamPhoto === null ? 16 : 11}>
                            {getChartsMIT(isDarkTheme, arrMatches, teamName, width, imgTeamPhoto)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <div className='tablebs' style={{ marginTop: 50, marginBottom: 50, overflowX: "auto", width: "100%" }} >
                    <Table style={{ fontSize: 13, lineHeight: "11px" }}
                        size='small'
                        inverted={isDarkTheme} celled structured striped compact unstackable >
                        <Table.Header >

                            <Table.Row>
                                <Table.HeaderCell width={1} textAlign='center'>NR</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' singleLine >PRZECIWNIK</Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, j) => line1.total_points_matches > 0 ? <Table.HeaderCell width={1} textAlign='center'>{getInicjaly(line1.member_name)}<span style={{ fontSize: 11 }}>({j + 1})</span></Table.HeaderCell> : "")}
                                <Table.HeaderCell width={1} textAlign='center' singleLine ></Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {arrMatches.map((line, j) => <Table.Row>
                                <Table.Cell textAlign='center' width={1} singleLine>{line.league_revenge_round === 0 ? <>{line.round_nr}</> : <>{line.league_revenge_round}R</>}</Table.Cell>
                                <Table.Cell textAlign='center' singleLine>{line.opponent}</Table.Cell>
                                {teamAndMembers[teamName].map((line1, i) => line1.total_points_matches > 0 ? <Table.Cell width={1} textAlign='center'>{getScore(line1.member_id, line.match_details_ex)}</Table.Cell> : "")}
                                <Table.Cell textAlign='right' width={1} singleLine><b>{line.score}</b> {line.status_m}</Table.Cell>
                            </Table.Row>)
                            }
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2} textAlign="right"><b>RAZEM:</b></Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, i) => line1.total_points_matches > 0 ? <Table.HeaderCell width={1} textAlign='center'><b>{getScoreSum(line1.member_id, arrMatches)} </b></Table.HeaderCell> : "")}
                                <Table.HeaderCell ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>

                </div>
            </>
        }

        return ""
    } catch (e) {
        console.log(e)
        return ""
    }
}

export function getMi(m1s, m2s) {
    try {
        if (m1s > m2s) {
            return <Icon name={"circle"} color="green" />
        } else if (m1s < m2s) {
            return <Icon name={"circle"} color="red" />
        } else if (m1s === m2s && m1s !== null) {
            return <Icon name={"circle"} color="orange" />
        }

    } catch (e) {
        return ""
    }

}


export function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}


export  function changeValuDataFibo(value,key){
       if(value){
        if(key==="sec"){
            return fmtMSS(value);
        }
        return value
       }    
       return value     
}


export function getImageTeam(teamName, teamAndMembers) {
    try {
        return teamAndMembers[teamName][0].team_photo_file
    } catch (e) {
        console.log(e)
        return null
    }
}


export function getChartsMIT(isDarkTheme, arrMatches, teamName, width, isPhoto) {
    try {

        let labels = []
        let mvalsW = []
        let mvalsL = []
        arrMatches.forEach(line => {

            labels.push(line.league_revenge_round === 0 ? line.round_nr : line.league_revenge_round + "R")
            mvalsW.push(line.scoreW)
            mvalsL.push(line.scoreL)


        });


        let options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            stacked: false,
            plugins: {
                datalabels: {
                    display: true,
                    color: isDarkTheme ? "white" : "black",
                    formatter: Math.round,
                    anchor: "end",
                    offset: -16,
                    align: "start"
                }
            },
            scales: {
                x: {
                    display: true,
                    grid: {
                        color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                    },
                    title: {
                        display: true,
                        text: 'RUNDA'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    grid: {
                        color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                    },
                    title: {
                        display: true,
                        text: 'WYNIK'
                    },
                },

            },
        };

        let dataChart = {
            labels,
            datasets: [
                {
                    label: teamName,
                    data: mvalsW,
                    backgroundColor: isDarkTheme ? 'rgb(37, 124, 60)' : 'rgb(151, 204, 165)',
                },
                {
                    label: 'Przeciwnik',
                    data: mvalsL,
                    backgroundColor: isDarkTheme ? 'rgb(151, 37, 36)' : 'rgb(218, 144, 142)',
                },
            ],
        };



        return <>
            <Bar data={dataChart} plugins={[ChartDataLabels]} options={options} height={width < 1300 ? 100 : isPhoto ? 90 : 50} />
        </>
    } catch (e) {
        console.log(e)
        return ""
    }
}

export function getInicjaly(name) {
    try {
        let sp = name.split(" ")
        return sp[0].charAt(0) + sp[1].charAt(0)
    } catch (e) {
        return name.charAt(0) + name.charAt(1) + name.charAt(2)
    }

}

export function analyzePenalties(subTRoot) {
    try {
        if (subTRoot.ext_conf === null) {
            return null
        } else if (subTRoot.ext_conf.analyze_penalties) {
            let retTab = []
            subTRoot.ext_conf.analyze_penalties.forEach(analyze_penalties_row => {
                if (analyze_penalties_row.enable) {
                    let arrAnalysis = []
                    let allMatches = []
                    if (subTRoot.tournament_json) {
                        subTRoot.tournament_json.forEach(element => {
                            element.matches.forEach(element1 => {
                                if (element1.match_details_ex !== null && element1.match_details_ex.version >= 2) {
                                    allMatches.push(element1,analyze_penalties_row.id)
                                    
                                    if (element1.match_details_ex.members[analyze_penalties_row.id] && element1.match_details_ex.members[analyze_penalties_row.id].team1_members !== null) {
                                        element1.match_details_ex.members[analyze_penalties_row.id].team1_members.forEach(rowTeam => {
                                            if (rowTeam.value !== null) {
                                                arrAnalysis.push({
                                                    value: rowTeam.value, member_id: rowTeam.member_id, team_id: element1.member1_id, match_date: element1.match_date,
                                                    team1_name: element1.member1_name, team2_name: element1.member2_name
                                                })
                                            }
                                        });
                                    }
                                    if (element1.match_details_ex.members[analyze_penalties_row.id] && element1.match_details_ex.members[analyze_penalties_row.id].team2_members !== null) {
                                        element1.match_details_ex.members[analyze_penalties_row.id].team2_members.forEach(rowTeam => {
                                            if (rowTeam.value !== null) {
                                                arrAnalysis.push({
                                                    value: rowTeam.value, member_id: rowTeam.member_id, team_id: element1.member2_id, match_date: element1.match_date,
                                                    team1_name: element1.member1_name, team2_name: element1.member2_name
                                                })
                                            }
                                        });
                                    }
                                }else if (element1.match_details_ex !== null && element1.match_details_ex.fiba) {
                                    allMatches.push(element1)
                                    if (element1.match_details_ex.dataJson.team1_members !== null && element1.match_details_ex.dataJson.team1_members.length>0) {
                                        element1.match_details_ex.dataJson.team1_members.forEach(rowTeam => {
                                            if (rowTeam[analyze_penalties_row.id] != null) {
                                                arrAnalysis.push({
                                                    value: rowTeam[analyze_penalties_row.id], member_id: rowTeam.member_id, team_id: element1.match_details_ex.dataJson.team1_id, match_date: element1.match_date,
                                                    team1_name: element1.member1_name, team2_name: element1.member2_name
                                                })
                                            }
                                        });
                                    }

                                    if (element1.match_details_ex.dataJson.team2_members !== null && element1.match_details_ex.dataJson.team2_members.length>0) {
                                        element1.match_details_ex.dataJson.team2_members.forEach(rowTeam => {
                                            if (rowTeam[analyze_penalties_row.id] != null) {
                                                arrAnalysis.push({
                                                    value: rowTeam[analyze_penalties_row.id], member_id: rowTeam.member_id, team_id: element1.match_details_ex.dataJson.team2_id, match_date: element1.match_date,
                                                    team1_name: element1.member1_name, team2_name: element1.member2_name
                                                })
                                            }
                                        });
                                    }
                                   
                                }
                            });
                        });
                    }

                    if (arrAnalysis && arrAnalysis.length > 0) {

                        const filteredMatches = filterPlayersByFouls(arrAnalysis.sort((a, b) => a.member_id - b.member_id
                            || ifNullDate(a.match_date) - ifNullDate(b.match_date)), analyze_penalties_row.count_fouls);

                        if (filteredMatches && filteredMatches.length > 0) {
                            // znalezieni do wykluczenia
                            filteredMatches.forEach(rowMemberID => {

                                // szukamy pojedynków do pauzowania 
                                let allm = allMatches.filter((element) => element.member1_id === rowMemberID.team_id || element.member2_id === rowMemberID.team_id).sort((a, b) => ifNullDate(a.match_date) - ifNullDate(b.match_date))

                                let a = retMatchToPause(rowMemberID, allm)

                                if (a) {
                                    retTab.push({ ...retMatchToPause(rowMemberID, allm), ...findMemberName(rowMemberID.team_id, rowMemberID.member_id, subTRoot) })
                                }


                            });

                        }

                    }


                } else {
                    if (retTab && retTab.length === 0) {
                        retTab = null
                    }
                }
            });
        
            return retTab
        }
        return null
    } catch (e) {
        console.log(e)
        return null
    }
}

function findMemberName(team_id, member_id, subTRoot) {
    try {
        let a = subTRoot.members.find((x) => x.member_id === team_id)
        let b = a.members_list.find((x) => x.member_id === member_id)
        return { member_name: b.member_name, member_img_file: b.member_img_file, team_name:a.name}
    } catch (e) {
        return "?"
    }
}

function retMatchToPause(rowMemberID, arrM) {
    let ret = { mfoul: rowMemberID, mpause: { status: 0 } }
    for (const element of arrM) {
        if (new Date(element.match_date) > new Date(rowMemberID.match_date)) {
            ret = { mfoul: rowMemberID, mpause: element }
            break
        }

    }
    return ret


}

function filterPlayersByFouls(data, foulLimit) {
    const foulCounts = {};
    const excludedPlayers = [];
    const result = [];

    data.forEach(match => {
        if (!foulCounts[match.member_id]) {
            foulCounts[match.member_id] = 0;
        }
        foulCounts[match.member_id] += match.value;

        if (foulCounts[match.member_id] >= foulLimit) {
            excludedPlayers.push(match);
            foulCounts[match.member_id] = 0; // Resetujemy licznik po wykluczeniu
        } else {
            result.push(match);
        }
    });

    return excludedPlayers;
}


export function checkIsLiveMatch(match_date, last_modify_ts, match_details_ex) {
    try {

        //console.log(match_date,last_modify_ts,match_details_ex)
        const now = Date.now(); // Aktualny czas w milisekundach
        const threeHoursAgo = now - 4 * 60 * 60 * 1000;
        const twoHoursAgo = now - 2 * 60 * 60 * 1000;

        const m1Time = new Date(match_date).getTime();
        const m2Time = new Date(last_modify_ts).getTime();

        return m1Time > threeHoursAgo && m2Time > twoHoursAgo && match_details_ex !== null;
    } catch (e) {
        console.log(e)
        return false
    }
}



export function fiba_style_cfg() {
    return {
        sec: { showChart: true, showTop: true,showInMembers: true, sort: 1 },
        //field_goals_ma: { showChart: true, showTop: true,sort:2 },
        two_points_ma: { showChart: true, showTop: true,showInMembers: true, sort: 3 },
        three_points_ma: { showChart: true, showTop: true,showInMembers: true, sort: 4 },
        free_throws_ma: { showChart: true, showTop: true,showInMembers: true, sort: 5 },
        rebounds_or: { showChart: true, showTop: true,showInMembers: true, sort: 6 },
        rebounds_dr: { showChart: true, showTop: true,showInMembers: true, sort: 7 },
        rebounds_tot: { showChart: true, showTop: true,showInMembers: true, sort: 8 },
        as: { showChart: true, showTop: true,showInMembers: true, sort: 9 },
        to: { showChart: true, showTop: true,showInMembers: true, sort: 10 },
        st: { showChart: true, showTop: true,showInMembers: true, sort: 17 },
        bs: { showChart: true, showTop: true,showInMembers: true, sort: 11 },
        fouls_pf: { showChart: true, showTop: true,showInMembers: true, sort: 12 },
        fouls_fd: { showChart: true, showTop: true,showInMembers: true, sort: 13 },
        fouls_technical: { showChart: true, showTop: true,showInMembers: true, sort: 14 },
        plus_minus: { showChart: true, showTop: true,showInMembers: true, sort: 15 },
        mvp: { showChart: true, showTop: true,showInMembers: true, sort: 16 },
        pts: { showChart: true, showTop: true,showInMembers: true, sort: 1000 },
    }
}