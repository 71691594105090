

import React, { Suspense, useReducer, useEffect } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'semantic-ui-css/semantic.min.css'

import AuthContext from './context/authContext';
import { CookiesProvider } from 'react-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router } from 'react-router-dom';
import CookieNotice from '@mirzalikic/react-cookie-notice';
import './styles-ag/ag-grid.css';
import './styles-ag/ag-theme-balham.css';
import './styles-ag/ag-theme-balham-dark.css';
import "./i18nextInit";
import './App.css';
import { ErrorBoundary } from 'react-error-boundary';
import LogoTeamLigaGenerator from './assets/tglogov6_small.png';
import { lazy } from 'react';


import { ToastProvider } from 'react-toast-notifications';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { sendErrorToDB } from './ConnectWS';
//import componentLoader from './componentLoaderTools';


const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );
      // try to import the component
      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) { // not been refreshed yet
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
      });
  });
};


const MenuPage = lazy(() => lazyRetry(() => import('./layout/ui/Menu/MenuPage')));
//const MenuPage = lazy(() => componentLoader(() => import('./layout/ui/Menu/MenuPage')));

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-isAuthenticated': return { ...state, isAuthenticated: action.isAuthenticated };
    case 'set-User': return { ...state, user: action.user };
    case 'set-ShowToken': return { ...state, token: action.token };
    case 'set-ShowTokenPanel': return { ...state, tokenPanel: action.tokenPanel };
    case 'set-changeModalInfo': return { ...state, modalInfo: action.modalInfo };
    case 'set-changeView': return { ...state, gView: action.gView };
    case 'set-changeRefreshView': return { ...state, refreshView: action.refreshView };
    case 'set-changeLiveIcon': return { ...state, liveIcon: action.liveIcon };
    case 'set-changeDarkMode': return { ...state, darkMode: action.darkMode };
    case 'set-changeOnlyOneColumn': return { ...state, onlyOneColumn: action.onlyOneColumn };
    case 'set-changeShowLoading': return { ...state, showLoading: action.showLoading };
    case 'set-changePaymentBlock': return { ...state, paymentBlock: action.paymentBlock };
    case 'set-changeRefreshViewHeight': return { ...state, refreshViewHeight: action.refreshViewHeight };
    

    case 'login': return { ...state, isAuthenticated: true };
    case 'logout': return { ...state, isAuthenticated: false };


    default: console.log('Sorry');
  }
  return state;
}

const initState = {
  isAuthenticated: false,
  user: null,
  token: null,
  tokenPanel: null,
  modalInfo: null,
  gView: "",
  refreshView: 0,
  liveIcon: 0,
  darkMode: null,
  onlyOneColumn: false,
  showLoading: false,
  paymentBlock:false,
  refreshViewHeight:0,
};

function cookieOffTG() {
  try {
    let wlh = window.location.href;
    if (wlh.indexOf('/iftg') > -1) {
      return true
    } else if (wlh.indexOf('/widget') > -1) {
      return true
    } else if (wlh.indexOf('/klub/') > -1) {
      return true
    } else if (wlh.indexOf('/turnieje/') > -1) {
      return true
    } else if (wlh.indexOf('/liga/') > -1) {
      return true
    } else if (wlh.indexOf('/viewtv') > -1) {
      return true
    } else if (wlh.indexOf('/duel/') > -1) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }

}


let UUIDTG = getUUID()

function getUUID() {
  try {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;//no-mixed-operators
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0; //no-mixed-operators
            d2 = Math.floor(d2/16);
        }
        // eslint-disable-next-line
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

  } catch (e) {
    return "57831"
  }
}


function ErrorFallbackApp({ error, resetErrorBoundary }) {

  return (
    <Segment placeholder style={{ height: "100vh" }}>

      <Header icon>

        <Icon name='warning sign' />
        Prosze odświeżyć stronę.

        <Header.Subheader>
          Jeśli ten problem się powtórzy wciśnij <b>CTRL + F5</b>, jeżeli to nie pomoże to skontaktuj się z administratorem systemu aby uzyskać więcej informacji.
          <br /> <br />
          <div style={{fontSize:12}}>
          Został zarejestrowany błąd UUID:{UUIDTG}
          <br />
          Jeżeli jest to błąd w aplikacji, zostanie on poprawiony w najbliższym możliwym czasie.
          </div>
        </Header.Subheader>
        <br />
        <img src={LogoTeamLigaGenerator} alt="turniej-generator" style={{ width: 220, margin: 5 }} />
      </Header>

    </Segment>
  )
}


function App() {

  const [state, dispatch] = useReducer(reducer, initState);
 

  useEffect(() => {

  }, []);


  function logErrorToService(error, info) {
    try {
      sendErrorToDB(error, info, UUIDTG)
    } catch (e) {
      sendErrorToDB(error, info, UUIDTG)
      console.log(e)
    }

  }



  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackApp}
      onError={logErrorToService}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <CookiesProvider>
        <Router>
          <AuthContext.Provider value={{
            isAuthenticated: state.isAuthenticated,
            user: state.user,
            token: state.token,
            tokenPanel: state.tokenPanel,
            modalInfo: state.modalInfo,
            gView: state.gView,
            refreshView: state.refreshView,
            liveIcon: state.liveIcon,
            darkMode: state.darkMode,
            onlyOneColumn: state.onlyOneColumn,
            showLoading: state.showLoading,
            paymentBlock: state.paymentBlock,
            refreshViewHeight:state.refreshViewHeight,
            
            login: () => dispatch({ type: 'login' }),
            logout: () => dispatch({ type: 'logout' }),
            changeUser: (val) => dispatch({ type: 'set-User', user: val }),
            changeToken: (val) => dispatch({ type: 'set-ShowToken', token: val }),
            changeTokenPanel: (val) => dispatch({ type: 'set-ShowTokenPanel', tokenPanel: val }),
            changeModalInfo: (val) => dispatch({ type: 'set-changeModalInfo', modalInfo: val }),
            changeView: (val) => dispatch({ type: 'set-changeView', gView: val }),
            changeRefreshView: (val) => dispatch({ type: 'set-changeRefreshView', refreshView: val }),
            changeLiveIcon: (val) => dispatch({ type: 'set-changeLiveIcon', liveIcon: val }),
            changeDarkMode: (val) => dispatch({ type: 'set-changeDarkMode', darkMode: val }),
            changeOnlyOneColumn: (val) => dispatch({ type: 'set-changeOnlyOneColumn', onlyOneColumn: val }),
            changeShowLoading: (val) => dispatch({ type: 'set-changeShowLoading', showLoading: val }),
            changePaymentBlock: (val) => dispatch({ type: 'set-changePaymentBlock', paymentBlock: val }),
            changeRefreshViewHeight: (val) => dispatch({ type: 'set-changeRefreshViewHeight', refreshViewHeight: val }),
          }}>

            <ToastProvider autoDismiss placement="top-center" autoDismissTimeout={3000}>{
              <Suspense fallback={<div id="pre-loaderTG" className="pre-loaderTG">
                <div className="loaderTG3"><div></div><div></div></div>
                <div className="pre-loaderTGTXT">Trwa ładowanie aplikacji</div>
              </div>}>

                <MenuPage />

                {cookieOffTG() ? "" :
                  <CookieNotice
                    onSave={(cookies) => {
                      // console.log(cookies);
                    }}
                    onInit={(cookies) => {
                      // console.log(cookies);
                    }}
                    acceptAllButtonText="Akceptuje"
                    cookiePrefix="cookies-tg-"
                    preferencesButtonText="Ustawienia"
                    savePreferencesButtonText="Zapisz moje preferencje"
                    buttonPrimaryClass="buttonPrimaryClass"
                    buttonSecondaryClass="buttonSecondaryClass"
                    cookies={[
                      { name: 'technical', checked: true, editable: false, default: true, title: 'Techniczne pliki cookies (wymagane)', text: 'Wymagane pliki cookies do prawidłowego działania tej strony' },
                      { name: 'analytical', checked: false, editable: true, title: 'Analityczne pliki cookies', text: 'Tego rodzaju pliki cookie zbierają informacje na temat sposobu w jaki użytkownicy korzystają z naszej witryny internetowej, np. które strony odwiedzają najczęściej, czy otrzymują komunikaty o błędach i w jaki sposób trafili na naszą witrynę internetową' },
                    ]}>
                    <h3>Co to są pliki cookies?</h3>
                    <p>Pliki cookies to małe pliki tekstowe, które mogą być wykorzystywane przez strony internetowe w celu efektywniejszego korzystania z witryny, np. w celu ulepszenia poruszania się po witrynie.</p>
                    <p>Przepisy prawa stanowią, że możemy przechowywać pliki cookies na Twoim urządzeniu, jeśli są one niezbędne do działania tej strony. Natomiast na wszystkie inne rodzaje plików cookies potrzebujemy Twojej zgody.</p>
                  </CookieNotice>

                }
              </Suspense>


            }</ToastProvider>
          </AuthContext.Provider>
        </Router>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;
