import React, { useEffect } from 'react';
import { Grid, Label, Segment, Table } from 'semantic-ui-react';
import { getlinkplayerprofile, mobileWSmall, NewArray, returnIconStats } from '../../myfunctions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ShowImgAvatar from '../ShowImgAvatar';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { changeValuDataFibo } from '../../leagueFuntions';


function MembersStatsLeague({ memberDataArr, isDarkTheme }) {
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

    const { t } = useTranslation();

    useEffect(() => {

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [memberDataArr])// eslint-disable-line react-hooks/exhaustive-deps


    function getTop10Members(memberDataArr, rowKey, mcount) {
        let tmp = memberDataArr[memberDataArr.length - 1].stats
        let findIndex = tmp.findIndex((element) => element.newkey === rowKey.newkey)
        return memberDataArr.sort((a, b) => { return b.stats[findIndex].value - a.stats[findIndex].value }).slice(0, mcount)
    }


    function getCharts(memberDataArr, rowKey) {
        try {
            let tmp = getTop10Members(memberDataArr, rowKey, 1000)
            let mvalsW = []
            tmp.forEach(element => {
                let findValue = element.stats.find((row) => row.newkey === rowKey.newkey)
                if (findValue) {
                    mvalsW.push(findValue.value)
                }
            });
            let labels = [...NewArray(mvalsW.length)]


            let options = {
                radius: 0,
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,

                scales: {
                    x: {
                        display: true,
                        grid: {
                            color: isDarkTheme ? '#2d2c2c' : '#f5f5f5'
                        },
                        title: {
                            display: false,
                            text: ''
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        grid: {
                            color: isDarkTheme ? '#2d2c2c' : '#f5f5f5'
                        },
                        title: {
                            display: false,
                            text: ''
                        },
                    },

                },
            };

            let dataChart = {
                labels,
                datasets: [
                    {
                        label: "Numer kolejnego zawodnika z listy i jego wartość",
                        data: mvalsW,

                        //backgroundColor: isDarkTheme ? 'rgb(37, 124, 60)' : 'rgb(151, 204, 165)',
                        fill: true,
                        backgroundColor: "rgba(75, 145, 192, 0.2)",
                        borderColor: "rgba(75, 145, 192, 0.8)"
                    }
                ],
            };
            return <Line data={dataChart} options={options} height={width < 900 ? 70 : 100} />
        } catch (e) {
            return ""
        }
    }

    function getCountCol(value){
        try{
            let countCol = 0
            memberDataArr[memberDataArr.length - 1].stats.forEach(element => 
                element.showTop===false ? "" : countCol=countCol+1
            );

            if(value>countCol){
                return countCol
            }
            return value
        }catch(e){
            return value
        }
    }

    return (
        <>
            <Grid style={{ marginTop: 10 }} columns={width > 1500 ? getCountCol(4) : width > 1250 ? getCountCol(3) : width > 850 ? getCountCol(2) : 1}>
                {memberDataArr && memberDataArr.length > 0 ?
                    memberDataArr[memberDataArr.length - 1].stats.map((row, i) =>
                        row.showTop===false ? "" :
                        <Grid.Column key={i} ><Segment style={{ margin: 0, padding: 5 }} basic className='tablebs'>
                            <Label color='blue'>{returnIconStats(row.newkey)}{t("stats." + row.newkey).toLocaleUpperCase()} - TOP 10</Label>
                            {row.show_chart === false ? "" :
                                <div>
                                    {getCharts(memberDataArr, row)}
                                </div>
                            }
                            <div style={{ overflowY: "hidden", overflowX: "auto", width: "100%" }}>
                                <Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px" }} size='small'
                                    inverted={isDarkTheme} celled striped compact unstackable >
                                    <Table.Header >

                                        <Table.Row>
                                            <Table.HeaderCell width={1} textAlign='center' singleLine></Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center' singleLine>{t("WidokTurnieju.zawodnik")}</Table.HeaderCell> 
                                            <Table.HeaderCell textAlign='center' singleLine>{returnIconStats(row.newkey)}{t("stats." + row.newkey).toLocaleUpperCase()}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            getTop10Members(memberDataArr, row, 10).map((line, i) =>
                                                <Table.Row key={i}>
                                                    <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                    <Table.Cell textAlign='left' singleLine style={{fontSize:10}}>
                                                        <figure className='imgPlayer'><div style={{opacity: line.member_img ? 1 : 0.4}}><ShowImgAvatar urlpic={line.member_img} mtype={"USER"} /></div><figcaption><b>{getlinkplayerprofile(null, line.member_name, 1, width < mobileWSmall() ? 13 : 12, "_blank")}</b> <br/>{getlinkplayerprofile(null, line.team_name, 1, width < mobileWSmall() ? 13 : 11, "_blank")}</figcaption></figure>

                                                    </Table.Cell>

                                                    {line.stats.map((line1, i) =>
                                                        line1.newkey === row.newkey ?
                                                            <Table.Cell width={1} textAlign='center'>{changeValuDataFibo(line1.value, line1.newkey)}</Table.Cell>
                                                            : ""
                                                    )}



                                                </Table.Row>

                                            )}

                                    </Table.Body>
                                </Table>
                            </div>
                        </Segment>
                        </Grid.Column>

                    ) : ""}
            </Grid>
        </>
    )
}

export default MembersStatsLeague;