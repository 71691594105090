import React, { useEffect, useContext, useState,  useRef } from 'react';
import { Button, Grid, Header, Icon, Image,  Modal, Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import {  normalizeStringURLIMG,  noSubIsActiveDP } from '../myfunctions';
import { ConnectWS, ConnectWSSendData } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';

import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';

import { FileUpload } from 'primereact/fileupload';
import { addLocale, locale } from 'primereact/api';
import noimage from '../assets/noimage.png';
import MyImageLazy from './MyImageLazy';
import { confirmAlert } from 'react-confirm-alert';
import Resizer from "react-image-file-resizer";


function AddPhotoToTeam({ openModalAddPhotoToTeam, setopenModalAddPhotoToTeam }) {
  locale('pl');
  addLocale('pl', {
    choose: "Wybierz",
    upload: "Wgraj",
    cancel: "Anuluj",
    pending: "Oczekuje na wgranie - kliknij Wgraj",
    completed: "Wysyłany"
  });

  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);

  const isDemo = useCheckUserIsDemo()
  
  const [myPgotoUrl, setmyPgotoUrl] = useState(null)


  const [isAS, setisAS] = useState(true)
 

  function checkSubIsActiv() {
    try {
      if (isDemo) {
        setisAS(true)
      } else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        // wylaczone sprwadzanie bo dla innych turniejów też można dodać 
        setisAS(false)
      }
    } catch (e) {
      setisAS(true)
    }
  }

  useEffect(() => {

    checkSubIsActiv()


    if (openModalAddPhotoToTeam.open) {
      setmyPgotoUrl(openModalAddPhotoToTeam.data.team_photo_file)
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [openModalAddPhotoToTeam])// eslint-disable-line react-hooks/exhaustive-deps

function removePhoto() {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować zdjęcie',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removePhotoAcc()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function removePhotoAcc() {
      ConnectWS("/generator/changeSubtournamentTeamPhotoByOrganizer", authContext.token, {subtournament_id:openModalAddPhotoToTeam.data.subTournamentId, tournament_member_id:openModalAddPhotoToTeam.data.id,editPicture:3 }, function (response, logout) {

        if (logout === 1) {
          

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
          setmyPgotoUrl(null)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }

  }
  const resizeFile = (file,quality) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1366,
            768,
            "JPEG",
            quality,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

  const ref = useRef(null)
  const customBase64Uploader = async (event) => {

    if (event.files.length > 0) {
      let arrFileOK = []
      let arrFileErr = []
      let currCF = 1
      event.files.forEach(async (row) => {
        let image = null

        if(row.size > 510000){
          addToast("Zmniejszam rozmiar obrazka", { appearance: 'info' });
          image = await resizeFile(row,100);
        }
       
        if(image && image.size> 510000){
          addToast("Zmniejszam rozmiar obrazka oraz jakość", { appearance: 'info' });
          image = await resizeFile(row,90);
        }

        if(image && image.size> 510000){
          addToast("Zmniejszam rozmiar obrazka oraz jakość v2", { appearance: 'info' });
          image = await resizeFile(row,70);
        }
        
        if(image && image.size> 510000){
          addToast("Zmniejszam rozmiar obrazka oraz jakość v3", { appearance: 'info' });
          image = await resizeFile(row,50);
        }
       
      
        callbackPhoto(image ? image : row, currCF, event.files.length, arrFileOK, arrFileErr)
        currCF++
      })
    }

    function callbackPhoto(nfile, currCF, count, arrFileOK, arrFileErr) {


   
      var formData = new FormData();
      formData.append('pictureData', nfile, normalizeStringURLIMG(nfile.name));
      formData.append('tournament_member_id', openModalAddPhotoToTeam.data.id);
      formData.append('subtournament_id', openModalAddPhotoToTeam.data.subTournamentId);
      formData.append('editPicture', 2);
      

      ConnectWSSendData("/generator/changeSubtournamentTeamPhotoByOrganizer", authContext.token, formData, function (response, logout) {
        if (logout === 1) {
          arrFileErr.push(nfile)
        } else if (response && response.status && Number(response.status) === 200) {
          arrFileOK.push(nfile)
         
            addToast(response.data.message, { appearance: 'success' });
            setmyPgotoUrl(URL.createObjectURL(nfile))
            authContext.changeRefreshView(authContext.refreshView + 1)
            // chooseFileUrl(process.env.REACT_APP_URL_MEDIA +response.data.file_url)
        

        } else {
          arrFileErr.push(nfile)
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }
        try {
          if (currCF === count) {
            ref.current.setFiles(arrFileErr);
            ref.current.setUploadedFiles(arrFileOK);
          }
       
        } catch (e) {
          console.log(e)
        }

      });

    }

  }

  return (
    <>
      <Modal
        open={openModalAddPhotoToTeam.open}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='edit' />
                  <Header.Content>Zdjęcie drużyny</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content >

          <Modal.Description >
      
            {openModalAddPhotoToTeam.open ? !isAS ? noSubIsActiveDP() : <>
            <Segment textAlign='center' style={{position:"relative"}}>
              <Icon className='cursorPointer' name="trash" style={{position:"absolute",top:5,right:0}} onClick={()=>removePhoto()}/>
            <MyImageLazy openView={false} maxHeight={400} src={myPgotoUrl  ? myPgotoUrl : noimage} />
            </Segment>
              <div className="card">
                <FileUpload accept="application/pdf,image/*" ref={ref} invalidFileSizeMessageDetail="Maksymalny rozmiar pliku to 3MB"  invalidFileSizeMessageSummary="Bład! za duży plik." maxFileSize={3000000} customUpload uploadHandler={customBase64Uploader} emptyTemplate={<p className="m-0">Drag and drop.</p>} />
              </div>
            </> : ""}

          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setopenModalAddPhotoToTeam({ open: false, data: null })}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddPhotoToTeam;