import axios from './axios';
import { getLanguage, normalizeString } from './myfunctions';

export function ConnectWS(method, tokendata, params, callback) {


  // dodanie jezyka
  try {
    if (params) {
      params = { ...params, language: getLanguage() }
    }
  } catch (e) {

  }

  // const data = {};
  // const paramstmp = new URLSearchParams(params).toString();
  // method=method+"?"+paramstmp;
  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | T:" + tokendata + " | ", method, JSON.stringify(params))
  }
  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
  }

  if (method.includes('getJsonFromFile')) {
    axios.defaults.timeout = 120000;
    
  } else {
    axios.defaults.timeout = 60000;
  }


  const json = JSON.stringify(params);
  axios.post(method, json, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
      }

      callback(response);
    }).catch(err => {
      // brak autoryzacji wylogowanie
      let errString = err + "";
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        console.log("ERR", err, errString)
      }
      if (errString.indexOf("403") > 0) {
        callback("BRAK UPRAWNIEŃ DO API E403", 2);
      } else if (errString.indexOf("404") > 0) {
        callback("BRAK METODY W API E404", 2);

      } else if (errString.indexOf("500") > 0) {
        callback("BŁAD w API E500", 2);

      } else if (errString.indexOf("Error") > 0) {
        callback("E500", 2);
      }
      else if (typeof err.response === "undefined") {
        callback("Proszę odświeżyć stronę...", 2);
      }
      else if (err.response.status === 401) {
        callback({ error: err.response.data.message }, 1);
      } else if (err.response.status === 400) {
        //{error: 'Jakieś ostrzeżenie', sqlError: false, type: 'info'}
        try {
          console.log(err.response.data)
          if (err.response.data.sqlError) {
            callback("Wystąpił błąd bazy danych, skontaktuj się z administratorem", 103);
          } else if (err.response.data.type === "info") {
            callback(err.response.data.message, 101);
          } else if (err.response.data.type === "warning") {
            callback(err.response.data.message, 102);
          } else if (err.response.data.type === "error") {
            callback(err.response.data.message, 103);
          } else {
            callback("Wystąpił błąd: B1, skontaktuj się z administratorem");
          }
        } catch (e) {
          console.log(e)
          callback("Wystąpił błąd: B2, skontaktuj się z administratorem");
        }
      } else {
        callback("Wystąpił błąd: B3, ponów próbę");
      }

    })
}


export function ConnectWSXAPIKEY(method, xApiKey, params, callback) {
  // const data = {};
  // const paramstmp = new URLSearchParams(params).toString();
  // method=method+"?"+paramstmp;
  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | T:" + xApiKey + " | ", method, JSON.stringify(params))
  }
  if (xApiKey) {
    axios.defaults.headers.common['x-api-key'] = xApiKey;
  } else {
    axios.defaults.headers.common['x-api-key'] = null;
  }


  const json = JSON.stringify(params);
  axios.post(method, json, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
      }

      callback(response);
    }).catch(err => {
      // brak autoryzacji wylogowanie
      let errString = err + "";
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        console.log("ERR", err, errString)
      }
      if (errString.indexOf("403") > 0) {
        callback("BRAK UPRAWNIEŃ DO API E403", 2);
      } else if (errString.indexOf("404") > 0) {
        callback("BRAK METODY W API E404", 2);

      } else if (errString.indexOf("500") > 0) {
        callback("BŁAD w API E500", 2);

      } else if (errString.indexOf("Error") > 0) {
        callback("E500", 2);
      } else if (typeof err.response === "undefined") {
        callback("Proszę odświeżyć stronę...", 2);
      } else if (err.response.status === 401) {
        callback({ error: err.response.data.message }, 1);
      } else if (err.response.status === 400) {
        //{error: 'Jakieś ostrzeżenie', sqlError: false, type: 'info'}
        try {
          console.log(err.response.data)
          if (err.response.data.sqlError) {
            callback("Wystąpił błąd bazy danych, skontaktuj się z administratorem", 103);
          } else if (err.response.data.type === "info") {
            callback(err.response.data.message, 101);
          } else if (err.response.data.type === "warning") {
            callback(err.response.data.message, 102);
          } else if (err.response.data.type === "error") {
            callback(err.response.data.message, 103);
          } else {
            callback("Wystąpił błąd: B1, skontaktuj się z administratorem");
          }
        } catch (e) {
          console.log(e)
          callback("Wystąpił błąd: B2, skontaktuj się z administratorem");
        }
      } else {
        callback("Wystąpił błąd: B3, ponów próbę");
      }

    })
}

export function ConnectWSSendData(method, tokendata, bodyFormData, callback) {
  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | ", method, bodyFormData)
  }

  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  }

  axios.post(method, bodyFormData, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      'Content-Type': 'multipart/form-data'
    }
  }).then(response => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      let end = new Date().getTime();
      let time = end - start;
      console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
    }

    callback(response);
  }).catch(err => {
    // brak autoryzacji wylogowanie
    let errString = err + "";
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("ERR", err, errString)
    }
    if (errString.indexOf("403") > 0) {
      callback("BRAK UPRAWNIEŃ DO API E403", 2);
    } else if (errString.indexOf("404") > 0) {
      callback("BRAK METODY W API E404", 2);

    } else if (errString.indexOf("500") > 0) {
      callback("BŁAD w API E500", 2);

    } else if (errString.indexOf("Error") > 0) {
      callback("E500", 2);
    }
    else if (typeof err.response === "undefined") {
      callback("Proszę odświeżyć stronę...", 2);
    }
    else if (err.response.status === 401) {
      callback({ error: err.response.data.message }, 1);
    } else if (err.response.status === 400) {
      //{error: 'Jakieś ostrzeżenie', sqlError: false, type: 'info'}
      try {
        console.log(err.response.data)
        if (err.response.data.sqlError) {
          callback("Wystąpił błąd bazy danych, skontaktuj się z administratorem", 103);
        } else if (err.response.data.type === "info") {
          callback(err.response.data.message, 101);
        } else if (err.response.data.type === "warning") {
          callback(err.response.data.message, 102);
        } else if (err.response.data.type === "error") {
          callback(err.response.data.message, 103);
        } else {
          callback("Wystąpił błąd: B1, skontaktuj się z administratorem");
        }
      } catch (e) {
        console.log(e)
        callback("Wystąpił błąd: B2, skontaktuj się z administratorem");
      }
    } else {
      callback("Wystąpił błąd: B3, ponów próbę");
    }

  })
}

export function addLinkToSitemap(link) {
  try {
    if (normalizeString(decodeURI(link)).includes("iftg")) {
    } else {
      ConnectWS("/data/addSiteLink", null, { link: normalizeString(decodeURI(link)), delete: 0 }, function (response, logout) {
        if (logout === 1) {
        } else if (response && response.status && Number(response.status) === 200) {
        } else {
          console.log(response);
        }
      });
    }
  } catch (err) {
    console.log(err)
  }
}

export function removeLinkToSitemap(link) {
  try {
    if (normalizeString(decodeURI(link)).includes("iftg")) {
    } else {
      ConnectWS("/data/addSiteLink", null, { link: normalizeString(decodeURI(link)), delete: 1 }, function (response, logout) {
        if (logout === 1) {
        } else if (response && response.status && Number(response.status) === 200) {
        } else {
          console.log(response);
        }
      });
    }
  } catch (err) {
    console.log(err)
  }
}


export function sendErrorToDB(error, info,UUIDTG) {
  try {
    let newURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.hash
    let txt = "Browser CodeName: " + navigator.appCodeName + ",";
    txt += "Browser Name: " + navigator.appName + ",";
    txt += "Browser Version: " + navigator.appVersion + ",";
    txt += "Cookies Enabled: " + navigator.cookieEnabled + ",";
    txt += "Platform: " + navigator.platform + ",";
    txt += "User-agent header: " + navigator.userAgent + ",";
    txt += " -------------------------------- ";
    for (var key in localStorage) {
      txt += "localStorage-" + key + ": " + window.localStorage.getItem(key) + ",";
    }

    ConnectWS("/data/addErrorToDB", null, { err_val: error.toString(), info_val: JSON.stringify(info), url_val: newURL, navigator_val: txt ,uuid:UUIDTG}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {
      } else {
        console.log(response);
      }
    });

  } catch (err) {
    console.log(err)
  }
}

function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000))
    , seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}



