import React, { useEffect, useContext, useState, useRef } from 'react';
import { Icon, Button, Modal, Grid, Header, Image, Form, Input, Message } from 'semantic-ui-react'

import LogoTeamLigaGenerator from '../../../../../assets/tglogov6_small.png';
import AuthContext from '../../../../../context/authContext';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { ConnectWS } from '../../../../../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { AG_GRID_LOCALE_PL } from '../../../../../LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../../../../../AGGridActivity';
import { confirmAlert } from 'react-confirm-alert';
import ShowJudgetsAmountReport from './ShowJudgetsAmountReport';


function JudgesList({ addJudges, selectionType, rootHeight, setselectedJudgesList, addATS, judgesListArr }) {

  const { addToast } = useToasts();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(null)


  const [loading, setloading] = useState(false)
  const [judgesList, setjudgesList] = useState(null)
  const [selectedJudgesListLocal, setselectedJudgesListLocal] = useState([])// eslint-disable-line no-unused-vars

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

  };

  const [formAddJ, setFormAddJ] = useState({
    name: "",
    email: "",
    additional_info: ""
  })

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyAccount")
    }
    getJudges()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    SelectedRowTab()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [judgesListArr, gridApi, judgesList])// eslint-disable-line react-hooks/exhaustive-deps

  function addArbiterToSubtournament(addATS, person_id) {
    setloading(true)
    let subtournament_id = addATS
    ConnectWS("/generator/addArbiterToSubtournament", authContext.token, { subtournament_id: subtournament_id, person_id: person_id, description: "" }, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {
        //addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)
    });

  }

  function removeArbiterFromSubtournament(addATS, person_id) {
    setloading(true)
    let subtournament_id = addATS
    ConnectWS("/generator/removeArbiterFromSubtournament", authContext.token, { subtournament_id: subtournament_id, person_id: person_id }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        //addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)
    });

  }

  function addOrRemoveJudges(judgesListAGGRID) {
    try {
      let agGridPersonId = []
      judgesListAGGRID.forEach(element => {
        agGridPersonId.push(element.person_id)
      });

      let subTournamentPersonId = []
      judgesListArr.forEach(element => {
        subTournamentPersonId.push(element.person_id)
      });


      let differenceAddJudges = agGridPersonId.filter(x => !subTournamentPersonId.includes(x));
      if (differenceAddJudges && differenceAddJudges.length > 0) {
        // dodajemy arbitra
        addArbiterToSubtournament(addATS, differenceAddJudges[0])
      }

      let differenceRemoveJudges = subTournamentPersonId.filter(x => !agGridPersonId.includes(x));
      if (differenceRemoveJudges && differenceRemoveJudges.length > 0) {
        // kasujemy arbitra
        removeArbiterFromSubtournament(addATS, differenceRemoveJudges[0])
      }
    } catch (e) {

    }
  }

  function SelectedRowTab() {
    try {

      if (null !== gridApi && null !== judgesListArr && judgesListArr.length > 0) {
        gridApi.forEachNode(function (node) {
          if (node.data && judgesListArr.find(item => { return item.person_id === node.data.person_id })) {
            node.setSelected(true);
          }
        });
      }
    } catch (e) {

    }

  }

  const submitModalAddJ = e => {
    e.preventDefault();
    let person_name = formAddJ.name
    let person_email = formAddJ.email
    let additional_info = formAddJ.additional_info

    ConnectWS("/generator/addArbiter", authContext.token, { person_name: person_name, person_email: person_email, additional_info: additional_info }, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getJudges()
        setFormAddJ({
          name: "",
          email: "",
          additional_info: ""
        })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }



  function getJudges() {
    setjudgesList(null)
    ConnectWS("/generator/getArbiters", authContext.token, {}, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {

        setjudgesList(response.data)
        setTimeout(function () { SelectedRowTab() }, 1000);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });

  }

  // zaznaczenie rekordu w tab
  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    if(addATS){
      addOrRemoveJudges(selectedRows)
    }
    if (selectedRows.length >= 1) {
      try {
        setselectedJudgesListLocal(selectedRows)
        setselectedJudgesList(selectedRows)
      } catch (e) {

      }

    }
    else {
      try {
        setselectedJudgesListLocal([])
        setselectedJudgesList([])
      } catch (e) {

      }
    }

  };

  const onCellValueChanged = (data) => {
    ConnectWS("/generator/editArbiter", authContext.token, { ...data.data }, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getJudges()
      } else {
        getJudges()
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });

  }
  const rowLoading = props => {
    return <Icon name='spinner' loading />
  }

  const rowDel = props => {
    const rowDelQ = () => {
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz usunąć sędziego?',
        buttons: [
          {
            label: 'Tak',
            onClick: () => rowDelQAcc(props.data)
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });

      function rowDelQAcc(data) {
        ConnectWS("/generator/removeArbiter", authContext.token, { ...data }, function (response, logout) {

          if (logout === 1) {

          } else if (response && response.status && Number(response.status) === 200) {
            addToast(response.data.message, { appearance: 'success' });
            getJudges()
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      }

    };
    return addJudges ? <div style={{ cursor: "pointer" }} onClick={rowDelQ} ><Icon name='trash' style={{ fontSize: 16 }} /></div> : ""
  };

  return (
    <>
      {addJudges ? <>
        <Button onClick={() => setOpen(true)} color='orange'>
          <Icon name="add" /> DODAJ SĘDZIEGO
        </Button>
        <ShowJudgetsAmountReport />
      </>
        : ""}
      {judgesList && judgesList.length < 1 ? <Message info size='mini' >Sędziów można dodać w module UCZESTNICY a nastepnie zakładka sędziowie. </Message>
        : ""}


      <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} gridApiRef={gridRef} />
      <div className="ag-theme-balham" style={{ position: "relative", marginTop: 10, height: rootHeight ? rootHeight : height < 900 ? height - 80 : height - 320, width: "100%" }}>


        <AgGridReact
          ref={gridRef}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={onCellValueChanged}
          rowSelection={selectionType > 1 ? 'multiple' : 'single'}
          animateRows={true}
          defaultColDef={{
            editable: false,
            sortable: true,
            minWidth: 100,
            filter: false,
            resizable: true,
            floatingFilter: false,
            flex: 1,
          }}
          frameworkComponents={{
            rowDel: rowDel,
            rowLoading: rowLoading,

          }}
          localeText={AG_GRID_LOCALE_PL}
          onGridReady={onGridReady}
          rowData={judgesList}
        >
          {(loading || (authContext && authContext.showLoading)) && selectionType > 0 ? <AgGridColumn cellRenderer='rowLoading' minWidth={50}
            maxWidth={50}
            width={50}
            flex={0} /> : <AgGridColumn maxWidth={50}
              width={50}
              flex={0} checkboxSelection={true} />}
          <AgGridColumn minWidth={200}
            width={200} editable={true} field="person_name" headerName="Nazwa" filter="agMultiColumnFilter" />
          <AgGridColumn minWidth={200}
            width={200} field="person_email" editable={true} headerName="Email" filter="agMultiColumnFilter" />
          <AgGridColumn minWidth={200}
            width={200} field="additional_info" editable={true} headerName="Dodatkowe informacje" filter="agMultiColumnFilter" />
          <AgGridColumn cellRenderer='rowDel' minWidth={40}
            maxWidth={40}
            width={40}
            flex={0}
            resizable={false}
            sortable={false}
            editable={false}
            filter={false} />
        </AgGridReact>

      </div>


      {addJudges ?
        <> <Modal
          open={open ? true : false}
          centered={width < 900 ? false : true}
        >
          <Modal.Header>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={12} verticalAlign="middle">
                  <Header as='h2' textAlign='center'>
                    <Icon name='add' color="red" />
                    <Header.Content>Dodaj sędziego</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right" only='large screen'>
                  <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content className='CardBlack'>

            <Modal.Description>
              {open ? <>
                <Form onSubmit={submitModalAddJ} size='tiny'>
                  <Form.Field
                    control={Input}
                    required
                    label='Imię i Nazwisko'
                    placeholder='Imię i Nazwisko'
                    value={formAddJ.name}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, name: data.value })}
                  />
                  <Form.Field
                    control={Input}

                    type='email'
                    label='Email'
                    placeholder='Email'
                    value={formAddJ.email}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, email: data.value })}
                  />
                  <Form.Field
                    control={Input}
                    label='Dodatkowe informacje'
                    placeholder='Dodatkowe informacje'
                    value={formAddJ.additional_info}
                    onChange={(e, data) => setFormAddJ({ ...formAddJ, additional_info: data.value })}
                  />
                  <Button fluid color='blue'>DODAJ SĘDZIEGO</Button>
                </Form>
              </> : ""}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => {
              setOpen(null)
            }}>
              ZAMKNIJ
            </Button>
          </Modal.Actions>
        </Modal>

        </>
        : ""}
    </>
  )
}

export default JudgesList;