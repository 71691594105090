import React, { useEffect, useContext, useState } from 'react';
import { Icon, Header, Divider, Table, List, Container, Card, Button, Image, Input, Label, Segment, Grid } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { changeInitialScale, changePageName, checkIsREFTOKENOn, checkiscompanypay, checkisglicko, getCyklPackage, getLimitUsersPackage, packageColor } from '../../../../myfunctions'
import { Markup } from 'interweave';
import tgtl from '../../../../assets/gtl.png';
import { ConnectWS } from '../../../../ConnectWS';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { useToasts } from 'react-toast-notifications';
import BuySubscription from '../../SinglePage/Other/BuySubscription';
import PackagesGTLCompany from './PackagesGTLCompany';
import CallBackTG from '../../../../tools/CallBackTG';
import pucharpatg from '../../../../assets/pucharpatg.png';
import PackagesAssist from './PackagesAssist';



function PackagesGTL(props) {
  const { width } = useWindowDimensions();
  const [form, setForm] = useState(null);
  const authContext = useContext(AuthContext);
  const isDemo = useCheckUserIsDemo();
  const { addToast } = useToasts();
  const [myRefToken, setmyRefToken] = useState(null);// eslint-disable-line no-unused-vars
  const [openModalDataPA, setopenModalDataPA] = useState(null);
  const [dedicatedWebsites, setdedicatedWebsites] = useState([])
  const [totalValueV2, settotalValueV2] = useState(null)
  const [iscompanypay, setiscompanypay] = useState(0);


  function generateHotPayData(token_amount, total_price) {

    //ConnectWS("/payment/generateHotPayDataDonate", authContext.token, { amount: amount }, function (response, logout) {
    ConnectWS("/payment/generateHotPayDataTokens", authContext.token, { token_amount: token_amount, total_price: total_price }, function (response, logout) {

      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });
        setForm(null)
      } else if (response && response.status && Number(response.status) === 200) {
        //setRowDataTournament(response.data)
        setForm(response.data)
        document.getElementById("order").submit();

      } else {
        //console.log(response);
        setForm(null)
      }

    });

  }

  function getAmountPriceSub() {
    //ConnectWS("/payment/getSubscriptionPrices", authContext.token, { amount: amount }, function (response, logout) {
    ConnectWS("/generator/getPrices", authContext.token, {}, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        settotalValueV2(response.data)

      } else {

      }

    });

  }
  function getCurrentPages() {

    ConnectWS("/dedicated/getDedicatedWebsites", null, {}, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data.length > 0) {
          setdedicatedWebsites(response.data)
        }
      } else {
        console.log(response)
      }

    });
  }


  useEffect(() => {
    setiscompanypay(checkiscompanypay())
    changeInitialScale(0.5)
    changePageName()
    getAmountPriceSub()
    getCurrentPages()
    return () => { changeInitialScale(1) }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function retCharTab(co) {
    if (co === true) {
      return <Icon color='green' name='checkmark' size='large' />
    } else if (co === false) {
      return <Icon style={{ color: "#cdccc7" }} name='delete' size='large' />
    } else if (co === 0) {
      return "Brak limitu"
    } else if (co === 1) {
      return <Icon style={{ color: "#cdccc7" }} name='ban' size='large' />
    } else {
      return co
    }
  }

  let arrayTab = [
    { body: "Panel do zarządzania rozgrywkami dla organizatora", gtlbrown: retCharTab(true), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "<b>Wyniki live</b>  - dostęp do wyników dla zawodników/drużyn i kibiców online", gtlbrown: <>{retCharTab(true)} </>, gtlsilver: <>{retCharTab(true)}</>, gtlgold: <>{retCharTab(true)}</>, gtldiament: retCharTab(true) },
    { body: "Własna podstrona rozgrywek <b>turniej-generator.pl/tg/XXX</b> z wynikami live", gtlbrown: retCharTab(true), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "System turniejowy - drabinki, grupy, każdy z każdym, szwajcarski, brazylijski, (ligowy, tylko w modelu subskrypcji zobacz poniżej)", gtlbrown: retCharTab(true), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "System turniejowy drużynowy - drabinki, grupy, każdy z każdym - dodawanie zawodników do drużyny, dodawanie zaawansowanych statystyk dla zawodników w drużynie", gtlbrown: retCharTab(false), gtlsilver: retCharTab(false), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Ilość zawodników/drużyn biorących udział w danej kategorii", gtlbrown: retCharTab(getLimitUsersPackage(0)), gtlsilver: retCharTab(getLimitUsersPackage(1)), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Limit rozgrywanych kategorii", gtlbrown: retCharTab(process.env.REACT_APP_IN_LIMITBROWNCAT), gtlsilver: retCharTab(0), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Limit rozgrywek per tydzień", gtlbrown: retCharTab("1"), gtlsilver: retCharTab(0), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },
    { body: "Możliwość łaczenia kilku rozgrywek w cykl", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Zapisy zawodników/drużyn do rozgrywek online", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Zaawansowane statystyki zawodników/drużyn", gtlbrown: retCharTab(false), gtlsilver: retCharTab(true), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Specjalna strona do wyświetlenia na TV lub rzutniku - wyniki live, automatyczne odświeżane bez potrzeby przeładowania strony", gtlbrown: <>{retCharTab(false)}</>, gtlsilver: <>{retCharTab(true)}</>, gtlgold: <>{retCharTab(true)}</>, gtldiament: retCharTab(true) },

    { body: "Import uczestników do turnieju z pliku csv", gtlbrown: retCharTab(false), gtlsilver: retCharTab(false), gtlgold: retCharTab(true), gtldiament: retCharTab(true) },
    { body: "Dostęp do rozegranych, archiwalnych turniejów, lig", gtlbrown: retCharTab("1 ROK"), gtlsilver: retCharTab(0), gtlgold: retCharTab(0), gtldiament: retCharTab(0) },

  ]


  /*

  function getSubscriptionsMonth(){
    try {
      let dtnow =  moment(moment.now()).format('YYYY-MM-DD');
      let dtmonth =  getSubscriptionsMonthDate(dtnow)
      return "od "+dtnow+" do "+dtmonth
    } catch {
      return "err"
    }
   
  }

*/

  return (
    iscompanypay ? <PackagesGTLCompany /> :
      <> <Header as='h5' textAlign='center' block>
        <Icon name='certificate' />
        <Header.Content>Pakiety - Generator turniejów </Header.Content>
      </Header>
        <Divider />
        <Label color='red' ribbon>
          JEDNORAZOWE
        </Label>

        <List size='small'>
          <List.Item>
            {packageColor(1)}
            <List.Content><b>Pakiet Brązowy (darmowy) </b>- przeznaczony dla wszystkich użytkowników systemu jest to pakiet za który <b>nie sa pobierane</b> TOKENY-GTL.</List.Content>
          </List.Item>
          <List.Item>
            {packageColor(2)}
            <List.Content><b>Pakiet Srebrny </b>- przeznaczony dla użytkowników, którzy tworzą profesjonalne rozgrywki począwszy od rejestracji zawodników online a kończąc na zaawansowanych statystykach oraz docenienie generatora. Za każde wygenerowanie takich rozgrywek z konta jest pobierany <b>1</b> TOKEN-GTL niezależnie od ilości kategorii</List.Content>
          </List.Item>
          <List.Item>
            {packageColor(3)}
            <List.Content><b>Pakiet Złoty </b>- przeznaczony dla użytkowników, którzy tworzą profesjonalne rozgrywki <b>z duża liczbą (ponad {getLimitUsersPackage(1)}) uczestników przynajmniej w jednej kategorii</b> począwszy od rejestracji zawodników online a kończąc na zaawansowanych statystykach oraz docenienie generatora. Za każde wygenerowanie takich rozgrywek z konta pobierane są <b>2</b> TOKEN-GTL niezależnie od ilości kategorii</List.Content>
          </List.Item>
        </List>


        <Grid >
          <Grid.Row>
            <Grid.Column width={10}>
              {getCyklPackage()}
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={2} textAlign='center'>
              <Image
                alt="puchartg"
                style={{ width: 120, margin: "0 auto" }}
                src={pucharpatg}

              />

            </Grid.Column>
            <Grid.Column stretched width={4} textAlign='center'>

              <Button fluid onClick={() => setopenModalDataPA("1")} color='brown'>Asystent wyboru odpowiedniego pakietu</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Container style={{ marginBottom: 10, marginTop: 10 }} textAlign="center">
          Wszystkie ważne informacje oraz porównanie pakietów zostało opisane w poniższej tabeli.
        </Container>
        <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
          <Table striped compact celled unstackable size='small' color="grey">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell>Funkcjonalność</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "14px" }}> {packageColor(1)}<br /><span style={{ fontSize: 13 }}>Pakiet Brązowy<br />(darmowy)</span></Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2}> {packageColor(2)}<br /><span style={{ fontSize: 13 }}>Pakiet Srebrny</span></Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2}> {packageColor(3)}<br /><span style={{ fontSize: 13 }}>Pakiet Złoty</span></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {arrayTab.map((row, k) => (
                < >
                  {k === 8 ?
                    <>
                      <Table.Row >
                        <Table.Cell style={{ lineHeight: "12px" }}>Dla zawodników/drużyn zostanie wygenerowana <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "lictl", name: "Numer TG", value: "" })}>Numer TG</span><br />

                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(false)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(true)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(true)}
                        </Table.Cell>

                      </Table.Row>
                      <Table.Row >
                        <Table.Cell>Ranking stosowany do określenia mocy przeciwnika/drużyny przeciwnej {checkisglicko() ? <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "GLICKO", name: "Ranking GLICKO", value: "" })}>Ranking GLICKO</span> : <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "RELO", name: "Ranking ELO", value: "" })}>Ranking ELO</span>}  </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(false)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(true)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          {retCharTab(true)}
                        </Table.Cell>

                      </Table.Row>

                    </>
                    : ""
                  }
                  <Table.Row key={k}>
                    <Table.Cell><Markup content={row.body} /> </Table.Cell>
                    <Table.Cell textAlign='center'>
                      {row.gtlbrown}
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      {row.gtlsilver}
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                      {row.gtlgold}
                    </Table.Cell>

                  </Table.Row>
                </>
              ))}

            </Table.Body>
            <Table.Footer style={{ backgroundColor: "#e8e8e8", fontWeight: "bold", fontSize: 15, lineHeight: "13px" }}>
              <Table.Row>
                <Table.Cell>WARTOŚĆ TOKENY-GTL - JEDNORAZOWE</Table.Cell>
                <Table.Cell textAlign='center'>
                  0 GTL
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  1 GTL
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  2 GTL
                </Table.Cell>

              </Table.Row>
            </Table.Footer>

          </Table>
        </div>



        {checkIsREFTOKENOn() ?
          <div style={{ overflowX: "auto", width: "100%" }} >
            <Segment style={{ width: 950, margin: "0 auto", marginTop: 30, marginBottom: 30, backgroundColor: "#ebebeb" }}>
              <Label color='grey' ribbon>
                <Icon name="handshake" /> WPISZ REFTOKEN POLECAJĄCEGO
              </Label>
              <Input style={{ marginTop: 20, width: 900, margin: "0 auto" }} label='REFTOKEN' value={myRefToken} action={{
                color: 'blue',
                labelPosition: 'right',
                icon: 'hand point up',
                content: 'SPRAWDŹ REFTOKEN',
                onClick: () => console.log("asd")
              }} />
              <div style={{ marginTop: 5, fontSize: 13, textAlign: "center" }}>KLIKNIJ SPRAWDŹ</div>
            </Segment>
          </div>
          : ""}

        <Card.Group style={{ marginTop: 50, marginBottom: 50 }} centered>

          {totalValueV2 && totalValueV2.token ? totalValueV2.token.map((row, i) =>

            <Card key={i} link className={i === 1 ? "cardpayw" : "cardpay"}>
              <Card.Content>
                <Image
                  alt="logo"
                  style={{ width: 70 }}
                  floated='right'
                  src={tgtl}
                />
                <Card.Header>TOKEN-GTL - {row.token_amount}</Card.Header>
                <Card.Meta style={{ fontSize: 14, lineHeight: 1.1, color: "#4B4B4B" }}> Wybierz ten pakiet a otrzymasz <b>{row.token_amount}</b> wirtualnych tokentów GTL</Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button fluid color='red' disabled={false} onClick={() => isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : generateHotPayData(row.token_amount, row.price)}>
                  DOŁADUJ KONTO {row.price} PLN
                </Button>
              </Card.Content>
            </Card>

          ) : ""}

        </Card.Group>

        {form ?
          <form style={{ display: "none" }}
            id="order"
            action="https://platnosc.hotpay.pl/"
            method="post">
            <input required name="SEKRET" value={form.SEKRET} type="hidden" />
            <input required name="HASH" value={form.HASH} type="hidden" />
            <input required name="KWOTA" value={form.KWOTA} type="hidden" />
            <input required name="NAZWA_USLUGI" value={form.NAZWA_USLUGI} type="hidden" />
            <input required name="ADRES_WWW" value={form.ADRES_WWW} type="hidden" />
            <input required name="ID_ZAMOWIENIA" value={form.ID_ZAMOWIENIA} type="hidden" />
            <input name="EMAIL" value={form.EMAIL} type="hidden" />
            <input name="DANE_OSOBOWE" value={form.DANE_OSOBOWE} type="hidden" />
            <button type="submit">Submit</button>
          </form>
          : ""}


        <Divider />
        <Label color='red' ribbon>
          MIESIĘCZNE
        </Label>

        <List size='small'>
          <List.Item>
            {packageColor(4)}
            <List.Content><b>Pakiet Diamentowy</b> – dedykowany klubom lub organizacjom, które regularnie organizują cykliczne rozgrywki. Zapewnia pełny dostęp do systemu bez konieczności doładowywania tokenów</List.Content>
          </List.Item>

        </List>
        <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
          <Table striped compact celled unstackable size='small' color="grey">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell>Funkcjonalność</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(-1)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy Lite</span></Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(4)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy</span></Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2} style={{ lineHeight: "12px" }}> {packageColor(5)}<br /><span style={{ fontSize: 13 }}>Pakiet Diamentowy Plus</span></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row >
                <Table.Cell>Tworzenie turniejów w domyślnym pakiecie (funkcjonalności patrz tabela powyżej)</Table.Cell>
                <Table.Cell textAlign='center'>
                  {packageColor(1)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {packageColor(3)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {packageColor(3)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell>System ligowy - terminarz, kolejki, tabela - (zobacz przykład): <a href='https://turniej-generator.pl/info/system-ligowy' className="ModalInfo" target="_blank" rel="noopener noreferrer">https://turniej-generator.pl/info/system-ligowy</a> </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell>Limit rozgrywanych lig (jako kategorie np Liga 1 oraz Liga 2 itp) </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(2)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(0)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(0)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell style={{ lineHeight: "14px" }}>System ligowy - dodawanie zawodników do drużyny, definiowanie sędziego oraz możliwość dodania protokołu meczowego, a także zaawansowanych statystyk dla zawodników w drużynie </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell style={{ lineHeight: "14px" }}><Label color='green' size='mini'>NEW</Label> System ligowy - Dostęp do panelu menagera pojedynków <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "AllowMembersSetScores", name: "Dostęp do panelu menagera pojedynków", value: "" })}>ZOBACZ OPIS</span> </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell style={{ lineHeight: "14px" }}>System ligowy - dodanie zdjęcia drużyny</Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell style={{ lineHeight: "14px" }}>Dedykowana strona klubu  / organizacji / turniejów / ligi :
                  {dedicatedWebsites && dedicatedWebsites.length > 0 ?
                    dedicatedWebsites.map((row, i) =>
                      <span key={i} style={{ marginLeft: 5 }}>
                        (<a href={row.url} className="ModalInfo" target="_blank" rel="noopener noreferrer">{row.name}</a>),
                      </span>

                    ) : ""}
                  <br />
                  oraz dużo innych możliwości.
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>




              <Table.Row >
                <Table.Cell style={{ lineHeight: "12px" }}>Algorytm punktacji turniejowej <br />
                  <span style={{ fontSize: 12 }}>Możliwość zdefiniowania własnego algorytmu punktacji turniejowej, bez potrzeby edytowania każdego rekordu tabeli koncowej</span>

                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>


              <Table.Row >
                <Table.Cell style={{ lineHeight: "12px" }}>Możliwość dodawania wydarzeń typu zajęcia/treningi z zapisami online oraz widget do osadzenia na własnej stronie www<br />

                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell style={{ lineHeight: "12px" }}>Multimedia plików oraz Album Zdjęcia Google dla Ligi <br />
                  <span style={{ fontSize: 12 }}>Możliwość wysyłania plików/protokołów z pojedynków w formie pliku oraz możliwość dodania do każdego pojedynku link do albumu Zdjęcia Google</span>

                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>

              <Table.Row >
                <Table.Cell>Możliwość podpięcia własnej domeny lub subdomeny, przekierowanej na Nasz serwer (brak własnego hostingu)</Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Widget Ogólny - Ustawienia dedykowanej strony - do osadzenia na własnej stronie www<br />
                  <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell>Widget treningów/eventów - do osadzenia na własnej stronie www<br />
                  <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell>Widget turniejów/lig - do osadzenia na własnej stronie www<br />
                  <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell>Widget cykli - do osadzenia na własnej stronie www<br />
                  <span style={{ fontSize: 12 }}>Możliwość konfiguracji konkretnych komponentów na stronie a także możliwość dopasowania kolorystyki do własnej strony www</span></Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(false)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {retCharTab(true)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer style={{ backgroundColor: "#e8e8e8", fontWeight: "bold", fontSize: 15, lineHeight: "13px" }}>
              <Table.Row>
                <Table.Cell textAlign='center'>
                  <span style={{ fontSize: 12 }}><u>Bez konieczności zawierania umowy i zbędnych formalności</u>, możesz wykupić, przedłużać subskrypcję w dowolnym momencie jej trwania. Wystarczy kliknąć opcję "PRZEDŁUŻ/SUBSKRYPCJA", aby do okresu obowiązywania subskrypcji dodać kolejne 31 dni. Dodatkowo, masz możliwość zrezygnowania w dowolnym momencie, bez konieczności przedłużania na kolejny miesiąc, wówczas subskrypcja wygaśnie.</span>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {totalValueV2 === null ? <> <Icon loading name='spinner' /> PLN</> : <>{totalValueV2.subscription[0].price} PLN</>}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {totalValueV2 === null ? <> <Icon loading name='spinner' /> PLN</> : <>{totalValueV2.subscription[1].price} PLN</>}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {totalValueV2 === null ? <> <Icon loading name='spinner' /> PLN</> : <>{totalValueV2.subscription[2].price} PLN</>}
                </Table.Cell>
              </Table.Row>
            </Table.Footer>

          </Table>
        </div>
        {authContext.user && authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true ? "" :
          <Card.Group style={{ marginTop: 50, marginBottom: 50 }} centered>
            <Card link className={"cardpayd cardpayw"}>

              <Card.Content>

                <Card.Header> {packageColor(-1)} PAKIET DIAMENTOWY LITE</Card.Header>
                SUBSKRYPCJA MIESIĘCZNA - nie odnawia się automatycznie,

              </Card.Content>
              <Card.Content extra>
                {totalValueV2 === null ? <Icon loading name='spinner' /> : <BuySubscription isDemo={isDemo} issmall={false} btnname={"SUBSKRYPCJA " + totalValueV2.subscription[0].price + " PLN"} price={totalValueV2.subscription[0].price} subscription_type={-1} />}

              </Card.Content>
            </Card>

            <Card link className={"cardpayd cardpayw"}>

              <Card.Content>

                <Card.Header> {packageColor(4)} PAKIET DIAMENTOWY</Card.Header>
                SUBSKRYPCJA MIESIĘCZNA - nie odnawia się automatycznie,

              </Card.Content>
              <Card.Content extra>
                {totalValueV2 === null ? <Icon loading name='spinner' /> : <BuySubscription isDemo={isDemo} issmall={false} btnname={"SUBSKRYPCJA " + totalValueV2.subscription[1].price + " PLN"} price={totalValueV2.subscription[1].price} subscription_type={0} />}

              </Card.Content>
            </Card>


            <Card link className={"cardpayd cardpayw"}>
              <Card.Content>

                <Card.Header>{packageColor(5)} PAKIET DIAMENTOWY PLUS</Card.Header>

                SUBSKRYPCJA MIESIĘCZNA - nie odnawia się automatycznie,

              </Card.Content>
              <Card.Content extra>
                {totalValueV2 === null ? <Icon loading name='spinner' /> : <BuySubscription isDemo={isDemo} issmall={false} btnname={"SUBSKRYPCJA " + totalValueV2.subscription[2].price + " PLN"} price={totalValueV2.subscription[2].price} subscription_type={1} />}
              </Card.Content>
            </Card>


          </Card.Group>
        }

        <CallBackTG />
        <PackagesAssist openModalDataPA={openModalDataPA} setopenModalDataPA={setopenModalDataPA} />
      </>
  )
}

export default PackagesGTL;