
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid, Header, Icon, Container, Button, Segment, Divider, List, Modal, Card, ListItem } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../assets/tglogov6_small.png';
import druktg from '../../../assets/druktg.png';
import tgcomp from '../../../assets/tgcomp.png';
import plmap from '../../../assets/plmap.png';
import BracketGen from '../Bracket/BracketGen';
import AuthContext from '../../../context/authContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { changeInitialScale, changePageName, checkisglicko } from '../../../myfunctions';
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import Marquee from "react-fast-marquee";
import GenGroupPDF from './GenGroupPDF';
import SearchLic from '../../../tools/SearchLic';
import MyImageLazy from '../../../tools/MyImageLazy';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ConnectWS } from '../../../ConnectWS';
import { Markup } from 'interweave';
import FooterPage from '../Footer/FooterPage';
import { useHistory } from 'react-router-dom';
import CallBackTG from '../../../tools/CallBackTG';
import { LazyLoadComponent } from 'react-lazy-load-image-component';


function StartPage() {
  const [openModalMove, setopenModalMove] = useState(false)
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  let myRefScroll = useRef(null)
  const [dedicatedWebsites, setdedicatedWebsites] = useState([])

  const { t } = useTranslation();
  const [isOpenGG, setisOpenGG] = useState(false)
  let history = useHistory();
  function executeScroll() {

    if (myRefScroll && myRefScroll.current /* + other conditions */) {
      myRefScroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  useEffect(() => {
    changeInitialScale(1)
    changePageName()
    getCurrentPages()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function getCurrentPages() {

    ConnectWS("/dedicated/getDedicatedWebsites", null, {}, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data.length > 0) {
          setdedicatedWebsites(response.data)
        }
      } else {
        console.log(response)
      }

    });
  }


  const game11 = {
    id: "111",
    name: "",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "11",
          name: t("Gracz") + " 1",
          url_img: null
        },
        score: {
          score: 0
        },
      },
      visitor: {
        team: {
          id: "18",
          name: t("Gracz") + " 8",
          url_img: null
        },
        score: {
          score: 1
        }
      }
    }
  };
  const game12 = {
    id: "112",
    name: "",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "14",
          name: t("Gracz") + " 4",
          url_img: null
        },
        score: {
          score: 3
        },
      },
      visitor: {
        team: {
          id: "15",
          name: t("Gracz") + " 5",
          url_img: null
        },
        score: {
          score: 1
        }
      }
    }
  };
  const game13 = {
    id: "113",
    name: "",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "12",
          name: t("Gracz") + " 2",
          url_img: null
        },
        score: {
          score: 3
        },
      },
      visitor: {
        team: {
          id: "16",
          name: t("Gracz") + " 6",
          url_img: null
        },
        score: {
          score: 1
        }
      }
    }
  };
  const game14 = {
    id: "114",
    name: "",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "13",
          name: t("Gracz") + " 3",
          url_img: null
        },
        score: {
          score: 3
        },
      },
      visitor: {
        team: {
          id: "17",
          name: t("Gracz") + " 7",
          url_img: null
        },
        score: {
          score: 1
        }
      }
    }
  };


  const game2 = {
    id: "2",
    name: "półfinał",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "11",
          name: t("Gracz") + " 1",
          url_img: null
        },
        score: {
          score: 3
        },
        seed: {
          displayName: "",
          rank: 1,
          sourceGame: game11,
          sourcePool: {}
        }
      },
      visitor: {
        team: {
          id: "14",
          name: t("Gracz") + " 4",
          url_img: null
        },
        score: {
          score: 1
        },
        seed: {
          displayName: "",
          rank: 1,
          sourceGame: game12,
          sourcePool: {}
        }
      }
    }
  };

  const game3 = {
    id: "3",
    name: "półfinał",
    scheduled: Number(new Date()),
    sides: {
      home: {
        team: {
          id: "12",
          name: t("Gracz") + " 2",
          url_img: null
        },
        score: {
          score: 3
        },
        seed: {
          displayName: "",
          rank: 1,
          sourceGame: game13,
          sourcePool: {}
        }
      },
      visitor: {
        team: {
          id: "13",
          name: t("Gracz") + " 3",
          url_img: null
        },
        score: {
          score: 2
        },
        seed: {
          displayName: "",
          rank: 1,
          sourceGame: game14,
          sourcePool: {}
        }
      }
    }
  };


  const games = [
    {
      id: "1",
      name: "Finał",
      scheduled: Number(new Date()),
      sides: {
        home: {
          team: {
            id: "11",
            name: t("Gracz") + " 1",
            url_img: null
          },
          score: {
            score: 0
          },
          seed: {
            displayName: "",
            rank: 1,
            sourceGame: game2,
            sourcePool: {}
          }
        },
        visitor: {
          team: {
            id: "12",
            name: t("Gracz") + " 2",
            url_img: null
          },
          score: {
            score: 3
          },
          seed: {
            displayName: "",
            rank: 1,
            sourceGame: game3,
            sourcePool: {}
          }
        }
      }
    }
  ];






  return (

    <div className='startPageTG'>

      <Segment style={{ padding: '0 0 70px 0', position: "relative" }} vertical  >

        <Container style={{ marginTop: 10 }}>
          <Grid stackable verticalAlign='middle' style={{ paddingTop: 20 }} >
            <Grid.Row>
              <Grid.Column verticalAlign='middle' width={7} style={{ paddingTop: 20 }}>
                <Header as='h2' style={{ lineHeight: "28px", fontSize: 23 }}>
                  {t("stronaStartowa.szukasz_generatora_turniejow")}<br />
                  {t("stronaStartowa.szukasz_generatora_turniejow2")}

                </Header>
                <p >
                  <Trans i18nKey="stronaStartowa.dobrze_sie_sklada" />
                </p>
                <Header as='h2' >
                  {t("stronaStartowa.dobrze_trafiles")}
                </Header>
                <p >
                  <Trans i18nKey="stronaStartowa.z_nami_jestes" />
                </p>
                <div>
                  <Grid unstackable style={{ margin: 0, marginTop: 20, padding: 0 }}>
                    <Grid.Row columns={3} style={{ margin: 0, padding: 0 }}>
                      <Grid.Column textAlign='center' style={{ margin: 0, padding: 10 }} >
                        <div className='btnstbg1 cursorPointer' style={{ fontSize: width > 700 ? 13 : 11, lineHeight: width > 700 ? "17px" : "13px" }} onClick={() => executeScroll()}>

                          <Icon name='eye' size='big' style={{ marginBottom: 7 }} /><br />
                          {t("stronaStartowa.sprawdz_przykladowe_turnieje")}

                        </div>
                      </Grid.Column>

                      <Grid.Column textAlign='center' style={{ margin: 0, padding: 10 }} >
                        <div className='btnstbg4 cursorPointer' style={{ fontSize: width > 700 ? 13 : 11, lineHeight: width > 700 ? "17px" : "13px" }} onClick={() => history.push("/zapisy-na-treningi-online")} >
                          <Icon name='code' size='big' style={{ marginBottom: 7 }} /><br />
                          {t("stronaStartowa.sprawdz_treningi")}


                        </div>
                      </Grid.Column>

                      <Grid.Column textAlign='center' style={{ margin: 0, padding: 10 }} >
                        <div className='btnstbg2 cursorPointer' style={{ fontSize: width > 700 ? 13 : 11, lineHeight: width > 700 ? "17px" : "13px" }} onClick={() => authContext.changeModalInfo({ nameModal: "TCharytatywny", name: "Turniej charytatywny", value: "" })} >
                          <Icon name='heart' size='big' style={{ marginBottom: 7 }} /><br />
                          {t("stronaStartowa.organizujesz_turniej_charytatywny")}

                        </div>
                      </Grid.Column>
                      {/* 
                      <Grid.Column textAlign='center' style={{ margin: 0, padding: 10 }} >
                        <div className='btnstbg4 cursorPointer' onClick={() => authContext.changeModalInfo({ nameModal: "SearchTournamentTG", name: "Wyszukaj turniej", value: "" })} >
                          <Icon name='search' size='big' style={{ marginBottom: 7 }} /><br />
                          {t("stronaStartowa.wyszukaj_turniej")}

                        </div>
                      </Grid.Column>
*/}
                    </Grid.Row >

                  </Grid>
                </div>

              </Grid.Column>
              <Grid.Column className='bgCenterPanel2' textAlign='center' width={9}>

                <BracketGen ttname={""} ttype={""} offZoom={true} totalMembers={0} remoteJson={games} onlyView={1} />


              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Container>
      </Segment>



      <Segment className='bgCenterPanel3' textAlign='center' style={{ paddingTop: '30px', paddingBottom: '30px' }} vertical >

        <MyImageLazy height={60} src={LogoTeamLigaGenerator} alt="logo" />
        <Grid celled='internally' columns='equal' stackable >
          <Grid.Row textAlign='center'>
            <Grid.Column style={{ paddingBottom: 30, paddingTop: 30 }}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                {t("stronaStartowa.teamliga_oferuje")}
              </Header>
              <List >
                <List.Item >
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.system_pucharowy_1")}</span><br /><span style={{ fontSize: 12 }}>{t("SystemGier.system_pucharowy_1_info")}</span>
                </List.Item>
                <List.Item >
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.system_pucharowy_2")}</span><br /><span style={{ fontSize: 12 }}>{t("SystemGier.system_pucharowy_2_info")}</span>
                </List.Item>
                {/* 
                <List.Item >
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.system_pucharowy_3")}</span><br /><span style={{ fontSize: 12 }}>{t("SystemGier.system_pucharowy_3_info")}</span>
                </List.Item>
                */}
           
                <List.Item >
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.brazylijski")}</span><br /><span style={{ fontSize: 12 }}>{t("SystemGier.brazylijski_1_info")}</span>
                </List.Item>
                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' />  {t("SystemGier.grupowo_pucharowy")}</span>


                </List.Item>
                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.grupowo_grupowy")}</span>
                </List.Item>
                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.kazdy_z_kazdym")}</span>
                </List.Item>

                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.szwajcarski")}</span>
                </List.Item>
                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.szwajcarsko_pucharowy")}</span>
                </List.Item>
                <List.Item >
                  <span><Icon name='arrow alternate circle right' color='red' /> {t("SystemGier.indywidualny")}</span><br /><span style={{ fontSize: 12 }}>{t("SystemGier.indywidualny_1_info")}</span>
                </List.Item>
                <List.Item>
                  <span><Icon name='arrow alternate circle right' color='red' />{t("SystemGier.liga")}</span>


                </List.Item>

              </List>

            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 30, paddingTop: 30 }} textAlign='center'>

              <Header as='h3' style={{ fontSize: '2em', margin: 0, padding: 0 }} textAlign='center' >
                <Trans i18nKey="stronaStartowa.jeden_generator_na_pl" />
              </Header>
              <MyImageLazy maxHeight={360} src={plmap} alt="tgplmap" />


            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 30, paddingTop: 30 }}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                {t("stronaStartowa.a_to_nie_wszystko")}
              </Header>
              <Trans i18nKey="stronaStartowa.jeden_generator_na_pl_body" />
              <List >
                <List.Item >
                  <Icon name='winner' color='red' />

                  {t("stronaStartowa.z_naszym_generatorem")}
                </List.Item>
                <List.Item >
                  <Icon name='registered' color='red' />
                  {t("stronaStartowa.rejestracja_zawodników_online")}

                </List.Item>
                <List.Item>
                  <Icon name='fire' color='red' />
                  {t("stronaStartowa.sprawne_zarzadzanie")}

                </List.Item>
                <List.Item>
                  <List.Content>
                    <Icon name='pie chart' color='red' />
                    {t("stronaStartowa.statystyki")} {checkisglicko() ? <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "GLICKO", name: t("RankingGLICKO"), value: "" })}>{t("RankingGLICKO")}</span> : <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "RELO", name: t("RankingELO"), value: "" })}>{t("RankingELO")}</span>}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <Icon name='user' color='red' />
                    <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "lictl", name: t("LicencjaTL"), value: "" })}>{t("LicencjaTL")}</span> {t("stronaStartowa.dla_kazdego_zawodnika")}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <Icon name='fork' color='red' />
                    {t("stronaStartowa.rozstawienie_uczestnikow0")} <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "ruauto", name: t("stronaStartowa.rozstawienie_uczestnikow0") + t("stronaStartowa.rozstawienie_uczestnikow1") + t("stronaStartowa.rozstawienie_uczestnikow2"), value: "" })}>{t("stronaStartowa.rozstawienie_uczestnikow1")}</span>  {t("stronaStartowa.rozstawienie_uczestnikow2")}
                  </List.Content>
                </List.Item>

              </List>


            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ paddingBottom: 30 }} vertical>


        <Container >
          <Grid stackable verticalAlign='middle'  >
            <Grid.Row>

              <Grid.Column textAlign='center' width={16} >

                <Segment textAlign='left' basic>



                  <Header as='h1' textAlign='center' style={{ marginBottom: 20 }}>
                    {t("stronaStartowa.zalety")}
                  </Header>
                  <Grid stackable verticalAlign='middle' >
                    <Grid.Row>
                      <Grid.Column verticalAlign='middle' width={9} style={{ textAlign: "justify" }} >
                        <List ordered>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety1" />

                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety2" />
                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety3" />
                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety4" />
                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety5" />
                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety6" />
                          </ListItem>
                          <ListItem >
                            <Trans i18nKey="stronaStartowa.zalety7" />
                          </ListItem>
                        </List>

                      </Grid.Column>
                      <Grid.Column verticalAlign='middle' textAlign='center' width={7} only='tablet computer'>

                        <MyImageLazy maxHeight={335} centered src={tgcomp} alt="tg" />
                      </Grid.Column>
                      <Trans i18nKey="stronaStartowa.zalety_podsumowanie" />

                    </Grid.Row>
                  </Grid>




                </Segment>

              </Grid.Column>


            </Grid.Row>

          </Grid>

          <Divider style={{ marginTop: 40, marginBottom: 50 }} />
          <Header as='h1' textAlign='center' style={{ margin: 0 }}>
            <Trans i18nKey="stronaStartowa.liga1" />
          </Header>
          <Header as='h3' textAlign='center' style={{ margin: 5 }}>
            <Trans i18nKey="stronaStartowa.liga2" />
          </Header>

          <Grid stackable verticalAlign='middle' >
            <Grid.Row>
              <Grid.Column verticalAlign='middle' textAlign={width > 800 ? 'right' : "left"} width={6} >
                <div style={{ textAlign: "center", marginBottom: 50 }} >
                  <LazyLoadComponent >
                    <Carousel

                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={3000}
                      arrows={false}


                    >
                      {[
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/1.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/2.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/3.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/4.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/5.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/6.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/7.png' },
                        { itemImageSrc: 'https://turniej-generator.pl/img/page/8.png' },
                      ].map((item, i) =>
                        <div key={i} >
                          <img src={item.itemImageSrc} className="limgfi" alt={"zdjecie"} style={{ maxHeight: 700 }} />
                        </div>
                      )}
                    </Carousel>


                  </LazyLoadComponent>
                </div>

                <Header as='h3' style={{ marginTop: 20, marginBottom: 20 }}>
                  <Trans i18nKey="stronaStartowa.liga10" />
                </Header>
                <List bulleted>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga11" />
                  </ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga12" />
                  </ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga13" />
                  </ListItem>
                </List>

                <Trans i18nKey="stronaStartowa.liga14" />
                <br />
                <Trans i18nKey="stronaStartowa.liga15" />
                <br />
                <Button style={{ marginTop: 20 }} size='mini' color='blue' onClick={() => history.push("/info/system-ligowy")}  >
                  ZOBACZ PRZYKŁADOWE LIGI W NASZYM SYSTEMIE
                </Button>
              </Grid.Column>
              <Grid.Column verticalAlign='middle' width={10}  >

                <Header as='h4' textAlign='left' style={{ marginTop: 20, marginBottom: 20 }}>
                  <Trans i18nKey="stronaStartowa.liga3" />
                </Header>
                <List ordered style={{ marginBottom: 30 }}>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga4" />
                  </ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga5" />
                  </ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga6" /></ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga7" /></ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga8" /></ListItem>
                  <ListItem >
                    <Trans i18nKey="stronaStartowa.liga9" /> </ListItem>
                     <ListItem >
                                      <Trans i18nKey="stronaStartowa.liga9a" /><span style={{ marginLeft: 10 }} className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "AllowMembersSetScores", name: "Dostęp do panelu menagera pojedynków", value: null })}>ZOBACZ OPIS</span> </ListItem>

                </List>
               
                
                <Carousel
                  draggable={width > 800 ? false : true}
                  swipeable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={width < 800 ? true : true}
                  autoPlaySpeed={3000}

                >

                  <div>
                   <Container textAlign='center'>
                  <MyImageLazy maxHeight={600} openView={true} src={"https://turniej-generator.pl/img/league/liga1.png"} alt={"img"} />
                  </Container>
                  </div>
                  <div>
                  <Container textAlign='center'>
                      <MyImageLazy maxHeight={600} openView={true} src={"https://turniej-generator.pl/img/bg/bgslv2.jpg"} alt={"img2"} />
                      </Container>
                  </div>
                
                </Carousel>
               
              </Grid.Column>
            </Grid.Row>
          </Grid>







        </Container>
        <br /><br /><br />
        <Segment className='bgCenterPanel3' style={{ paddingTop: '30px', paddingBottom: '30px' }} vertical textAlign='center'>
          <MyImageLazy height={60} src={LogoTeamLigaGenerator} alt="logo" />
          <br />

          <Container style={{ marginTop: 15 }}>


            <Marquee gradient={true} speed={width < 700 ? 60 : 40} gradientWidth={20} gradientColor={"#dedede"} >
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/badminton.png"} alt="badminton" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/tennis.png"} alt="tennis" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/tabletennis.png"} alt="tabletennis" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/volleyball.png"} alt="volleyball" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/basketball.png"} alt="basketball" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/soccer.png"} alt="soccer" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/e-sport.png"} alt="esport" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/othersport.png"} alt="othersport" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/chess.png"} alt="chess" />
              <MyImageLazy className='imgsports' src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/dart.png"} alt="dart" />


            </Marquee>
          </Container>
        </Segment>




        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <Button animated='fade' fluid color="google plus" as='a' size='massive' onClick={() => Number(process.env.REACT_APP_IN_CONSTRUCTION) === 0 ? history.push("/login") : authContext.changeModalInfo({ nameModal: "all", name: "Szanowni Państwo", value: "Generator Turniejów jest w trakcie tworzenie i uruchamiania aplikacji internetowej. Przepraszamy za niedogodności i zapraszamy wkrótce." })}>
            <Button.Content visible>{t("stronaStartowa.btn_zobacz_aplikacje").toUpperCase()}</Button.Content>
            <Button.Content hidden>{t("stronaStartowa.btn_dobra_decyzja").toUpperCase()}</Button.Content>
          </Button>
        </Divider>

        <Container >




          <Grid stackable verticalAlign='middle' className='bgCenterPanel2' >
            <Grid.Row>


              <Grid.Column verticalAlign='middle' textAlign='center' width={6} >
                <div ref={myRefScroll}>
                  <Header as='h3'  >

                    <Trans i18nKey="stronaStartowa.przyklady_turniejow" />
                  </Header>
                  <List >
                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='blue' />  <Trans i18nKey="stronaStartowa.przyklady_turniejow2" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/tg/aazig" className="linktl2" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='blue' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>
                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='red' /> <Trans i18nKey="stronaStartowa.przyklady_turniejow3" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/tsv/1097/1873" className="linktl" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='red' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>
                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='red' /> <Trans i18nKey="stronaStartowa.przyklady_turniejow4" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/tsv/1097/1874" className="linktl" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='red' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>

                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='red' /> <Trans i18nKey="stronaStartowa.przyklady_turniejow5" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/tsv/1097/1875" className="linktl" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='red' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>
                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='red' /> <Trans i18nKey="stronaStartowa.przyklady_turniejow6" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/tsv/1097/1876" className="linktl" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='red' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>
                    <List.Item >
                      <span><Icon name='arrow alternate circle right' color='red' /> <Trans i18nKey="stronaStartowa.przyklady_turniejow7" /> </span><br /><span style={{ fontSize: 12 }}><a href="https://turniej-generator.pl/info/system-ligowy" className="linktl" target="_blank" rel="noopener noreferrer"><Icon name="linkify" color='red' /><Trans i18nKey="stronaStartowa.przyklady_turniejow1" /></a></span>
                    </List.Item>


                  </List>
                </div>
              </Grid.Column>
              <Grid.Column verticalAlign='middle' width={10} >
                <SearchLic pageLogin={false} />
              </Grid.Column>


            </Grid.Row>

          </Grid>



        </Container>
      </Segment>

      <Segment className='bgCenterPanel3' style={{ paddingTop: '30px', paddingBottom: '30px' }} vertical>
        <Container >


          <Grid stackable verticalAlign='middle' style={{ paddingTop: 10 }} celled='internally' columns='equal' >
            <Grid.Row>
              <Grid.Column verticalAlign='middle' width={10}>
                <Header textAlign='center' as='h3' >
                  {t("stronaStartowa.popularne_drabinki_w_wersji_papierowej")}
                  <Header.Subheader>
                    <i>{t("stronaStartowa.drabinka_jest_kompatybilna")}</i>
                  </Header.Subheader>
                  <br />
                  <Header.Subheader>
                    <i>{t("stronaStartowa.system_brazylijski_opis")}</i>
                  </Header.Subheader>
                </Header>
                <Card.Group centered style={{ paddingTop: '30px' }}>
                  <Card color='blue'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_8_zawodnikow")} <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_1")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_8_gtl.pdf'} target="blank" title="Drabinka 8 zawodników - System pucharowy - przegrany odpada">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>
                  <Card color='blue'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_16_zawodnikow")}   <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_1")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_16_gtl.pdf'} target="blank" title="Drabinka 16 zawodników - System pucharowy - przegrany odpada">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>
                  <Card color='blue'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_32_zawodnikow")}   <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_1")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_32_gtl.pdf'} target="blank" title="Drabinka 32 zawodników - System pucharowy - przegrany odpada">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>
                  <Card color='blue'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_64_zawodnikow")} <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_1")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_64_gtl.pdf'} target="blank" title="Drabinka 64 zawodników - System pucharowy - przegrany odpada">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>

                  <Card color='green'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_8_zawodnikow")}  <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_2")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_8v2_gtl.pdf'} target="blank" title="Drabinka 8 zawodników - System pucharowy - do dwóch przegranych">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>
                  <Card color='green'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_16_zawodnikow")}  <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_2")}</span> </Card.Header>


                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_16v2_gtl.pdf'} target="blank" title="Drabinka 16 zawodników - System pucharowy - do dwóch przegranych">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>

                  </Card>
                  <Card color='green'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_32_zawodnikow")}  <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_2")}</span> </Card.Header>


                    </Card.Content>
                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_32v2_gtl.pdf'} target="blank" title="Drabinka 32 zawodników - System pucharowy - do dwóch przegranych">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>
                  </Card>
                  <Card color='green'>
                    <Card.Content>

                      <Card.Header style={{ fontSize: 17 }}><Icon name="file pdf" /> {t("SystemGier.drabinka_64_zawodnikow")}  <br /> <span style={{ fontSize: 13 }}>{t("SystemGier.system_pucharowy_2")}</span> </Card.Header>

                    </Card.Content>

                    <a href={process.env.REACT_APP_PUBLIC_URL + '/pdf/system_pucharowy_64v2_gtl.pdf'} target="blank" title="Drabinka 64 zawodników - System pucharowy - do dwóch przegranych">
                      <Button icon labelPosition='right' size='mini' fluid >
                        <Icon name='file pdf' /> {t("PobierzPdf").toUpperCase()}
                      </Button>
                    </a>
                  </Card>

                </Card.Group>
              </Grid.Column>
              <Grid.Column textAlign='center' width={6}>

                <Header textAlign='center' as='h3' >
                  {t("stronaStartowa.grupy_w_wersji_papierowej")}
                  <Header.Subheader>
                    <i>{t("stronaStartowa.gotowe_grupy_do_druku")}</i>
                  </Header.Subheader>

                </Header>
                <MyImageLazy maxHeight={400} src={druktg} alt="druk" />
                <Button style={{ marginTop: 10 }} id="gengroupclick" color="blue" as='a' size='large' onClick={() => setisOpenGG(true)}>{t("stronaStartowa.drukuj_grupy")}</Button>


              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Container>
      </Segment>

      <div style={{ paddingBottom: 10 }}>
        <Carousel
          draggable={width > 800 ? false : true}
          swipeable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={width < 800 ? false : true}
          autoPlaySpeed={4000}

        >
          {/* 
          <div><Container >
            <Header as='h1' textAlign='center' style={{ marginBottom: 30, marginTop: 20 }}>
              <Trans i18nKey="stronaStartowa.mpwpp1" />

            </Header>
            <Grid stackable verticalAlign='middle' className='bgCenterPanel2' >
              <Grid.Row>

                <Grid.Column verticalAlign='middle' width={5} >

                  <Header as='h2' icon>
                    <Icon name="handshake" circular color='yellow' />
                 
                    <HeaderSubheader style={{ marginTop: 10, fontSize: 16, color: "#242323" }}>
                      <Trans i18nKey="stronaStartowa.mpwpp2" />
                      <br /> <br />
                      <Trans i18nKey="stronaStartowa.mpwpp3" />
                      <br /> <br />
                      <Trans i18nKey="stronaStartowa.mpwpp4" />

                      <br /> <br />
                      <Trans i18nKey="stronaStartowa.mpwpp5" /> <a href="https://turniej-generator.pl/gppwpp" className='linktl' target={"_blank"} rel="noopener noreferrer">https://turniej-generator.pl/gppwpp</a>

                    </HeaderSubheader>
                  </Header>




                </Grid.Column>
                {width < 800 ? "" :
                  <Grid.Column verticalAlign='middle' width={11} >
                    <MPWPPCard theme={"light"} />
                  </Grid.Column>
                }
              </Grid.Row>
            </Grid>
          </Container >
          </div>

          */}
          <div>
            <Container>

              <Header as='h1' textAlign='center' style={{ marginBottom: 50, marginTop: 20 }}>
                <Trans i18nKey="stronaStartowa.dart1" />
              </Header>

              <Grid stackable verticalAlign='middle' className='bgCenterPanel2' >
                <Grid.Row textAlign='center'>

                  <Grid.Column verticalAlign='middle' width={6} >
                    <Header as="h3" style={{ lineHeight: "25px" }}><Trans i18nKey="stronaStartowa.dart2" /></Header>
                    <Trans i18nKey="stronaStartowa.dart3" />
                    <br /><br />
                    <Trans i18nKey="stronaStartowa.dart4" /> <a href="https://turniej-generator.pl/info/dart" className='linktl' target={"_blank"} rel="noopener noreferrer">https://turniej-generator.pl/info/dart</a>


                  </Grid.Column>
                  <Grid.Column verticalAlign='middle' width={4} >
                    <MyImageLazy openView={true} maxHeight={260} src={process.env.REACT_APP_PUBLIC_URL + "/img/dart/1.png"} alt="dart1" />
                    <br />  <Trans i18nKey="stronaStartowa.dart5" />

                  </Grid.Column>
                  <Grid.Column verticalAlign='middle' width={2} >
                    <MyImageLazy openView={true} maxHeight={260} src={process.env.REACT_APP_PUBLIC_URL + "/img/dart/2.png"} alt="dart1" />
                    <br /><Trans i18nKey="stronaStartowa.dart6" />

                  </Grid.Column>

                  <Grid.Column verticalAlign='middle' width={4} >
                    <MyImageLazy openView={true} maxHeight={360} src={process.env.REACT_APP_PUBLIC_URL + "/img/dart/7.png"} alt="dart1" />
                    <br /><Trans i18nKey="stronaStartowa.dart8" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

            </Container>
          </div>

          {dedicatedWebsites && dedicatedWebsites.length > 0 ?
            dedicatedWebsites.map((row, i) =>
              <div key={i} >
                <Container style={{ marginBottom: 50, marginTop: 40 }} >



                  <Grid stackable verticalAlign='middle' className='bgCenterPanel2' >
                    <Grid.Row textAlign='center'>

                      <Grid.Column verticalAlign='middle' width={10} >
                        <Header as='h1' textAlign='center' style={{ marginBottom: 50, marginTop: 20 }}>
                          {row.name}
                        </Header>

                        <Markup content={row.description} />
                        <br /> <br />
                        Dedykowana strona: <a href={row.url} className='linktl' target={"_blank"} rel="noopener noreferrer">{row.url}</a>

                      </Grid.Column>
                      <Grid.Column verticalAlign='middle' width={6} >
                        <MyImageLazy maxHeight={500} openView={true} src={row.img_file} alt={"img" + i} />
                      </Grid.Column>

                    </Grid.Row>
                  </Grid>

                </Container>
              </div>
            ) : <div></div>}
        </Carousel>
      </div>



      <CallBackTG />

      { /* 
      <Segment vertical style={{ paddingTop: '10px', paddingBottom: '50px' }} >
        <Container textAlign='center'>
          <Message>
            <Message.Header><Icon name="adversal" />Reklama Google</Message.Header>
            <AdSense.Google
              client='ca-pub-7406426760252636'
              slot='4652315741'
              style={{ display: 'block', textAlign: "center" }}
              layout='in-article'
              format='fluid'
            />
          </Message>

        </Container>

      </Segment>
*/ }

      <Modal
        dimmer='blurring'
        centered={true}
        onClose={() => setopenModalMove(false)}
        onOpen={() => setopenModalMove(true)}
        open={openModalMove}

      >

        <Modal.Content style={{ backgroundColor: "#121212" }}>
          <video controls autoPlay poster={process.env.REACT_APP_PUBLIC_URL + '/move/gtlp.jpg'} onEnded={() => setopenModalMove(false)} style={{ width: "100%", height: "100%" }}>
            <source src={process.env.REACT_APP_PUBLIC_URL + '/move/gtl.mp4'} type="video/mp4"></source>
          </video>


        </Modal.Content>

      </Modal>

      <Modal
        dimmer='blurring'
        centered={true}
        size={"large"}
        onClose={() => ""}
        onOpen={() => setisOpenGG(true)}
        open={isOpenGG}
      >

        <Modal.Content>
          <GenGroupPDF />
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setisOpenGG(false)}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
      <footer><FooterPage /></footer>
    </div>
  )
}

export default StartPage;