import bergerTable from 'berger-table-generator';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Grid, Header, Icon, Image, Message, Modal, Table } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../assets/tglogov6_small.png';
import { getZoomT, numberToCharTG, saveCsvTab } from '../../../myfunctions';
import { useReactToPrint } from 'react-to-print';
import { QRCode } from 'react-qrcode-logo';
import tglh from '../../../assets/tglh.png';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';


function BracketGenHarm(propsRoot) {
    const [open, setOpen] = useState(false)
    const [idt, setidt] = useState(null)
    const [rowData, setrowData] = useState([])
    const [chboxg, setchboxg] = useState(false)
    const { t } = useTranslation();


    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


    function reverseScore(a, b, countMatches) {

        if (Number(a) < Number(b)) {
            return countMatches[b].member_name + " vs " + countMatches[a].member_name
        } else {
            return countMatches[a].member_name + " vs " + countMatches[b].member_name
        }

    }

    function getTimeMatchv2(match_place, retOnlyTime) {


        if (match_place) {
            if (retOnlyTime) {
                return JSON.parse(match_place).match_dt
            } else {
                return <div style={{ fontSize: 12, lineHeight: "14px" }}>
                    {JSON.parse(match_place).place_desc ? <> <Icon color='grey' name="point" />{JSON.parse(match_place).place_desc}<br /> </> : ""}
                    {JSON.parse(match_place).match_dt ? <> <Icon color='grey' name="calendar" />{JSON.parse(match_place).match_dt}<br /> </> : ""}

                </div>
            }

        } else {
            return ""
        }


    }

    function getTimeMatch(a, b, countMatches, rowmatches, retOnlyTime) {

        let m1 = countMatches[a].tournament_member_id
        let m2 = countMatches[b].tournament_member_id
        if (rowmatches) {
            for (const row of rowmatches) {

                if ((m1 === row.tournament_member1_id && m2 === row.tournament_member2_id) || (m2 === row.tournament_member1_id && m1 === row.tournament_member2_id)) {

                    if (row.match_place) {
                        if (retOnlyTime) {
                            return JSON.parse(row.match_place).match_dt
                        } else {
                            return <div style={{ fontSize: 12, lineHeight: "14px" }}>
                                {JSON.parse(row.match_place).place_desc ? <> <Icon color='grey' name="point" />{JSON.parse(row.match_place).place_desc}<br /> </> : ""}
                                {JSON.parse(row.match_place).match_dt ? <> <Icon color='grey' name="calendar" />{JSON.parse(row.match_place).match_dt}<br /> </> : ""}

                            </div>
                        }

                    } else {
                        return ""
                    }
                }
            }
        }

    }

    function sortM(a, b) {
        return new Date(getDateS(a.match_dt)).getTime() - new Date(getDateS(b.match_dt)).getTime();
    }
    function sortMData(a, b) {
        return a.group_nr - b.group_nr || new Date(getDateS(a.match_dt)).getTime() - new Date(getDateS(b.match_dt)).getTime();
    }

    function getDateS(mdt) {
        try {
            if (mdt === null || mdt === "") {
                return "2555-01-01 12:00:00"
            } else {
                return mdt
            }

        } catch (e) {
            return mdt
        }
    }


    function char_to_int(character) {
        switch (character) {
            case 'I': return 1;
            case 'V': return 5;
            case 'X': return 10;
            case 'L': return 50;
            case 'C': return 100;
            case 'D': return 500;
            case 'M': return 1000;
            default: return -1;
        }
    }
    function romanToArabic(roman) {
        if (roman == null)
            return -1;
        var totalValue = 0,
            value = 0, // Initialise!
            prev = 0;

        for (var i = 0; i < roman.length; i++) {
            var current = char_to_int(roman.charAt(i));
            if (current > prev) {
                // Undo the addition that was done, turn it into subtraction
                totalValue -= 2 * value;
            }
            if (current !== prev) { // Different symbol?
                value = 0; // reset the sum for the new symbol
            }
            value += current; // keep adding same symbols
            totalValue += current;
            prev = current;
        }
        return totalValue;
    }




    function genMatchList() {
        try {
            let allGameBracket = []
            function loopJsonB(jsonTMP) {

                let alljsonTMP = jsonTMP
                Object.entries(jsonTMP).forEach(
                    ([key1, value1]) => {
                        if (key1 === "sides") {
                            if (alljsonTMP.match_place) {
                                allGameBracket.push({
                                    group_nr: 1000,
                                    group_name: alljsonTMP.name,
                                    match: alljsonTMP.sides.home.team.name + " vs " + alljsonTMP.sides.visitor.team.name,
                                    match_dt: getTimeMatchv2(alljsonTMP.match_place, true),
                                    match_time: getTimeMatchv2(alljsonTMP.match_place, false),

                                })


                            }

                            if (value1.home.seed && value1.home.seed.sourceGame) {
                                loopJsonB(value1.home.seed.sourceGame)
                            }
                            if (value1.visitor.seed && value1.visitor.seed.sourceGame) {
                                loopJsonB(value1.visitor.seed.sourceGame)
                            }
                        }
                    }
                );
            }

            let tmp2 = []

            let element = JSON.parse(JSON.stringify(propsRoot.remoteJsonFull))
            // propsRoot.tournamentJsonRoot.subtournaments.forEach(element => {

            if (element.type === "GROUPS_CUP" || element.type === "GROUP" || element.type === "GROUPS_GROUP") {

                element.groups.forEach(element1 => {

                    //console.log(element1)
                    let arr = bergerTable(element1.members.length)

                    arr.forEach((row) => {
                        row.forEach((row1) => {
                            //  console.log("asdsss", row1.teamA, row1.teamB, element1.members)
                            tmp2.push({
                                group_nr: romanToArabic(element1.group_name.replaceAll("Grupa ", "")),
                                group_name: element1.group_name,
                                match: reverseScore(row1.teamA, row1.teamB, element1.members),
                                match_time: getTimeMatch(row1.teamA, row1.teamB, element1.members, element1.matches, false),
                                match_dt: getTimeMatch(row1.teamA, row1.teamB, element1.members, element1.matches, true)
                            })
                        })
                    })


                });


                if (element.children && element.children.length > 0) {
                    element.children.forEach(element1 => {
                        console.log(element1)
                        if (element1.type.includes("CUP")) {

                            element1.tournament_json.forEach(element2 => {
                                loopJsonB(element2)
                            })
                        } else if (element1.type === "GROUP" || element1.type === "GROUPS_GROUP") {
                            element1.groups.forEach(element2 => {

                                //console.log(element1)
                                let arr = bergerTable(element2.members.length)

                                arr.forEach((row) => {
                                    row.forEach((row1) => {
                                        //  console.log("asdsss", row1.teamA, row1.teamB, element1.members)
                                        tmp2.push({
                                            group_nr: romanToArabic(element2.group_name.replaceAll("Grupa ", "")),
                                            group_name: element2.group_name,
                                            match: reverseScore(row1.teamA, row1.teamB, element2.members),
                                            match_time: getTimeMatch(row1.teamA, row1.teamB, element2.members, element2.matches, false),
                                            match_dt: getTimeMatch(row1.teamA, row1.teamB, element2.members, element2.matches, true)
                                        })
                                    })
                                })


                            });
                        }
                    });
                }

            }



            // });
            tmp2 = tmp2.concat(allGameBracket)

            setrowData(tmp2.sort(sortM))

            return []
        } catch (e) {
            console.log(e)
            return []
        }
    }

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

        if (propsRoot.show) {
            setOpen(true)

        }
        if (propsRoot.linkTournament) {
            setidt(propsRoot.linkTournament)
        }
        if (propsRoot.countMatches && propsRoot.countMatches.length > 0 && propsRoot.countMatches[0].members && propsRoot.show) {
            genMatchList()


        }


        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.show, propsRoot.linkTournament])// eslint-disable-line react-hooks/exhaustive-deps


    function closeModal() {
        setOpen(false)
        propsRoot.setopenModalHarm(false)
    }




    const componentRefPrint = useRef();
    const handlePrintTab = useReactToPrint({
        content: () => componentRefPrint.current,
    });

    let group_name = "Grupa I"

    function sgroup_name(val) {
        group_name = val
        return <div className='page-break'></div>
    }


    function checkboxchangedata(checked) {
        let datatmp = rowData
        if (checked) {
            setrowData(datatmp.sort(sortMData))
        } else {
            setrowData(datatmp.sort(sortM))
        }
        setchboxg(checked)
    }

    return (
        <>
            <Modal
                onClose={() => closeModal()}
                open={open}
                dimmer='blurring'
                closeIcon
                centered={width < 900 ? false : true}
                size='large'
                style={{ zoom: getZoomT(width) }}
            >

                <Modal.Content scrolling>

                    <div ref={componentRefPrint}>
                        <Grid unstackable style={{ marginTop: 5, marginBottom: 5 }}>
                            <Grid.Row>
                                <Grid.Column width={idt ? 12 : 16} textAlign="center" verticalAlign="middle">
                                    <Message>
                                        <Header as='h2' >
                                            <Header.Content>
                                                <Icon name="winner" />
                                                {t("WidokTurnieju.harmonogram_gier")}<br />
                                                {propsRoot.onlyView === 1 ? "" :
                                                    <Image style={{ width: 200 }} centered src={LogoTeamLigaGenerator} alt="logo" />
                                                }
                                            </Header.Content>
                                        </Header>
                                    </Message>
                                    {propsRoot.onlyView === 1 ? "" :
                                        <Message size='mini' info className='hiddenPrint'>
                                            Date oraz miejsce pojedynku, możesz ustawić dla każdego meczu.<br />
                                            Otwórz okno wpisywania wyniku a następnie uzupełnij <i>Informacje o pojedynku</i>
                                        </Message>
                                    }
                                </Grid.Column>
                                {idt ?
                                    <Grid.Column width={4} textAlign="right" >

                                        <QRCode size={120} floated="right" value={"https://turniej-generator.pl/tg/" + numberToCharTG(idt)} logoImage={tglh} ecLevel={"H"} />

                                    </Grid.Column>
                                    : ""}

                            </Grid.Row>

                        </Grid>
                        {propsRoot.onlyView === 1 ? "" : <>
                            <Icon className='cursorPointer tllink hiddenPrint' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTab} />
                            {saveCsvTab(rowData, "harmonogram.csv")}
                            <Checkbox toggle className='hiddenPrint' checked={chboxg} onChange={(e, d) => checkboxchangedata(d.checked)} label='Każda grupa na osobnej stronie' />
                            <style type="text/css" media="print">
                                {" @page { size: portrait; } "}
                            </style>
                        </>}
                        <Table style={{ marginTop: 5, fontSize: 14, lineHeight: "11px", marginBottom: 2 }} size='small' celled striped unstackable >
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell singleLine width={3} textAlign='center'>{t("WidokTurnieju.grupa").toUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell singleLine textAlign='center'>{t("WidokTurnieju.pojedynek").toUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell singleLine width={5} textAlign='center'>{t("StronaDedykowana.data").toUpperCase()}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rowData.length > 0 ?
                                    rowData.map((row, i) => <>
                                        {group_name !== row.group_name && chboxg ? sgroup_name(row.group_name) : ""}
                                        <Table.Row key={i}>
                                            <Table.Cell >{row.group_name}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match}</Table.Cell>
                                            <Table.Cell textAlign='center'>{row.match_time}</Table.Cell>
                                        </Table.Row>

                                    </>
                                    )
                                    : ""}

                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => closeModal()}>
                        {t("Zamknij").toUpperCase()}
                    </Button>

                </Modal.Actions>
            </Modal>
        </>

    )
}

export default BracketGenHarm;