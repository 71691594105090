import React, { Suspense, useEffect, useRef } from 'react';
const JoditEditor = React.lazy(() => import('jodit-react'));

function GetEditorTG(props) {

    useEffect(() => {

    }, [props.rte])// eslint-disable-line react-hooks/exhaustive-deps

    const editor = useRef(null);


    return (
        <div style={{ marginBottom: 25 }}>
            <Suspense fallback={<div>Trwa ładowanie modułu...</div>}>
                <JoditEditor
                    ref={editor}
                    value={props.rte}
                    onChange={newContent => props.setrte(newContent)}
                />
            </Suspense>
        </div>
    )
}

export default GetEditorTG;