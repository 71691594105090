import React, { useState, useEffect } from 'react';
import { Dropdown, Grid, Icon, Input, Label, Table } from 'semantic-ui-react';


import ShowImgAvatar from '../ShowImgAvatar';
import { getlinkplayerprofile } from '../../myfunctions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { t } from 'i18next';


function EditLeagueMatchDetailsExMatcheFiba({ match_details_ex, setmatch_details_ex, members, row, onlyView }) {
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


    const [team1Members, setteam1Members] = useState([])
    const [team2Members, setteam2Members] = useState([])


    useEffect(() => {

        if (match_details_ex) {
            createOptionsMembers()

        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [members, match_details_ex])// eslint-disable-line react-hooks/exhaustive-deps



    function getInputData(i, team_nr, value, name, type) {
        if (onlyView === 1) {
            if (type === "bool" && name === "mvp") {
                return value ? <Icon name="star" color={value ? "yellow" : "grey"} /> : ""
            } else {
                return value !== null ? value : "-"
            }

        } else if (type === "number") {
            return <Input style={{ width: 60,fontSize:12 }} type={"number"} value={value !== null ? value : ""} onChange={(e, d) => updateJson(i, team_nr, name, d.value)} />
        } else if (type === "bool" && name === "mvp") {
            return <Icon name="star" color={value ? "yellow" : "grey"} />
        } else {
            return value
        }
    }

    function getInputDataQ(i, q, value, name, type) {
        if (onlyView === 1) {
            return value !== null ? value : "-"

        } else if (type === "number") {
            return <Input style={{ width: 60,fontSize:12 }} type={"number"} value={value !== null ? value : ""} onChange={(e, d) => updateJsonQ(i, q, d.value, name, type)} />
        } else {
            return value
        }
    }

    function updateJsonQ(i, q, value, name, type) {

        try {
            
            let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))
            if(jsonTmp.dataJson[name].scoring_intervals[q]){
                // zabezpieczenie bo moze nie być q
                jsonTmp.dataJson[name].scoring_intervals[q][i] = value
            }else{
                jsonTmp.dataJson[name].scoring_intervals[q] = [value,value]
            }
           
            setmatch_details_ex({ ...jsonTmp })
        } catch (e) {

        }


    }

    function createOptionsMembers() {
        let team1 = []
        let team2 = []

        team1.push({
            key: 0,
            text: "Brak",
            value: JSON.stringify({ member_id: 0, member_name: "Brak" }),
        })
        team2.push({
            key: 0,
            text: "Brak",
            value: JSON.stringify({ member_id: 0, member_name: "Brak" }),
        })
        if (members && members.length > 0) {
            members.forEach((rowtmp) => {

                if (rowtmp.member_id === row.member1_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team1.push({
                                key: row1.member_id,
                                text: row1.member_name,
                                value: JSON.stringify({ member_id: row1.member_id, member_name: row1.member_name }),
                                imgtg: row1.member_img_file,
                                //   image: { avatar: true, src: row1.member_img_file ? row1.member_img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' },
                            })
                        })
                    }
                } else if (rowtmp.member_id === row.member2_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team2.push({
                                key: row1.member_id,
                                text: row1.member_name,
                                value: JSON.stringify({ member_id: row1.member_id, member_name: row1.member_name }),
                                imgtg: row1.member_img_file,
                                //     image: { avatar: true, src: row1.member_img_file ? row1.member_img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' },
                            })
                        })
                    }
                }
            })

            setteam1Members(team1)
            setteam2Members(team2)
        }
    }

    let arrLegend = [
        { id: "Nr", name: "Numer zawodnika" },
        { id: "Min", name: "Minuty" },
        { id: "C/W", name: "Celne Próby" },
        { id: "%", name: "Skuteczność w rzutach" },
        { id: "ZB A", name: "Zbiórki w ataku" },
        { id: "ZB O", name: "Zbiórka w obronie" },
        { id: "ZB", name: "Suma zbiórek" },
        { id: "A", name: "Asysty" },
        { id: "S", name: "Straty" },
        { id: "P", name: "Przechwyt" },
        { id: "BP", name: "Rzuty zablokowane" },
        { id: "BO", name: "Bloki otrzymane" },
        { id: "FP", name: "Faule osobiste" },
        { id: "FW", name: "Faule otrzymane" },
        { id: "FT", name: "Faule techniczny" },
        { id: "+/-", name: "Plus / Minus" },
        { id: "PKT", name: "Punkty" },
    ]


    function getMembersSelected(i, team_nr, row,) {
        if (onlyView === 1) {
            return row.member_id ? <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, row.member_name, 0, 14)}</figcaption></figure> : <><Icon color='red' name="warning circle" /> {row.member_name} </>
        } else {
            return <><Dropdown
                placeholder='Wybierz zawodnika'
                fluid
                selection
                value={JSON.stringify({ member_id: row.member_id, member_name: row.member_name })}
                onChange={(e, d) => updateJsonMembers(i, team_nr, d.value)}
                options={team_nr === 1 ? team1Members : team2Members}
            /> {row.member_id ? "" : <><Icon color='red' name="warning circle" /> {row.member_name} </>}</>
        }
    }

    function updateJsonMembers(i, team_nr, value) {

        let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))

        let member_id = JSON.parse(value).member_id
        let member_name = JSON.parse(value).member_name


        if (team_nr === 1) {
            jsonTmp.dataJson.team1_members[i].member_id = member_id === "" ? null : member_id
            jsonTmp.dataJson.team1_members[i].member_name = member_name === "" ? null : member_name
        } else if (team_nr === 2) {
            jsonTmp.dataJson.team2_members[i].member_id = member_id === "" ? null : member_id
            jsonTmp.dataJson.team2_members[i].member_name = member_name === "" ? null : member_name
        } else {

        }
        setmatch_details_ex({ ...jsonTmp })
    }

    function updateJson(i, team_nr, name, value) {

        let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))
        console.log(jsonTmp)

        if (team_nr === 1) {
            jsonTmp.dataJson.team1_members[i][name] = value === "" ? null : Number(value)
        } else if (team_nr === 2) {
            jsonTmp.dataJson.team2_members[i][name] = value === "" ? null : Number(value)
        } else {

        }
        setmatch_details_ex({ ...jsonTmp })
    }

    function getAllMembers(team_id, member_img_file, team_nr, match_details_ex) {
        try {

            let dataArr = team_nr === 1 ? [...match_details_ex.dataJson.team1_members] : [...match_details_ex.dataJson.team2_members]

            return <><div style={{ overflowY: "auto", width: "100%" }} >
                <Table size='small' className='fibaStats rowSelectedColor' celled striped compact structured unstackable >
                    <Table.Header >
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Numer zawodnika">NR</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' style={{ lineHeight: "12px" }} title="Zawodnik"><figure className='imgPlayer'><ShowImgAvatar urlpic={member_img_file} mtype={"USER"} /><figcaption> {t("WidokTurnieju.zawodnik")}</figcaption></figure></Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Minuty">Min</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center' >Punkty z gry</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center' >2 punkty</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center' >3 punkty</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center' >Za 1</Table.HeaderCell>
                            <Table.HeaderCell colSpan='3' textAlign='center' >Zbiórki</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Asysty">A</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Straty">S</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Przechwyt">P</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Rzuty zablokowane">BP</Table.HeaderCell>
                            <Table.HeaderCell colSpan='3' textAlign='center' >Faule</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Plus/Minus">+/-</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Punkty">MVP</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center' title="Punkty">PKT</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' title="Celne Próby">C/W</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Skuteczność w rzutach">%</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Celne Próby">C/W</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Skuteczność w rzutach">%</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Celne Próby">C/W</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Skuteczność w rzutach">%</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Celne Próby">C/W</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Skuteczność w rzutach">%</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Zbiórki w ataku">ZB A</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Zbiórki w obronie">ZB O</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Suma zbiórek">ZB</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Faule osobiste">FP</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Faule otrzymane">FW</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Faul techniczny">FT</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dataArr.map((row, i) =>
                            <Table.Row key={i} className={row.mvp ? 'tablePromotionRowMVP' : ''} >

                                <Table.Cell textAlign='center' style={{ position: "relative" }}><span style={{ fontSize: 10, position: "absolute", left: 2, top: 2 }}>{i + 1}.</span> {row.no}</Table.Cell>
                                <Table.Cell singleLine>
                                    {getMembersSelected(i, team_nr, row)}

                                </Table.Cell>
                                <Table.Cell textAlign='center'>{row.min}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.field_goals_ma}/{row.field_goals_total}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.field_goals_perc}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.two_points_ma}/{row.two_points_total}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.two_points_perc}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.three_points_ma}/{row.three_points_total}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.three_points_perc}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.free_throws_ma}/{row.free_throws_total}</Table.Cell>
                                <Table.Cell textAlign='center'>{row.free_throws_perc}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.rebounds_or, "rebounds_or", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.rebounds_dr, "rebounds_dr", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.rebounds_tot, "rebounds_tot", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.as, "as", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.to, "to", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.st, "st", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.bs, "bs", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.fouls_pf, "fouls_pf", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.fouls_fd, "fouls_fd", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.fouls_technical, "fouls_technical", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.plus_minus, "plus_minus", "number")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.mvp, "mvp", "bool")}</Table.Cell>
                                <Table.Cell textAlign='center'>{getInputData(i, team_nr, row.pts, "pts", "number")}</Table.Cell>
                            </Table.Row>
                        )}

                    </Table.Body>
                </Table>
            </div>


            </>

        } catch (e) {
            console.log(e)
            return ""
        }
    }

    function getDataTOString(value) {
        try {
            return value ? value.toString() : null
        } catch (e) {
            return "ERR"
        }
    }



    function getInfoQValue(team_scores, q, match_details_ex) {
        try {
            if (match_details_ex && match_details_ex.dataJson && match_details_ex.dataJson[team_scores]) {
                return <><span style={{ marginRight: 15 }}>{getInputDataQ(0, q, match_details_ex.dataJson[team_scores].scoring_intervals[q][0], team_scores, "number")}</span>
                    {getInputDataQ(1, q, match_details_ex.dataJson[team_scores].scoring_intervals[q][1], team_scores, "number")}</>
            }
            return "-"

        } catch (e) {
            return <><span style={{ marginRight: 15 }}>{getInputDataQ(0, q, -1, team_scores, "number")}</span>
                {getInputDataQ(1, q, -1, team_scores, "number")}</>
        }
    }

    function getInfoQ(member1_name, member2_name, match_details_ex) {
        try {
            return <><div style={{ marginBottom: 20, overflowY: "auto", width: "100%" }} >
                <Table size='small' celled compact unstackable definition  >
                    <Table.Header >
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell textAlign='center' >K1</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' >K2</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' >K3</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' >K4</Table.HeaderCell>

                        </Table.Row>

                    </Table.Header>
                    <Table.Body>

                        <Table.Row   >


                            <Table.Cell textAlign='center'>
                                {member1_name}  </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team1_scores", "q1", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team1_scores", "q2", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team1_scores", "q3", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team1_scores", "q4", match_details_ex)}
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row   >


                            <Table.Cell textAlign='center'>
                                {member2_name}  </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team2_scores", "q1", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team2_scores", "q2", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team2_scores", "q3", match_details_ex)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {getInfoQValue("team2_scores", "q4", match_details_ex)}
                            </Table.Cell>
                        </Table.Row>


                    </Table.Body>
                </Table>
            </div>


            </>
        } catch (e) {
            return ""
        }
    }


    function getAllStatsTeam(team1_id, member1_img_file, team2_id, member2_img_file, member1_name, member2_name, match_details_ex) {
        try {
            

            return <><div style={{ overflowY: "auto", width: "100%" }} >
                <Table size='small' className='fibaStats rowSelectedColor' celled striped compact unstackable >
                    <Table.Header >
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>
                                <figure className='imgPlayer2'><ShowImgAvatar msize={"massive"} urlpic={member1_img_file} mtype={"TEAM"} /><figcaption> {member1_name}</figcaption></figure>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' title="Punkty">STATYSTYKI</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>
                                <figure className='imgPlayer2'><ShowImgAvatar msize={"massive"} urlpic={member2_img_file} mtype={"TEAM"} /><figcaption> {member2_name}</figcaption></figure>
                            </Table.HeaderCell>
                        </Table.Row>

                    </Table.Header>
                    <Table.Body>
                        {match_details_ex.dataJson && match_details_ex.dataJson.team1_stats ? Object.entries(match_details_ex.dataJson.team1_stats).map(([key]) =>
                            getDataTOString(match_details_ex.dataJson.team1_stats[key]) && getDataTOString(match_details_ex.dataJson.team2_stats[key]) ?
                                <Table.Row key={key}  >
                                    <Table.Cell width={2} singleLine textAlign='center'>
                                        {getDataTOString(match_details_ex.dataJson.team1_stats[key])}
                                    </Table.Cell>
                                    <Table.Cell width={3} singleLine textAlign='center'>
                                        {t("stats." + key)}
                                    </Table.Cell>
                                    <Table.Cell width={2} singleLine textAlign='center'>
                                        {getDataTOString(match_details_ex.dataJson.team2_stats[key])}
                                    </Table.Cell>
                                </Table.Row> : ""
                        ) :""}

                    </Table.Body>
                </Table>
            </div>


            </>
        } catch (e) {
            console.log(e)
            return ""
        }
    }


    return (
        <div style={{ marginBottom: 20 }}>

            {match_details_ex && match_details_ex.fiba === true ?
                <>

                    <div>
                        {getInfoQ(row.member1_name, row.member2_name, match_details_ex)}
                    </div>
                    <div>
                        {getAllMembers(row.member1_id, row.member1_img_file, 1, match_details_ex)}
                    </div>
                    <div style={{ marginTop: 20 }}>
                        {getAllMembers(row.member2_id, row.member2_img_file, 2, match_details_ex)}
                    </div>
                    <div style={{ marginTop: 20 }}>

                        {getAllStatsTeam(row.member1_id, row.member1_img_file, row.member2_id, row.member2_img_file, row.member1_name, row.member2_name, match_details_ex)}
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Label>
                            LEGENDA:
                        </Label>
                        <Grid style={{ marginTop: 5 }}>
                            <Grid.Row >
                                {arrLegend.map((row, i) =>
                                    <Grid.Column width={1} key={i} style={{ fontSize: 10 }}>
                                        <b>{row.id}</b> - {row.name}

                                    </Grid.Column>
                                )}


                            </Grid.Row>
                        </Grid>
                    </div>
                </>
                : ""}

        </div>
    )
}

export default EditLeagueMatchDetailsExMatcheFiba;


