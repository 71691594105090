import React, { useEffect, useState } from 'react';
import { Grid, Icon, Table } from 'semantic-ui-react';

import { getChartsMIT, getImageTeam, getInicjaly, getMi } from '../../leagueFuntions';
import MyImageLazy from '../MyImageLazy';


function PtkMembersInMatcheOther({ remoteJsonFull, onlyView, teamName, teamAndMembers, tournament_json, isDarkTheme, width, setopenModalmatch_details_ex }) {

    const [arrMatches, setarrMatches] = useState([]);
    const [imgTeamPhoto, setimgTeamPhoto] = useState(null);


    useEffect(() => {

        startA()
        setimgTeamPhoto(getImageTeam(teamName, teamAndMembers))
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [teamAndMembers])// eslint-disable-line react-hooks/exhaustive-deps


    function getScoreSum(member_id, arr) {
        try {
            let ret = 0
            arr.forEach(element => {
                if (element.match_details_ex && element.match_details_ex.members && element.match_details_ex.version === 2) {
                    ret = ret + Number(getScore(member_id, element.match_details_ex))
                } else if (element.match_details_ex && element.match_details_ex.fiba) {
                    ret = ret + Number(getScore(member_id, element.match_details_ex))
                }
            });
            return ret
        } catch (e) {
            return 0
        }
    }

    function getScore(member_id, stats) {
        let ret = 0
        let ok = false
        if (stats.members && stats.version >= 2) {
            if (stats.members.points_scored) {
                if (stats.members.points_scored.team1_members) {
                    stats.members.points_scored.team1_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.value
                            ok = true
                        }
                    });
                }
                if (stats.members.points_scored.team2_members) {
                    stats.members.points_scored.team2_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.value
                            ok = true
                        }
                    });
                }

            } else if (stats.members.points_scored_goals) {
                if (stats.members.points_scored_goals.team1_members) {
                    stats.members.points_scored_goals.team1_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.value
                            ok = true
                        }
                    });
                }
                if (stats.members.points_scored_goals.team2_members) {
                    stats.members.points_scored_goals.team2_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.value
                            ok = true
                        }
                    });
                }

            }

        } else if (stats.fiba) {
            if (stats.dataJson.team1_members.length > 0 && stats.dataJson.team2_members.length > 0) {
                if (stats.dataJson.team1_members) {
                    stats.dataJson.team1_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.pts
                            ok = true
                        }
                    });
                }
                if (stats.dataJson.team2_members) {
                    stats.dataJson.team2_members.forEach(element => {
                        if (element.member_id === member_id) {
                            ret = element.pts
                            ok = true
                        }
                    });
                }

            }
        } else {
            ok = true
            ret = <Icon name="warning sign" color="grey" style={{ opacity: "0.2" }} title="Niezgodna wersja statystyk" />
        }


        return ok ? ret : ""
    }






    function showUsers(row) {
        try {
            let ret = false
            if (row && row.stats) {
                row.stats.forEach(element => {
                    if ((element.key === "points_scored" || element.key === "points_scored_goals" || element.key === "pts") && element.value > 0) {
                        ret = true
                        return
                    }
                });
            }
            return ret
        } catch (e) {
            return true
        }

    }



    function startA() {
        try {
            let arrMatchesTMP = []
            if (tournament_json) {
                tournament_json.forEach(element => {
                    element.matches.forEach(element1 => {
                        if (element1.member1_name === teamName && element1.member2_id !== null) {
                            arrMatchesTMP.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member2_name, scoreW: element1.member1_score, scoreL: element1.member2_score, score: element1.member1_score !== null ? element1.member1_score + ":" + element1.member2_score : "", status_m: getMi(element1.member1_score, element1.member2_score), match_details_ex: element1.match_details_ex, member_id: element1.member1_id })
                        } else if (element1.member2_name === teamName && element1.member1_id !== null) {
                            arrMatchesTMP.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member1_name, scoreW: element1.member2_score, scoreL: element1.member1_score, score: element1.member1_score !== null ? element1.member2_score + ":" + element1.member1_score : "", status_m: getMi(element1.member2_score, element1.member1_score), match_details_ex: element1.match_details_ex, member_id: element1.member2_id })
                        }
                    });
                });
            }
            setarrMatches(arrMatchesTMP)
        } catch (e) {

        }
    }



    function openStatsPrev(line) {
        try {
            let rowMatchRoot = {}
            remoteJsonFull.tournament_json.forEach(rowHarm => {
                rowHarm.matches.forEach(rowMatch => {

                    if (rowHarm.round_nr===line.round_nr && (rowMatch.member1_name === teamName || rowMatch.member2_name === teamName) && (rowMatch.member1_name === line.opponent || rowMatch.member2_name === line.opponent)) {
                        rowMatchRoot = rowMatch
                        
                    }
                });
            });
            
            
            setopenModalmatch_details_ex({ open: true, row: rowMatchRoot, onlyView: onlyView, st: remoteJsonFull, subtournament_id: remoteJsonFull.subtournament_id })
        } catch (e) {

        }
    }



    return (
        arrMatches.length > 0 ? <>

            <Grid unstackable>
                <Grid.Row verticalAlign="middle" >
                    {imgTeamPhoto ? <Grid.Column textAlign="center" width={width < 900 ? 16 : 5}> <MyImageLazy openView={true} maxHeight={400} src={imgTeamPhoto} />  </Grid.Column> : ""}
                    <Grid.Column width={width < 900 || imgTeamPhoto === null ? 16 : 11}>
                        {getChartsMIT(isDarkTheme, arrMatches, teamName, width, imgTeamPhoto)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>


            <div className='tablebs' style={{ marginTop: 50, marginBottom: 50, overflowX: "auto", width: "100%" }} >
                <Table style={{ fontSize: 13, lineHeight: "11px" }}
                    size='small'
                    inverted={isDarkTheme} celled structured striped compact unstackable >
                    <Table.Header >

                        <Table.Row>
                            <Table.HeaderCell width={1} textAlign='center'>NR</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' singleLine >PRZECIWNIK</Table.HeaderCell>
                            {teamAndMembers[teamName].map((line1, j) => showUsers(line1) ? <Table.HeaderCell width={1} textAlign='center'>{getInicjaly(line1.member_name)}<span style={{ fontSize: 11 }}>({j + 1})</span> </Table.HeaderCell> : "")}
                            <Table.HeaderCell width={1} textAlign='center' singleLine ></Table.HeaderCell>

                        </Table.Row>

                    </Table.Header>
                    <Table.Body>
                        {arrMatches.map((line, j) => <Table.Row>

                            <Table.Cell textAlign='center' width={1} singleLine>{line.league_revenge_round === 0 ? <>{line.round_nr}</> : <>{line.league_revenge_round}R</>}</Table.Cell>
                            <Table.Cell textAlign='center' singleLine>{line.opponent}</Table.Cell>
                            {teamAndMembers[teamName].map((line1, i) => showUsers(line1) ? <Table.Cell width={1} textAlign='center'>{getScore(line1.member_id, line.match_details_ex)} </Table.Cell> : "")}
                            <Table.Cell textAlign='right' width={1} singleLine><b>{line.score}</b> {line.status_m} {line.score ? <Icon name="balance scale" className='cursorPointer' color='blue' onClick={() => openStatsPrev(line)} /> : ""}</Table.Cell>
                        </Table.Row>)
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2} textAlign="right"><b>RAZEM:</b></Table.HeaderCell>
                            {teamAndMembers[teamName].map((line1, i) => showUsers(line1) ? <Table.HeaderCell width={1} textAlign='center'><b>{getScoreSum(line1.member_id, arrMatches)} </b></Table.HeaderCell> : "")}
                            <Table.HeaderCell ></Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>


                </Table>

            </div>
        </>
            : ""
    )
}

export default PtkMembersInMatcheOther;