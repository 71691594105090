import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Grid, Header, Icon, Image, Input, Label, Segment, Select, Table } from 'semantic-ui-react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useReactToPrint } from 'react-to-print';
import LogoTeamLigaGenerator from '../../../assets/tglogov6_small.png';
import BuyCoffeStart from './Other/BuyCoffeStart';

function GenGroupPDF() {
  const [genType, setgenType] = useState(0)
  const [genType1, setgenType1] = useState(null)
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [countPerson, setcountPerson] = useState(1)
  const [countGroup, setcountGroup] = useState(1)

  const options = [
    { key: '1', text: 'Każdy z każdym', value: 1 },
    { key: '2', text: 'Grupy', value: 2 },
  ]

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function genGroup() {
    setgenType(1)
  }


  useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [countPerson, countGroup])// eslint-disable-line react-hooks/exhaustive-deps



  function generatorTable(tmpCount) {
    let rows = [], i = 0, len = tmpCount;
    while (++i <= len) rows.push(i);
    return <>
      <div style={{ overflowX: "auto", overflowY: "auto", width: "100%" }} >
        <Table size='small'  celled striped compact unstackable >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
              <Table.HeaderCell style={{ fontSize: 12 }} textAlign='center' >UCZESTNIK</Table.HeaderCell>
              {rows.map((row, i) =>
                <Table.HeaderCell key={i} width={1} textAlign='center'>
                  {i + 1}
                </Table.HeaderCell>
              )}

              <Table.HeaderCell width={1} textAlign='center'>PUNKTY</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign='center'>MAŁE PUNKTY</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              rows.map((line4, i) =>
                <Table.Row key={i}>
                  <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                  <Table.Cell textAlign='center' style={{ fontSize: 12 }} >
                    <div style={{ width: 250 }}>
                    </div>
                  </Table.Cell>
                  {rows.map((line5, j) =>
                    <Table.Cell key={j} disabled={i === j ? true : false} textAlign='center'>
                      {i === j ? <Icon name="x" size='large' /> : ""}
                    </Table.Cell>
                  )
                  }
                  <Table.Cell textAlign='center' />
                  <Table.Cell textAlign='center' />

                </Table.Row>
              )
            }
          </Table.Body>


        </Table>

      </div>
    </>
  }


  function generatorGroup() {
    let rows1 = [], i = 0, len = countGroup;
    while (++i <= len) rows1.push(i);
    return rows1.map((row, i) =>
      <><Segment key={i} basic style={{margin:0,paddingTop:5}}>
        <Label color='blue' ribbon style={{marginBottom:5}}>
          GRUPA {i + 1}
        </Label>
        {generatorTable(countPerson)}
      </Segment>
        {countPerson > 15 ?
          i + 1 < rows1.length ? <div className='page-break' /> : ""
          :
          countPerson > 6 ?
            i + 1 < rows1.length ? (i + 1) % 2 === 0 ? <div className='page-break' /> : "" : ""
            :
            i + 1 < rows1.length ? (i + 1) % 3 === 0 ? <div className='page-break' /> : "" : ""

        }

      </>
    )
  }

  return (
    <div style={{ minHeight: 300 }}>
      <div ref={componentRef}>
        <style type="text/css" media="print">
          {genType1 === 1 ? " @page { size: landscape; } " : " @page { size: portrait; } "}
        </style>
        <Grid stackable style={{marginBottom:1}}>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h4' textAlign='center'>
                {genType === 0 ? <>
                  <Icon name='print' color="blue" />
                  <Header.Content>WYGENERUJ GRUPY - DRUK</Header.Content>
                </> : ""}
                {genType === 1 && genType1 === 1 ? <>
                  <Icon name='table' />
                  <Header.Content>SYSTEM: KAŻDY Z KAŻDYM</Header.Content>
                </> : ""}
                {genType === 1 && genType1 === 2 ? <>
                  <Icon name='table' />
                  <Header.Content>SYSTEM: GRUPOWY</Header.Content>
                </> : ""}
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" only='large screen'>
              <Image floated='right' size='small' centered src={LogoTeamLigaGenerator} alt="logo"/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {genType === 0 ?
          <div style={{marginTop:15}}>
            <Form>
              <Form.Group widths='equal'>
                <Form.Field
                  control={Select} selectOnBlur={false}
                  label='Typ turnieju'
                  options={options}
                  value={genType1}
                  onChange={(e, d) => setgenType1(d.value)}
                  placeholder="Proszę wybrać"
                />
              </Form.Group>
            </Form>

            {genType1 === 1 ?
              <Form onSubmit={genGroup}>
                <Form.Group widths='equal'>
                  <Form.Field
                    control={Input}
                    type='number'
                    min={2}
                    max={20}
                    value={countPerson}
                    required={true}
                    label='Ilość osób/drużyn'
                    onChange={(e, d) => setcountPerson(d.value)}
                    placeholder="Proszę wpisać"
                  />

                </Form.Group>
                <Button type='submit' fluid color='blue' size='mini'>GENERUJ</Button>
              </Form>
              : ""}
            {genType1 === 2 ?
              <Form onSubmit={genGroup}>
                <Form.Group widths='equal'>
                  <Form.Field
                    control={Input}
                    type='number'
                    min={2}
                    max={99}
                    value={countPerson}
                    required={true}
                    label='Ilość osób/drużyn'
                    onChange={(e, d) => setcountPerson(d.value)}
                    placeholder="Proszę wpisać"
                  />
                  <Form.Field
                    control={Input}
                    type='number'
                    min={2}
                    max={99}
                    value={countGroup}
                    required={true}
                    label='Ilość grup'
                    onChange={(e, d) => setcountGroup(d.value)}
                    placeholder="Proszę wpisać"
                  />

                </Form.Group>
                <Button type='submit' fluid color='blue' size='mini'>GENERUJ</Button>
              </Form>
              : ""}
          </div> : ""}
          
        {genType === 1 && genType1 === 1 ?
          //każdy z każdym
          <>
            {generatorTable(countPerson)}
          </> : ""}
        {genType === 1 && genType1 === 2 ?
          //Grupowy
          <>
            {generatorGroup()}
          </> : ""}
          {genType === 1 && (genType1 === 1 || genType1 === 2) ?
          //każdy z każdym
          <>
            <Form >
              <Button className='hiddenPrint' fluid size='mini' color='orange' id="gengroupprintclick" style={{ marginTop: 10 }} onClick={handlePrint}>DRUKUJ</Button>
              <BuyCoffeStart />
              <Button className='hiddenPrint' onClick={() => setgenType(0)} style={{ marginTop: 10 }} fluid size='mini' >WSTECZ</Button>
            </Form>
          </> : ""}
      </div>

    </div>
  );
}

export default GenGroupPDF;