import React, { useEffect, useContext, useState } from 'react';
import { Icon, Button, Modal, Grid, Header, Image, Form, Input, Segment, Message, Portal, Label, Table } from 'semantic-ui-react'

import LogoTeamLigaGenerator from '../../../../../assets/tglogov6_small.png';
import AuthContext from '../../../../../context/authContext';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { ConnectWS } from '../../../../../ConnectWS';
import { useToasts } from 'react-toast-notifications';

import { checkNominationNotConfirmedNotPersonID, getReportAmountForArbiter } from '../../../../../tools/SystemPanel/ToolsSystemPanel';
import { Calendar } from 'primereact/calendar';
import { addLocale, locale } from 'primereact/api';
import { TabPanel, TabView } from 'primereact/tabview';
import JudgesList from './JudgesList';
import axios from '../../../../../axios';
import { changeFormatDateTimeBS, getmatch_info } from '../../../../../myfunctions';
import ShowImgAvatar from '../../../../../tools/ShowImgAvatar';


function ShowJudgetsAmountReport(props) {
  addLocale('pl', {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
    dayNamesMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    today: 'Dzisiaj',
    clear: 'Wyczyść'
  });
  locale('pl');


  const { addToast } = useToasts();

  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

  const [openReportAmountForArbiter, setOpenReportAmountForArbiter] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0);
  const [openPortalModuleJudges, setopenPortalModuleJudges] = useState(null)
  const [selectedJudgesList, setselectedJudgesList] = useState(null)
  const [JudgesNotConfirmed, setJudgesNotConfirmed] = useState([])
  const [loadingData, setloadingData] = useState(false);


  const [form, setform] = useState({
    dateRange: [new Date(), new Date()],
    valueSum: 0,
    loading: false
  });// eslint-disable-line no-unused-vars

  const [Tournament, setTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [SubTournament, setSubTournament] = useState([]);// eslint-disable-line no-unused-vars

  const [formTournament, setFormTournament] = useState({// eslint-disable-line no-unused-vars
    tournamentid: 0,
    subtournament_id: 0,
    person_id: null,
    person_name: ""
  });

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {

    }
    getTournaments()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    if (selectedJudgesList && openPortalModuleJudges) {
      if (selectedJudgesList.length > 0) {
        setFormTournament({ ...formTournament, person_id: selectedJudgesList[0].person_id, person_name: selectedJudgesList[0].person_name })

      } else {
        setFormTournament({ ...formTournament, person_id: null, person_name: "" })
      }

    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [selectedJudgesList])// eslint-disable-line react-hooks/exhaustive-deps


  function getTournaments() {

    ConnectWS("/generator/getTournaments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if (data.tournament_free > 0) {
            dictemat.push({ key: i, text: data.name + " - " + data.start_date, value: data.tournament_id })
          }
        })
        //setRowDataTournament(response.data)
        setTournament(dictemat);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function ChangeTournamentBox(data) {
    setFormTournament({ ...formTournament, tournamentid: data.value })
    ConnectWS("/generator/getSubtournaments", authContext.token, { tournament_id: data.value }, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        dictemat.push({ key: -1, text: "WSZYSTKIE", value: 0 })
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data.subtournament_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setSubTournament(dictemat);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
    });
  }




  async function getJudgesNotConfirmed(tournament_id, subtournament_id) {
    try {
      let subTournamentJsonList = []
      setloadingData(true)
      const res = await axios.post("/tournament/getTournamentData", { tournament_id: Number(tournament_id) }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (res) {
        let arrTmp = []
        if (res.data.subtournaments) {
          for (let i = 0; i < res.data.subtournaments.length; i++) {
            if (res.data.subtournaments[i].subtournament_type === "LEAGUE" && (subtournament_id === 0 || (subtournament_id > 0 && res.data.subtournaments[i].subtournament_id === subtournament_id))) {
              const resSub = await axios.post("/tournament/getSubtournamentData", { tournament_id: Number(tournament_id), subtournament_id: Number(res.data.subtournaments[i].subtournament_id) }, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              if (resSub) {

                if (resSub.data) {
                  arrTmp.push({ ...resSub.data })
                }
              } else {

              }
            }


          }

        }

        subTournamentJsonList = [...arrTmp]
      } else {
        subTournamentJsonList = []
      }

      let arrMatches = []
      if (subTournamentJsonList && subTournamentJsonList.length > 0) {
        subTournamentJsonList.forEach(element => {
          if (element.tournament_json && element.tournament_json.length > 0) {
            element.tournament_json.forEach(element1 => {
              if (element1.matches && element1.matches.length > 0) {
                element1.matches.forEach(element2 => {
                  if (checkNominationNotConfirmedNotPersonID(element2)) {
                    arrMatches.push({ ...element2, round_nr: element1.round_nr, league_revenge_round: element1.league_revenge_round })
                  }
                });
              }
            });
          }
        });
      }
      setJudgesNotConfirmed(arrMatches)
      setloadingData(false)
    } catch (e) {
      setJudgesNotConfirmed([])
      setloadingData(false)
      console.log(e)
    }
  }


  return (
    <>

      <Button onClick={() => setOpenReportAmountForArbiter(true)} color='grey'>
        <Icon name="table" /> RAPORTY SĘDZIOWSKIE
      </Button>

      <Portal open={openPortalModuleJudges ? true : false}>
        <Segment
          style={{
            left: '40%',
            position: 'fixed',
            top: '10%',
            width: 500,
            zIndex: 2002,
          }}
        >
          <JudgesList addJudges={false} addATS={null} selectionType={1} rootHeight={300} setselectedJudgesList={setselectedJudgesList} />

          <Button
            content='ZAMKNIJ'
            size='mini'
            fluid
            style={{ marginTop: 5 }}
            onClick={() => setopenPortalModuleJudges(null)}
          />
        </Segment>
      </Portal>

      <Modal
        open={openReportAmountForArbiter ? true : false}
        centered={width < 900 ? false : true}
        size='large'
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='add' color="red" />
                  <Header.Content>RAPORTY SĘDZIOWSKIE</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content className='CardBlack'>

          <Modal.Description>
            {openReportAmountForArbiter ? <>
              <Segment>
                <Form>
                  <Form.Group inline widths='equal'>

                    <Form.Select
                      fluid
                      search
                      label={"Rozgrywki"}
                      options={Tournament}
                      value={formTournament.tournamentid}
                      onChange={(e, data) => ChangeTournamentBox(data)}
                      placeholder='Proszę wybrać...'
                    />


                    <Form.Select
                      fluid
                      search
                      label="Kategoria"
                      placeholder='Proszę wybrać...'
                      options={SubTournament}
                      value={formTournament.subtournament_id}
                      onChange={(e, data) => {
                        setFormTournament({ ...formTournament, subtournament_id: data.value })
                        //  setForm({ ...form, subtournament_id: data.value })
                        //ChangeSubTournamentBox(form.tournamentid, data.value)
                      }}

                    />

                  </Form.Group>
                </Form>
              </Segment>
              <TabView activeIndex={activeIndex} scrollable onTabChange={(e) => {
                setActiveIndex(e.index)
              }}>

                <TabPanel header={"WYNAGRODZENIE"} style={{ padding: 0, margin: 0 }} >
                  <div style={{ padding: 5 }}>
                    {formTournament.tournamentid === 0 ? <Message size='mini' warning>Proszę wybrać rozgrywki!</Message> : ""}
                    {formTournament.person_id === null ? <Message size='mini' warning>Proszę wybrać sędziego!</Message> : ""}
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8} >
                          <Input label="Sędzia" style={{ width: "100%" }} value={formTournament.person_name}></Input>

                        </Grid.Column>
                        <Grid.Column width={8} verticalAlign='middle'>
                          <Button fluid  onClick={() => setopenPortalModuleJudges(true)}>WYBIERZ SĘDZIEGO</Button>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={3} stretched>
                          <Label fluid style={{ marginBottom: 1, paddingTop: 12 }}>Zakres czasu od-do:</Label>
                        </Grid.Column>
                        <Grid.Column width={4} verticalAlign='middle'>
                          <Calendar value={form.dateRange} dateFormat="dd/mm/yy" onChange={(e) => setform({ ...form, dateRange: e.value })} selectionMode="range" readOnlyInput />
                        </Grid.Column>

                        <Grid.Column width={5} stretched>
                          <Button disabled={formTournament.tournamentid === 0 || form.loading ? true : false} color='blue' loading={form.loading} onClick={() => getReportAmountForArbiter(form, setform, addToast, formTournament.person_id, formTournament.tournamentid, formTournament.subtournament_id)} fluid>POBIERZ PODSUMOWANIE</Button>
                        </Grid.Column>
                        <Grid.Column width={4} verticalAlign='middle'>
                          <span><b>RAZEM:</b> {form.loading ? <Icon name="spinner" loading /> : form.valueSum} PLN</span>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </TabPanel>
                <TabPanel header={"NIEZAAKCEPTOWANE NOMINACJE"} style={{ padding: 0, margin: 0 }} >
                  <div style={{ padding: 5 }}>
                    {formTournament.tournamentid === 0 ? <Message size='mini' warning>Proszę wybrać rozgrywki!</Message> : ""}
                    <Button fluid disabled={formTournament.tournamentid === 0 || loadingData} color='blue' loading={loadingData} onClick={() => getJudgesNotConfirmed(formTournament.tournamentid, formTournament.subtournament_id)}>POBIERZ ZESTAWIENIE</Button>

                    <div style={{ padding: 5, overflowX: "auto", width: "100%" }} >
                      <Table size='small' style={{ marginTop: 5, lineHeight: "13px" }} celled striped compact unstackable >
                        <Table.Header>
                          <Table.Row>

                            <Table.HeaderCell textAlign='center'>
                              Runda
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>
                              Data
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>
                              Pojedynek
                            </Table.HeaderCell>

                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {JudgesNotConfirmed && JudgesNotConfirmed.length > 0 ?
                            JudgesNotConfirmed.map((line3, j) =>
                              line3.match_member1_id > 0 && line3.match_member2_id > 0 ?
                                <Table.Row key={j}>

                                  <Table.Cell textAlign='center' width={1} singleLine>
                                    KOLEJKA {line3.round_nr} {line3.league_revenge_round > 0 ? <>({line3.league_revenge_round} -  REWANŻOWA)</> : ""}
                                  </Table.Cell>
                                  <Table.Cell textAlign='center' width={1} singleLine>
                                    {line3.match_date ? changeFormatDateTimeBS(line3.match_date) : <><Icon title="POZYCJA NIE BEDZIE BRANA POD UWAGĘ DO ROZLICZENIA WYNAGRODZENIA!" color='red' name="warning sign" />Brak informacji</>}
                                  </Table.Cell>
                                  <Table.Cell textAlign='center' style={{ position: "relative" }} singleLine>
                                    <>
                                      <span className='imgPlayer3' >
                                        <ShowImgAvatar urlpic={line3.member1_img_file} mtype={"TEAM"} msize={'mini'} />
                                      </span> {line3.member1_name} <span style={{ marginLeft: 5, marginRight: 5 }}>vs</span> <span className='imgPlayer3' >
                                        <ShowImgAvatar urlpic={line3.member2_img_file} mtype={"TEAM"} msize={'mini'} />
                                      </span> {line3.member2_name}

                                    </>
                                    {line3.match_info && getmatch_info(line3.match_info, line3, 1, formTournament.subtournament_id).length > 0 ? <div style={{ fontSize: width < 1000 ? 11 : 11, textWrap: "balance", opacity: 0.7 }}>
                                      {getmatch_info(line3.match_info, line3, 1, formTournament.subtournament_id)}
                                    </div> : ""}
                                  </Table.Cell>


                                </Table.Row>
                                : ""
                            ) : <Table.Row>  <Table.Cell colSpan={3}><Message>Lista jest pusta</Message></Table.Cell></Table.Row>}

                        </Table.Body>
                      </Table>
                    </div>

                  </div>
                </TabPanel>
              </TabView>

            </> : ""}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => {
            setOpenReportAmountForArbiter(null)
          }}>
            ZAMKNIJ
          </Button>
        </Modal.Actions>
      </Modal>


    </>
  )
}

export default ShowJudgetsAmountReport;