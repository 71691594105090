import React from 'react';
import EditLeagueGlobalStatsTT from './EditLeagueGlobalStatsTT';
import EditLeagueGlobalStatsOther from './EditLeagueGlobalStatsOther';

function EditLeagueGlobalStats(props) {
    return (
        props.systemId===2 ?
       <EditLeagueGlobalStatsTT systemId={props.systemId} openModalmatch_details_ex={props.openModalmatch_details_ex} setopenModalmatch_details_ex={props.setopenModalmatch_details_ex} setOpen={props.setOpen} />
        :
        <EditLeagueGlobalStatsOther systemId={props.systemId} openModalmatch_details_ex={props.openModalmatch_details_ex} setopenModalmatch_details_ex={props.setopenModalmatch_details_ex} setOpen={props.setOpen} />
        
    )
}

export default EditLeagueGlobalStats;