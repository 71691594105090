import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Icon, Header, Step, Button, Segment, Select, Form, Message, Modal, Input, Grid, StepGroup } from 'semantic-ui-react'
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import BracketGen from '../../Bracket/BracketGen';
import BracketGroups from '../../Bracket/BracketGroups';
import BracketGroupsEWE from '../../Bracket/BracketGroupsEWE';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import BracketLeague from '../../Bracket/BracketLeague';
import axios from '../../../../axios';
import { confirmAlert } from 'react-confirm-alert'; // Import
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { getIconTournamentType } from '../../../../myfunctions';
import BracketGroupsGroup from '../../Bracket/BracketGroupsGroup';
import BracketIndividual from '../../Bracket/BracketIndividual';

const q = [
  { id: 1, qname: "Krok 1", qdisc: "Wybierz rodzaj turnieju", qtype: 1, status: "disabled", value: "" },
  // { id: 2, qname: "Krok 2", qdisc: "Rozpocznij generowanie turnieju", qtype: 1, status: "disabled", value: "" },
  { id: 2, qname: "Krok 2", qdisc: "Rozstaw uczestników", qtype: 1, status: "disabled", value: "" },
  { id: 3, qname: "Krok 3", qdisc: "Zatwierdź wygenerowany turniej", qtype: 1, status: "disabled", value: "" },
]
let qclone = q.slice(0);

function StepCreateTournament(props) {
  const [TournamentType, setTournamentType] = useState([]);
  const [TournamentTypeSelected, setTournamentTypeSelected] = useState({});
  const [TournamentTypeSelectName, setTournamentTypeSelectName] = useState(null);
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [revenge_round, setrevenge_round] = useState(null)
  const [empty_league_value, setempty_league_value] = useState(null)
  const [revenge_round_select, setrevenge_round_select] = useState([{ key: 'tak', text: 'Tak', value: true }, { key: 'nie', text: 'Nie', value: false }])// eslint-disable-line no-unused-vars
  const [empty_league_struct, setempty_league_struct] = useState([{ key: 'tak', text: 'Ręcznie', value: 1 }, { key: 'nie', text: 'Wylosować pary', value: 0 }, { key: '3', text: 'Ustawienia zaawansowane', value: 2 }])// eslint-disable-line no-unused-vars

  const [loading2, setloading2] = useState(false)

  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [step, setstep] = useState(0)
  const [ttype, setttype] = useState(1)
  const [groupCount, setgroupCount] = useState(0)
  const [questions, setquestions] = useState(qclone)// eslint-disable-line no-unused-vars
  const [MembersLastCyclePlacesStatus, setMembersLastCyclePlacesStatus] = useState(false)
  const [loading, setloading] = useState(false)
  const isDemo = useCheckUserIsDemo()

  const [loadingGlobal, setloadingGlobal] = useState(false)


  const [form, setForm] = useState({
    tournamentTypeGames: "",
    tournamentCreateUsers: false,
    tournamentAcc: false,
  });




  const [placeint, setplaceint] = useState([])


  const groups = [
    { key: '0', text: 'Proszę wybrać', value: 0 },
    { key: '2', text: '2', value: 2 },
    { key: '3', text: '3', value: 3 },
    { key: '4', text: '4', value: 4 },
    { key: '5', text: '5', value: 5 },
    { key: '6', text: '6', value: 6 },
    { key: '7', text: '7', value: 7 },
    { key: '8', text: '8', value: 8 },
    { key: '9', text: '9', value: 9 },
    { key: '10', text: '10', value: 10 },
    { key: '11', text: '11', value: 11 },
    { key: '12', text: '12', value: 12 },
    { key: '13', text: '13', value: 13 },
    { key: '14', text: '14', value: 14 },
    { key: '15', text: '15', value: 15 },
    { key: '16', text: '16', value: 16 },
    { key: '17', text: '17', value: 17 },
    { key: '18', text: '18', value: 18 },
    { key: '19', text: '19', value: 19 },
    { key: '20', text: '20', value: 20 },
    { key: '21', text: '21', value: 21 },
    { key: '22', text: '22', value: 22 },
    { key: '23', text: '23', value: 23 },
    { key: '24', text: '24', value: 24 },
    { key: '25', text: '25', value: 25 },
    { key: '26', text: '26', value: 26 },
    { key: '27', text: '27', value: 27 },
    { key: '28', text: '28', value: 28 },
    { key: '29', text: '29', value: 29 },
    { key: '30', text: '30', value: 30 },
  ]

  function getTournamentType() {
    setloading(true)
    setloadingGlobal(true)
    ConnectWS("/generator/getSubtournamentTypes", null, {}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0


        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.variant ? data.name + " Wariant:" + data.variant : data.name, value: JSON.stringify({ id: data.id, name: data.name, variant: data.variant, type: data.type }) })
        })
        setTournamentType(dictemat);

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloadingGlobal(false)
      setloading(false)
    });
  }



  useEffect(() => {
    setMembersLastCyclePlacesStatus(false)
    getTournamentType()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function nexStep(val) {


    if (step + val < 0) {

      return
    } else if (questions.length - 1 < step + val) {

      return
    }

    if (step + val === 0) {
      setgroupCount(0)
      setrevenge_round(null)
    }
    setMembersLastCyclePlacesStatus(false)
    setstep(step + val)

  }




  function createSubtournamentStruct(subtournament_id, value, variant, ttype) {
    setloadingGlobal(true)
    setloading(true)
    ConnectWS("/generator/createSubtournamentStruct", authContext.token, { subtournament_id: subtournament_id, subtournament_type: value, subtournament_variant: variant, groupCount: groupCount, swiss_rounds_count: 4, revenge_round: revenge_round, empty_league_struct: empty_league_value }, function (response, logout) {

      if (logout === 1) {
        setgroupCount(0)

      } else if (response && response.status && Number(response.status) === 200) {
        if(value==="INDIVIDUAL"){
          questions[0] = { ...questions[0], status: "completed" }
          questions[1] = { ...questions[1], status: "completed" }
          questions[2] = { ...questions[2], status: "disabled" }
          addToast(response.data.message, { appearance: 'success' });
          setForm({ ...form, tournamentCreate: true, tournamentTypeGames: value, tournamentCreateUsers: true, tournamentAcc: false, })
          authContext.changeRefreshView(authContext.refreshView + 1)
          nexStep(+2)
        }else{
          questions[0] = { ...questions[0], status: "completed" }
          questions[1] = { ...questions[1], status: "disabled" }
          questions[2] = { ...questions[2], status: "disabled" }
          addToast(response.data.message, { appearance: 'success' });
          setForm({ ...form, tournamentCreate: true, tournamentTypeGames: value, tournamentCreateUsers: false, tournamentAcc: false })
          authContext.changeRefreshView(authContext.refreshView + 1)
          nexStep(+1)
        }
      
      } else {
        setgroupCount(0)
        setForm({ ...form, tournamentCreate: false, tournamentTypeGames: value })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloadingGlobal(false)
      setloading(false)

    });





  }

  function changeTournamentType(data) {


    if (props.members === 0) {
      addToast("Brak dodanych uczestników!", { appearance: 'error' });
      return
    } else {
      if (JSON.parse(data.value).id === "CUP_SIMPLE") {
        if (props.members < 9) {
          setplaceint([{ key: '2', text: '2 - tylko pojedynek finałowy', value: 2 }, { key: '4', text: '4 - pojedynek o 3 miejsce', value: 4 }, { key: '8', text: '8', value: 8 }])
        } else if (props.members < 17) {
          setplaceint([{ key: '2', text: '2 - tylko pojedynek finałowy', value: 2 }, { key: '4', text: '4 - pojedynek o 3 miejsce', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }])
        } else if (props.members < 33) {
          setplaceint([{ key: '2', text: '2 - tylko pojedynek finałowy', value: 2 }, { key: '4', text: '4 - pojedynek o 3 miejsce', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }])
        } else {
          setplaceint([{ key: '2', text: '2 - tylko pojedynek finałowy', value: 2 }, { key: '4', text: '4 - pojedynek o 3 miejsce', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }, { key: '64', text: '64', value: 64 }])
        }
      } else {
        if (props.members < 9) {
          setplaceint([{ key: '4', text: '4', value: 4 }, { key: '8', text: '8', value: 8 }])
        } else if (props.members < 17) {
          setplaceint([{ key: '4', text: '4', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }])
        } else if (props.members < 33) {
          setplaceint([{ key: '4', text: '4', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }])
        } else {
          setplaceint([{ key: '4', text: '4', value: 4 }, { key: '8', text: '8', value: 8 }, { key: '16', text: '16', value: 16 }, { key: '32', text: '32', value: 32 }, { key: '64', text: '64', value: 64 }])
        }
      }

    }




    if (JSON.parse(data.value).type === 1 && groupCount === 0 && JSON.parse(data.value).id !== "CUP_N_PLACE") {
      //CUP_N_ELIMINATION
      setTournamentTypeSelected(data)
      setOpen2(true)
      return
    }

    if ((JSON.parse(data.value).type === 2 || JSON.parse(data.value).type === 7) && groupCount === 0) {
      //grupowo-pucharowy lub grupowo-grupowy
      setTournamentTypeSelected(data)
      setOpen(true)
      return
    }




    if (JSON.parse(data.value).type === 3 && groupCount === 0) {
      //kazdy z kazdym
      setTournamentTypeSelected(data)
      setgroupCount(1)
    }

    if (JSON.parse(data.value).type === 5 && revenge_round === null) {
      //ligowy
      if (authContext.user.subscription_info.subscription_active || isDemo) {
        setTournamentTypeSelected(data)
        setOpen3(true)
      } else {
        addToast("System ligowy tylko w pakiecie diamentowym", { appearance: 'warning' });
      }

      return
    }

    if (data.value !== 0) {
      setTournamentTypeSelectName(JSON.parse(data.value).variant ? JSON.parse(data.value).name + " Wariant:" + JSON.parse(data.value).variant : JSON.parse(data.value).name)
      createSubtournamentStruct(props.subtournament_id, JSON.parse(data.value).id, JSON.parse(data.value).variant, JSON.parse(data.value).type)
      setttype(JSON.parse(data.value).type)
    }



  }

  function accRostawianieUczestników(subtournament_id, ttype) {

    if (ttype === 5 || ttype === 8) {
      // dla ligowego pomnijamy losowanie od razu zatwierdzamy 
      questions[1] = { ...questions[1], status: "completed" }
      addToast("Proszę teraz zatwierdzić wygenerowany turniej/ligę", { appearance: 'success' });
      setForm({ ...form, tournamentCreateUsers: true })
      authContext.changeRefreshView(authContext.refreshView + 1)
      nexStep(+1)
      return
    }

    setloadingGlobal(true)
    setloading(true)
    let m = ttype === 1 ? "/generator/drawMissingMatchPairs" : "/generator/drawMissingGroupsMembers"
    ConnectWS(m, authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        questions[1] = { ...questions[1], status: "completed" }
        addToast(response.data.message, { appearance: 'success' });
        setForm({ ...form, tournamentCreateUsers: true })
        authContext.changeRefreshView(authContext.refreshView + 1)
        nexStep(+1)
      } else {
        setForm({ ...form, tournamentCreateUsers: true })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloading(false)
      setloadingGlobal(false)
    });

  }


  function accTournament(subtournament_id) {
    if (form.tournamentCreateUsers === false) {
      addToast("Proszę zatwierdzić rozstawienie uczestników w Korku 2", { appearance: 'warning' });
      return
    }
    setloadingGlobal(true)
    setloading(true)
    ConnectWS("/generator/confirmSubtournamentStruct ", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        questions[2] = { ...questions[2], status: "completed" }
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons

      }
      setloadingGlobal(false)
      setloading(false)

    });
  }


  async function accMembersLastCyclePlaces(MembersLastCyclePlaces, ttype) {
    function loopJsonB(jsonTMP, idBArr) {
      Object.entries(jsonTMP).forEach(
        ([key1, value1]) => {
          if (key1 === "sides") {
            if (value1.home.seed && value1.home.seed.sourceGame) {


              if (!value1.home.seed.sourceGame.sides.home.seed) {
                idBArr.push({ id: value1.home.seed.sourceGame.id, m1: Number(value1.home.seed.sourceGame.sides.home.team.name.replaceAll("UCZESTNIK ", "")), m2: Number(value1.home.seed.sourceGame.sides.visitor.team.name.replaceAll("UCZESTNIK ", "")) })
              }


              loopJsonB(value1.home.seed.sourceGame, idBArr)

            }
            if (value1.visitor.seed && value1.visitor.seed.sourceGame) {


              if (!value1.visitor.seed.sourceGame.sides.visitor.seed) {
                idBArr.push({ id: value1.visitor.seed.sourceGame.id, m1: Number(value1.visitor.seed.sourceGame.sides.home.team.name.replaceAll("UCZESTNIK ", "")), m2: Number(value1.visitor.seed.sourceGame.sides.visitor.team.name.replaceAll("UCZESTNIK ", "")) })

              }

              loopJsonB(value1.visitor.seed.sourceGame, idBArr)

            }
          }
        }
      );
    }


    if (MembersLastCyclePlacesStatus) {
      addToast("Uczestnicy zostali rozstawieni już wcześniej", { appearance: 'info' });
      return
    } else {
      confirmAlert({
        customUI: ({ onClose }, countMember) => {
          let countMemberTmp = 10000
          function ggg(val) {
            console.log(val)
            countMemberTmp = val
          }
          return (
            <Segment className='CardBlack'>
              <h3>Ilu uczestników chcesz rozstawić?</h3>

              Jeżeli nie wpiszesz wartości, zostaną rozstawieni wszyscy uczestnicy, którzy posiadają miejsca z cyklu.
              <Input style={{ marginTop: 5 }} size='mini' type='number' onChange={(e, d) => ggg(d.value)} fluid icon='users' iconPosition='left' placeholder='Ilość rozstawionych uczestników' />
              <br />
              <Button.Group fluid>
                <Button floated='left' onClick={onClose}>WSTECZ</Button>
                <Button floated='right' color='green'
                  onClick={() => {
                    accMembersLastCyclePlacesACC(countMemberTmp);
                    onClose();
                  }}
                >
                  ZATWIERDZ
                </Button>
              </Button.Group>
            </Segment>
          );
        }
      });
    }



    async function accMembersLastCyclePlacesACC(countMemberTmp) {


      try {
        let countMemerOK = 0
        setloading2(true)
        let subtournament_id = props.subtournament_id


        let tokendata1 = "Bearer " + authContext.token;
        axios.defaults.headers.common['Authorization'] = tokendata1;
        const res = await axios.post("/generator/getUnassignedSubtournamentMembers", { subtournament_id: subtournament_id }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (res) {



          // drabinka
          if (ttype === 1) {
            let idBArr = []
            loopJsonB(props.tournamentJson[0], idBArr)

            for (let step = 0; step < idBArr.length; step++) {
              let sId = idBArr[step].id
              let homeUserId = null
              let visitorUserId = null
              try {
                let tournament_member_id = null
                if (Number(idBArr[step].m1) <= Number(countMemberTmp) && Number(MembersLastCyclePlaces[idBArr[step].m1 - 1].cycle_order_nr) > 0) {
                  tournament_member_id = MembersLastCyclePlaces[idBArr[step].m1 - 1].tournament_member_id
                }
                if (tournament_member_id && typeof tournament_member_id !== "undefined") {
                  homeUserId = tournament_member_id
                }
              } catch (e) {

              }
              try {
                let tournament_member_id2 = null
                if (Number(idBArr[step].m2) <= Number(countMemberTmp) && Number(MembersLastCyclePlaces[idBArr[step].m2 - 1].cycle_order_nr) > 0) {
                  tournament_member_id2 = MembersLastCyclePlaces[idBArr[step].m2 - 1].tournament_member_id
                }
                if (tournament_member_id2 && typeof tournament_member_id2 !== "undefined") {
                  visitorUserId = tournament_member_id2
                }

              } catch (e) {

              }

              //  console.log(sId, homeUserId, visitorUserId)
              if (homeUserId === null && visitorUserId === null) {
                // nic nie robimy
              } else {
                axios.defaults.headers.common['Authorization'] = tokendata1;
                await axios.post("/generator/setMatchMembers", { sId: sId, subtournament_id: subtournament_id, homeUserId: homeUserId, visitorUserId: visitorUserId }, {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                });
                countMemerOK++
              }
            }



            // grupowo-pucharowy
          } else if (ttype === 2) {

            let tournamentGroups = props.tournamentGroups && props.tournamentGroups.length > 0 ? props.tournamentGroups : []

            //tworzymy sobie tablice z membersami do rozstawienia
            let tournamentGroupsMembers = []
            let countGroup = tournamentGroups.length
            let maxMemberGroup = 0

            for (let step = 0; step < tournamentGroups.length; step++) {
              if (maxMemberGroup < tournamentGroups[step].members.length) {
                maxMemberGroup = tournamentGroups[step].members.length
              }
            }

            for (let step = 0; step < maxMemberGroup; step++) {
              let tmparr = []
              for (let step1 = 0; step1 < countGroup; step1++) {

                if (tournamentGroups[step1] && tournamentGroups[step1].members[step] && tournamentGroups[step1].members[step].subtournament_group_member_id) {
                  tmparr.push(tournamentGroups[step1].members[step].subtournament_group_member_id)
                }
              }

              // sprwadzamy czy parzyste miejsce jeszcze tak to nic nie robimy w przeciwenstwie nalezy reversa na tablic robimy weza
              if (step % 2 === 0) {
              } else {
                tmparr.reverse()
              }
              tournamentGroupsMembers.push(...tmparr)
            }

            //petla po miejscach
            for (let step = 0; step < MembersLastCyclePlaces.length; step++) {
              if (Number(MembersLastCyclePlaces[step].cycle_order_nr) > 0 && step <= Number(countMemberTmp)) {
                let subtournament_group_member_id = tournamentGroupsMembers[step]
                let tournament_member_id = MembersLastCyclePlaces[step].tournament_member_id
                axios.defaults.headers.common['Authorization'] = tokendata1;
                await axios.post("/generator/addMemberToGroup", { subtournament_group_member_id: subtournament_group_member_id, member_id: tournament_member_id }, {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                });
                countMemerOK++
              }

            }


            ///generator/addMemberToGroup {"subtournament_group_member_id":15275,"member_id":20449}

          }
        }

        else {
          setloading2(false)
        }

        authContext.changeRefreshView(authContext.refreshView + 1)
        if (countMemerOK > 0) {
          addToast("Uczestnicy zostali rozstawieni na podstawie miejsc z cyklu", { appearance: 'success' });
          setMembersLastCyclePlacesStatus(true)
        } else {
          addToast("Brak uczestników do rozstawienia na podstawie miejsc z cyklu!", { appearance: 'info' });
        }


        setloading2(false)

      } catch (e) {
        console.log(e)
        addToast("Nie mogę utworzyć par automatycznie, proszę rozstawić uczestników ręcznie lub przez losowanie", { appearance: 'info' });
        setloading2(false)
      }
    }

  }

  return (
    <>
      <Segment raised loading={loadingGlobal}>

        <div style={{ marginBottom: 10, overflowX: "auto", width: width < 700 ? (width - 130) : "100%" }} >
          <StepGroup ordered fluid >
            {questions.map(function (question, index) {
              return <Step key={index} completed={question.status === 'completed' && step !== index ? true : false} active={step === index ? true : false} disabled={question.status === 'disabled' && step !== index ? true : false}>

                <Step.Content>
                  <Step.Title className='stepStyle'>{question.qname}</Step.Title>
                  <Step.Description>{question.qdisc ? question.qdisc : ''}</Step.Description>
                </Step.Content>
              </Step>
            })}
          </StepGroup>
        </div>


        {TournamentTypeSelectName != null ?
          <Header as='h3' color='orange' icon textAlign='center' style={{ marginTop: 50, marginBottom: 50 }}>
            <Icon name='winner' circular className='coffeAnime' />
            <Header.Subheader>WYBRANY TURNIEJ</Header.Subheader>
            <Header.Content>{TournamentTypeSelectName}</Header.Content>
          </Header>
          : ""}

        <Button.Group widths='3' size='mini'>
          {step === 0 ? "" :
            <Button onClick={() => nexStep(-1)} size='mini' labelPosition='left' icon='arrow circle left' content='Poprzednie' />
          }
          {TournamentTypeSelectName === null || step === 2 ? "" : <>  <Button.Or text="" />
            <Button onClick={() => nexStep(+1)} size='mini' labelPosition='right' icon='arrow circle right' content='Następne' />
          </>}
        </Button.Group>
        <Header>
          {questions[step].qname} - {questions[step].qdisc}
        </Header>
        {step === 0 ?
          <div style={{ marginBottom: 50 }}>

            {TournamentType && TournamentType.length > 0 ?
              TournamentType.map((row, i) =>

                <Grid >
                  <Grid.Row style={{ margin: 0, padding: 5 }}>
                    <Grid.Column mobile={9} tablet={12} computer={width > 1500 ? 14 : 12}>
                      <Button size='small' fluid onClick={() => changeTournamentType(row)}>{getIconTournamentType(row.value)}{row.text}</Button>
                    </Grid.Column>
                    <Grid.Column mobile={7} tablet={4} computer={width > 1500 ? 2 : 4}>

                      <Button icon labelPosition='left' size='small' fluid color='grey' onClick={() => row.value ? authContext.changeModalInfo({ nameModal: JSON.parse(row.value).id, name: JSON.parse(row.value).name, value: "" }) : ""}> <Icon name='sticky note' /> {width > 800 ? "ZOBACZ OPIS" : "OPIS"}</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

              ) : ""}

          </div>
          : ""
        }

        {step === 1 ?
          <>
            {empty_league_value === 2 && ttype === 5 ? "" : // zaawansowany tryb ligowy
              <>
                <Message size='tiny' visible>
                  <p><Icon name="info circle" />Aby rozstawić uczestników ręcznie, kliknij w numer uczestnika.<br />
                    <Icon name="info circle" />Uczestnicy którzy nie będą rozstawienie zostaną im wylosowane wolne miejsca.<br />
                    <Icon name="info circle" />Następnie kliknij poniższy przycisk "ZATWIERDZAM ROZSTAWIENIE UCZESTNIKÓW"</p>
                </Message>

                <Button style={{ marginBottom: 10 }} color="brown" loading={loading} disabled={loading} fluid onClick={() => accRostawianieUczestników(props.subtournament_id, ttype)}>ZATWIERDZAM ROZSTAWIENIE UCZESTNIKÓW</Button>
              </>
            }
            {props.MembersLastCyclePlaces !== null && props.MembersLastCyclePlaces && props.MembersLastCyclePlaces.length > 0 && (ttype === 1 || ttype === 2) ?
              <Button icon labelPosition='left' style={{ marginBottom: 10 }} size="mini" loading={loading2} disabled={loading2} color='blue' fluid onClick={() => accMembersLastCyclePlaces(props.MembersLastCyclePlaces, ttype)}>
                <Icon name='tasks' />
                ROZSTAW UCZESTNIKÓW AUTOMATYCZNIE WG MIEJSCA Z CYKLU
              </Button>
              : ""}
            {ttype === 1 ?
              <BracketGen ttname={""} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} third_place_encounter={props.third_place_encounter} ttype={props.ttype} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={0} createTournament={true} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 2 ?
              <BracketGroups struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={0} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 7 ?
              <BracketGroupsGroup finishGroup={false} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={0} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 3 ?
              <BracketGroupsEWE struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={0} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 5 ?
              <BracketLeague advancedMode={empty_league_value === 2 ? true : false} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={0} subtournamentId={props.subtournament_id} />
              : ""}
            {ttype === 8 ?
              <BracketIndividual subTournamentJson={props.subTournamentJson} advancedMode={empty_league_value === 2 ? true : false} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={0} subtournamentId={props.subtournament_id} />
              : ""}
          </>
          : ""
        }
    

        {step === 2 ?
          <>

            <Button style={{ marginBottom: 10 }} color="brown" loading={loading} disabled={loading} fluid onClick={() => accTournament(props.subtournament_id)}>{ttype === 8 ? "ZATWIERDŹ KONFIGURACJĘ" :"ZATWIERDŹ WYGENEROWANY TURNIEJ - ROZPOCZNIJ GRY"}</Button>
            {ttype === 1 ?
              <BracketGen ttname={""} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={1} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 2 ?
              <BracketGroups struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={1} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 3 ?
              <BracketGroupsEWE struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={1} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 7 ?
              <BracketGroupsGroup finishGroup={false} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} matches={props.matches ? props.matches : []} remoteGroups={props.tournamentGroups ? props.tournamentGroups : {}} onlyView={0} tournamentId={props.subtournament_id} />
              : ""}
            {ttype === 5 ?
              <BracketLeague struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={1} subtournamentId={props.subtournament_id} />
              : ""}
            {ttype === 8 ?
              <BracketIndividual subTournamentJson={props.subTournamentJson} struct_confirmed={props.struct_confirmed ? props.struct_confirmed : 0} remoteJson={props.tournamentJson ? props.tournamentJson : {}} onlyView={0} subtournamentId={props.subtournament_id} />
              : ""}
          </>
          : ""
        }

      </Segment>

      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='small'
      >
        <Header icon>
          <Icon name='table' />
          Ilość grup w turnieju
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={Select} selectOnBlur={false}
              options={groups}
              placeholder='Ilość grup'
              value={groupCount}
              onChange={(event, data) =>
                // console.log(data.value)
                data.value > 0 ? setgroupCount(data.value) : ""
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setgroupCount(0)
            setOpen(false)
          }}>
            <Icon name='remove' /> Rezygnuje
          </Button>
          <Button color='green' loading={loading} disabled={loading} inverted onClick={() => {
            setOpen(false)
            changeTournamentType(TournamentTypeSelected)
          }}>
            <Icon name='checkmark' /> Potwierdzam
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        basic
        onClose={() => setOpen2(false)}
        onOpen={() => setOpen2(true)}
        open={open2}
        size='small'
      >
        <Header icon>
          <Icon name='table' />
          Do którego miejsca gramy drabinkę? pozostałe miejsca to przedział.
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={Select} selectOnBlur={false}
              options={placeint}
              placeholder='Do którego miejsca gramy drabinkę?'
              onChange={(event, data) => {
                setgroupCount(data.value)
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setgroupCount(0)
            setOpen2(false)
          }}>
            <Icon name='remove' /> Rezygnuje
          </Button>
          <Button color='green' loading={loading} disabled={loading} inverted onClick={() => {
            setOpen2(false)
            changeTournamentType(TournamentTypeSelected)
          }}>
            <Icon name='checkmark' /> Potwierdzam
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        basic
        onClose={() => setOpen3(false)}
        onOpen={() => setOpen3(true)}
        open={open3}
        size='small'
      >
        <Header icon>
          <Icon name='table' />
          Czy gramy rundę rewanżowa oraz czy wylosować uczestników?
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={Select} selectOnBlur={false}
              options={revenge_round_select}
              placeholder='Czy gramy rundę rewanżowa?'
              value={revenge_round}
              onChange={(event, data) => {
                setrevenge_round(data.value)
              }}
            />
            <Form.Field
              control={Select} selectOnBlur={false}
              options={empty_league_struct}
              placeholder='Czy wygenerować automatycznie pary meczowe?'
              value={empty_league_value}
              onChange={(event, data) => {
                setempty_league_value(data.value)
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => {
            setgroupCount(0)
            setOpen3(false)
          }}>
            <Icon name='remove' /> Rezygnuje
          </Button>
          <Button color='green' loading={loading} disabled={loading || revenge_round === null || empty_league_value === null} inverted onClick={() => {
            setOpen3(false)
            changeTournamentType(TournamentTypeSelected)
          }}>
            <Icon name='checkmark' /> Potwierdzam
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default StepCreateTournament;