import React, { useEffect, useContext } from 'react';
import { Container, Header, Message } from 'semantic-ui-react'
import { SiCoffeescript } from 'react-icons/si';
import AuthContext from '../../../../context/authContext';
import { checkiscompanypay } from '../../../../myfunctions';



function BuyCoffeStart({ theme }) {
  const authContext = useContext(AuthContext);

  const tabColor = ['brown']
  useEffect(() => {

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps




  return (
    checkiscompanypay()===1 ? "" :
    theme === "dark" ? <Container textAlign='center' style={{ marginTop: 20 }}><Header as='h5' className='cursorPointer fontColorPlayer2' icon onClick={() => authContext.changeModalInfo({ nameModal: "buyCoffe", name: "Czas na kawę...", value: "" })}>
      <span style={{ fontSize: 40, margin: 0, padding: 0, }} ><SiCoffeescript className='coffeAnime' />
      </span>
      <br />
      Czy możemy liczyć na Twoje wsparcie w postaci kawy?
      <Header.Subheader className='fontColorPlayer2'>
        Podaruj nam filiżankę kawy, a wspomożesz naszą pasję i twórczość.
        <br />
        <span style={{ fontSize: 14 }}><b>To mały krok, który naprawdę pomaga naszej kreatywności rozkwitnąć!.</b><br />
          <b>.:KLIK:.</b>
        </span>
      </Header.Subheader>

    </Header>
    </Container> :
      <Message className='hiddenPrint fontColorPlayer2' floating color={tabColor[Math.floor(Math.random() * tabColor.length)]} id="seebuycoffeclick" onClick={() => authContext.changeModalInfo({ nameModal: "buyCoffe", name: "Czas na kawę...", value: "" })}>
        <Header as='h5' className='cursorPointer' icon >
          <span style={{ fontSize: 40, margin: 0, padding: 0, }} ><SiCoffeescript className='coffeAnime' />
          </span>
          <br />
          Czy możemy liczyć na Twoje wsparcie w postaci kawy?
          <Header.Subheader>
            Podaruj nam filiżankę kawy, a wspomożesz naszą pasję i twórczość.
            <br />
            <span style={{ fontSize: 14 }}><b>To mały krok, który naprawdę pomaga naszej kreatywności rozkwitnąć!.</b><br />
            <b>.:KLIK:.</b></span>
          </Header.Subheader>
        </Header>

      </Message>


  )
}

export default BuyCoffeStart;