import React, { useEffect, useContext, useState } from 'react';
import { Button, Container, Grid, Header, Icon, Image, Modal, Step, StepContent, StepDescription, StepGroup, StepTitle } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import LogoTeamLigaGenerator from '../../../../assets/tglogov6_small.png';
import ChangeMyData from '../../Dashboard/MyAccount/ChangeMyData';
import { bruttoToNetto, checkPayIsOff, loadingShow } from '../../../../myfunctions';

function BuyProductCompany(props) {

  /*
buyProductType 
1 - tokeny
2 - suby
3 - wsparcie techniczne
4 - podniesienie membersow z darmowego
5 - Usługa do opłcenia
  */

  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const [open, setopen] = useState(false);
  const [step, setstep] = useState(0)
  const [ad, setad] = useState(null);

  const [paymentLoading, setpaymentLoading] = useState(false);


  function preGenerateData(stype) {
    /*
    confirmAlert({
      title: 'Czy chcesz fakturę?',
      message: 'Faktura zostanie wystawiona na dane wprowadzone w systemie',
      buttons: [
        {
          label: 'Tak',
          onClick: () => generateData(stype, true)
        },
        {
          label: 'Nie',
          onClick: () => generateData(stype, false)
        }
      ]
    });
     */
    setopen(true)
  }


  function getAccountData() {

    ConnectWS("/login/getAccountData", authContext.token, {}, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

      } else if (response && response.status && Number(response.status) === 200) {
        setad(response.data)
        
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }

    });
  }

  async function generateData(stype, fvat) {

    //sprwadzanie czy user ma wprowadzone dane adresowe dla faktury

    setpaymentLoading(true)

    let amount = props.price;
    let isToken = props.isToken;
    let buyProductType = props.buyProductType;
    let tournament_id = props.tournament_id ? props.tournament_id : 0

    ConnectWS("/payment/createTransactionP24", authContext.token, { amount: amount, isToken: isToken, fvat: fvat, buyProductType: buyProductType, tournament_id: tournament_id, payable_service_id: props.payable_service_id }, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

      } else if (response && response.status && Number(response.status) === 200) {
        window.location.href = response.data.link
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setTimeout(() => {
        setpaymentLoading(false)
      }, 5000);
      

    });
  }

  useEffect(() => {
    if (step === 1) {
      getAccountData()
    }
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [step])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.isDemo, props.btnname, props.issmall])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>{props.isToken ?
      <Button animated='fade' fluid color='red' size={props.issmall ? "tiny" : "medium"} disabled={false} onClick={() => props.isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : preGenerateData(props.stype)}>
        <Button.Content visible>
          {props.btnname}
        </Button.Content>
        <Button.Content hidden>
          DOBRA DECYZJA!
        </Button.Content>
      </Button>
      : <Button animated='fade' fluid color='violet'  size={props.issmall ? "tiny" : "medium"} disabled={false} onClick={() => props.isDemo ? addToast('Zablokowane na koncie testowym', { appearance: 'warning' }) : preGenerateData(props.stype)}>
        <Button.Content visible  >
          {props.btnname}
        </Button.Content>
        <Button.Content hidden >
          DOBRA DECYZJA!
        </Button.Content>
      </Button>}
      <Modal
        open={open}
        size='large'
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='globe' color="blue" />
                  <Header.Content>Płatność</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
          {authContext.paymentBlock && props.buyProductType !== 5 ? <Modal.Description style={{ backgroundColor: "#f5f5f5" }} >
            <Container textAlign="center">
              <Icon name="warning sign" size='big' /><br /><br />
              <b>Drogi Użytkowniku,</b>
              <br /><br />
              Informujemy, że na Twoim koncie widnieją zaległości płatnicze.<br />
              W związku z tym wszystkie inne płatności zostały tymczasowo zablokowane do czasu uregulowania zaległych należności.
              <br /><br />

              Aby odblokować dostęp do płatności:<br /><br />

              - Kliknij zakładkę <b>MOJE KONTO</b><br />
              - Kliknij zakładkę <b>MOJE PŁATNOŚCI</b><br />
              - Ureguluj zaległe opłaty.<br />
              <br />
              Po dokonaniu płatności blokada zostanie automatycznie zdjęta.
              <br /><br />
              Jeśli masz pytania lub potrzebujesz pomocy, skontaktuj się z Nami.
              <br /><br />
              Dziękujemy za szybkie działanie.
              <br />
              Zespół Turniej-Generator
            </Container>
          </Modal.Description> : checkPayIsOff() ? <Modal.Description style={{ backgroundColor: "#f5f5f5" }} >
            <Container textAlign="center">
              <b>Drogi Użytkowniku,</b>
              <br /><br />
              Informujemy, że system płatności w naszej aplikacji jest chwilowo niedostępny z powodu planowanego wyłączenia.
              <br /><br />
              Przerwa potrwa maksymalnie 1–2 godziny. Prosimy o ponowne sprawdzenie dostępności płatności wkrótce.
              <br /><br />
              Przepraszamy za wszelkie niedogodności i dziękujemy za Twoje zrozumienie.
              <br /><br />
              Zespół Turniej-Generator
            </Container>
          </Modal.Description> :
            <Modal.Description style={{ backgroundColor: "#f5f5f5" }}>
              <StepGroup fluid ordered size='mini'>
                <Step completed={step > 0 ? true : false} active={step === 0 ? true : false}>
                  <StepContent>
                    <StepTitle>Dane do płatności</StepTitle>
                    <StepDescription style={{ margin: 0, padding: 0 }}>Wymagane</StepDescription>
                  </StepContent>
                </Step>

                <Step completed={step > 1 ? true : false} active={step === 1 ? true : false}>
                  <StepContent>
                    <StepTitle>Podsumowanie</StepTitle>
                    <StepDescription style={{ margin: 0, padding: 0 }}>Płatności</StepDescription>
                  </StepContent>
                </Step>

              </StepGroup>

              {step === 0 ? <ChangeMyData paymentView={true} setstep={setstep} /> : ""}
              {step === 1 ? <> <Button fluid size='mini' onClick={() => setstep(0)}>
                WSTECZ
              </Button>

                {ad  ? <div>

                  <Grid stackable style={{ marginTop: 20 }}>
                    <Grid.Row divided>
                      {ad.buyer_is_payer ? <Grid.Column width={6} textAlign="right">
                        <b>Dane do faktury</b><br />
                        {ad.company === 0 ? <div>
                          {ad.user_name} {ad.user_surname} <br />
                          {ad.place}, {ad.street}<br />
                          {ad.postal_code}, {ad.postal}<br />
                        </div> : <div>
                          {ad.company_name}  <br />
                          {ad.nip} <br />
                          {ad.place}, {ad.street}<br />
                          {ad.postal_code}, {ad.postal}<br />
                        </div>}


                      </Grid.Column> : <><Grid.Column width={3} textAlign="right">
                        <b>Dane do faktury <br />Nabywca</b><br />
                        <div style={{marginTop:5}}>
                          {ad.company_name}  <br />
                          {ad.nip} <br />
                          {ad.place}, {ad.street}<br />
                          {ad.postal_code}, {ad.postal}<br />
                        </div>


                      </Grid.Column>
                      <Grid.Column width={3} textAlign="right">
                        <b>Dane do faktury <br />Płatnik</b><br />
                        <div style={{marginTop:5}}>
                          {ad.payer_company_name}  <br />
                          {ad.payer_nip} <br />
                          {ad.payer_place}, {ad.payer_street}<br />
                          {ad.payer_postal_code}, {ad.payer_postal}<br />
                        </div>


                      </Grid.Column></> }
                      
                      <Grid.Column width={10} textAlign="left" >
                        <b>Do zapłaty</b><br />
                        {props  ? <>
                    
                          <div style={{ lineHeight: "20px", marginTop: 10 }}>
                            <span style={{ fontSize: 13 }}>{bruttoToNetto(props.price)} PLN <span style={{ fontSize: 12 }}>netto</span></span><br />
                            <span style={{ fontSize: 22, fontWeight: "bold" }}>{props.price} PLN <span style={{ fontSize: 16 }}>brutto</span></span>

                          </div>

                        </> : ""}
                        <br />
                        <Button fluid color='blue' disabled={props.price === null || paymentLoading ? true : false} loading={paymentLoading} onClick={() => generateData(props.stype, true)}>PRZEJDŹ DO PŁATNOŚCI</Button>


                      </Grid.Column>
                    </Grid.Row>
                  </Grid>





                </div> : loadingShow()}





              </> : ""}

            </Modal.Description>
          }
        </Modal.Content>
        <Modal.Actions>
          {step === 0 ? <Button onClick={() => setopen(false)}>ZAMKNIJ</Button> : ""}
        </Modal.Actions>
      </Modal>
    </>

  )
}

export default BuyProductCompany;