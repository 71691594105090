import { dateCheck } from "../../myfunctions";
import axios from '../../axios';


export async function getReportAmountForArbiter(form, setform, addToast, person_id, tournament_id, subtournament_id) {
    try {     
        let subTournamentJsonList = []
        if (form.dateRange[0] === null || form.dateRange[1] === null) {
            addToast('Wprowadź zakres dat', { appearance: 'warning' });
            return
        }
        setform({ ...form, loading: true })
        const res = await axios.post("/tournament/getTournamentData", { tournament_id: Number(tournament_id) }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (res) {
            let arrTmp = []
            if (res.data.subtournaments) {

                for (let i = 0; i < res.data.subtournaments.length; i++) {
                    if (res.data.subtournaments[i].subtournament_type === "LEAGUE" && (subtournament_id === 0 || (subtournament_id > 0 && res.data.subtournaments[i].subtournament_id === subtournament_id))) {
                        const resSub = await axios.post("/tournament/getSubtournamentData", { tournament_id: Number(tournament_id), subtournament_id: Number(res.data.subtournaments[i].subtournament_id) }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        if (resSub) {

                            if (resSub.data) {
                                arrTmp.push({ ...resSub.data })
                            }
                        } else {

                        }
                    }


                }

            }
    
            subTournamentJsonList = [...arrTmp]
        } else {
            subTournamentJsonList = []
        }

        let sumAmount = 0
        if (subTournamentJsonList && subTournamentJsonList.length > 0) {
            subTournamentJsonList.forEach(element => {
                if (element.tournament_json && element.tournament_json.length > 0) {
                    element.tournament_json.forEach(element1 => {
                        if (element1.matches && element1.matches.length > 0) {
                            element1.matches.forEach(element2 => {
                                if (element2.match_date && checkToAccNominationConfirmed(element2, person_id) && dateCheck(form.dateRange[0], form.dateRange[1], element2.match_date)) {
                                    sumAmount = sumAmount + Number(getAmount(element2, person_id))
                                }
                            });
                        }
                    });
                }
            });
        }

        setform({ ...form, valueSum: sumAmount, loading:false })
    } catch (e) {
        console.log(e)
        setform({ ...form, valueSum: -1, loading:false })
    }

}

export function checkNominationNotConfirmedNotPersonID(line) {
    try {
        let ret = false
        if (line.match_info) {

            Object.keys(line.match_info).forEach(function (key) {
                if (line.match_info[key].type === "moduleJudges" && line.match_info[key].person_id !== null && line.match_info[key].confirmed === false) {
                    ret = true
                    return true
                }
            })
        }
        return ret
    } catch (e) {
        return false
    }
}

export function checkToAccNominationConfirmed(line, person_id) {
    try {
        let ret = false
        if (line.match_info) {

            Object.keys(line.match_info).forEach(function (key) {
                if (line.match_info[key].type === "moduleJudges" && line.match_info[key].person_id !== null && line.match_info[key].confirmed === true && line.match_info[key].person_id === person_id) {
                    ret = true
                    return true
                }
            })
        }
        return ret
    } catch (e) {
        return false
    }
}

export function getAmount(line, person_id) {
    try {
        let ret = ""
        if (line.match_info) {

            Object.keys(line.match_info).forEach(function (key) {
                if (line.match_info[key].type === "moduleJudges" && line.match_info[key].person_id === person_id) {
                    ret = line.match_info[key].amount
                    return line.match_info[key].amount
                }
            })
        }
        return ret
    } catch (e) {
        return ""
    }
}