import React, { useState, useEffect, useContext } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button, Icon, Input, Message, Form, Table, Modal, Grid, Header, Image } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';
import axios from '../axios';
import AuthContext from '../context/authContext';
import { txtCase } from '../myfunctions';

function ImportMembers(props) {

  const [isOpen, setisOpen] = useState(false)
  const [loadingFile, setloadingFile] = useState(false)
  const authContext = useContext(AuthContext);
  const [showError, setshowError] = useState({ show: false, body: null })

  useEffect(() => {
   
    setArray([])
    setshowError({ show: false, body: null })
    if (props.openMembers.open) {
      setisOpen(true)
    } else {
      setisOpen(false)
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openMembers])// eslint-disable-line react-hooks/exhaustive-deps


  function closeModal() {
    setisOpen(false)
    props.setopenMembers({ open: false, data: null })
  }

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setArray([])
    setFile(e.target.files[0]);

  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      setloadingFile(true)
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
      setloadingFile(false)
    }
  };

  const csvFileToArray = string => {
    if (string.indexOf(";") > -1) {
      setshowError({ show: false, body: null })
      const csvRows = string.replaceAll("\r", "").split("\n");

      if (csvRows && csvRows.length > 0) {
        let array = []
        let rowCount = 1
        for (const element of csvRows) {
          if (rowCount > 100) {
            break;
          }
          try {
            const values = element.split(";");
            console.log(values)
            if (values.length === 4 && values[0] !== "" && values[1] !== "") {
              // brak maila
              array.push({ lp: rowCount, name: values[0], surname: values[1], additional_info: values[2].trim() === "" ? null : values[2], email: values[3].trim() === "" ? null : values[3], iserror: 0, import: null })
            } else {
              // bledy rekord
              array.push({ lp: rowCount, name: null, surname: null, email: null, iserror: 1, import: null })
            }

          } catch (e) {
            array.push({ lp: rowCount, name: null, surname: null, email: null, iserror: 1, import: null })
          }
          rowCount++
        }



        setArray(array);
      } else {
        setshowError({ show: true, body: 'Brak rekordów do zaimportowania' })
      }

    } else {
      setshowError({ show: true, body: 'Nieprawidłowy plik csv, brak ";" średnika jako delimitera' })
    }
  };

  async function addMemberToTournament(subtournament_id, element, name, email,additional_info) {
    try {
      const res = await axios.post("/generator/addMemberToTournament", { subtournament_id: subtournament_id, name: name, email: email,additional_info:additional_info, source_guest: false, limit_users: 999 }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.data.message)
        .catch((error) => error.response.data.message);
      ;
      if (res) {
        return { ...element, import: res }
      } else {
        return { ...element, import: "Bład importu [1]" }
      }

    } catch (err) {
      return { ...element, import: "Bład importu [2]" }
    }
  }


  async function startImportCSV() {
    ///generator/addMemberToTournament {"subtournament_id":2215,"name":"3 3","email":"","source_guest":false,"limit_users":999}
    let subtournament_id = props.openMembers.data.subtournament_id
    let tokendata1 = "Bearer " + authContext.token;
    axios.defaults.headers.common['Authorization'] = tokendata1;
    let arr = []

    setloadingFile(true)
    for (let i = 0; i < array.length; i++) {
      if (array[i].iserror === 0) {
        let name = txtCase(array[i].name.trim()) + " " + txtCase(array[i].surname.trim())
        let result = await addMemberToTournament(subtournament_id, array[i], name, array[i].email,array[i].additional_info)
        arr.push(result)
      } else {
        arr.push(array[i])
      }
    }
    setloadingFile(false)
    setArray(arr)
    authContext.changeRefreshView(authContext.refreshView + 1)

  }

  function checkRowIsErr(val) {
    if (val !== null && val.indexOf("oprawnie dodano uczestnika") > -1) {
      return false
    } else if (val !== null && val.indexOf("kazany uczestnik jest już dodany") > -1) {
      return false
    } else if (val === null) {
      return false
    } else {
      return true
    }
  }

  return (

    <Modal
      onClose={() => closeModal()}
      open={isOpen}
      size='fullscreen'

    >
      <Modal.Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h3' textAlign='center'>
                <Icon name='upload cloud' color="red" />
                <Header.Content>Import uczestników</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" only='large screen'>
              <Image alt="logo" floated='right' size='big' style={{ maxWidth: 200 }} centered src={LogoTeamLigaGenerator} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>
      <Modal.Content scrolling>
        {props.openMembers.data && props.openMembers.data.member_type === "USER" ? <>
          {showError.show ?
            <Message negative>
              <Message.Header>Błąd!</Message.Header>
              <p>{showError.body}</p>
            </Message> : ""}
          <Message size='mini'>
            Przykładowy plik do importu: <a className={"tllink cursorPointer"} href='https://turniej-generator.pl/import/importTG.csv'> importTG.csv </a> <br />
            1. Dane w pliku muszą być rozdzielone średnikami ; Importujemy tylko pliki csv.<br />
            2. W każdej lini muszą być trzy średniki!<br />
            3. Plik zawiera max 100 lini oraz musi być w kodowaniu UTF-8.<br />
            4. Dane w pliku to: <b>Imie;Nazwisko;Opcjonalnie dodatkowa informacja;Opcjonalnie Email</b>
          </Message>

          <Form size='mini'>
            <Form.Field>
              <label>Plik do importu</label>
              <Input
                type={"file"}
                id={"csvFileInput"}

                accept={".csv"}
                onChange={handleOnChange}
              />

            </Form.Field>

            <Button size='mini' fluid loading={loadingFile} disabled={loadingFile}
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            >
              ZAŁADUJ PLIK CSV
            </Button>
          </Form>
          <br />

          <div style={{ overflowX: "auto", width: "100%" }} >
            <Table singleLine size='small' celled striped compact unstackable >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} textAlign='center'>Poprawny rekord</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign='center'>Linia</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Imie</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Nazwisko</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Dodatkowa informacja</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>E-mail</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign='center'>Status importu</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {array.map((row, i) =>
                  <Table.Row key={i} negative={checkRowIsErr(row.import)}>
                    <Table.Cell width={1} textAlign='center'>{row.iserror === 0 ? <Icon name="check" color='green' /> : <> <Icon name="x" color='red' /> POPRAW</>}</Table.Cell>
                    <Table.Cell width={1} textAlign='center'>{row.lp}</Table.Cell>
                    <Table.Cell textAlign='center'>{row.name}</Table.Cell>
                    <Table.Cell textAlign='center'>{row.surname}</Table.Cell>
                    <Table.Cell textAlign='center'>{row.additional_info}</Table.Cell>
                    <Table.Cell textAlign='center'>{row.email}</Table.Cell>
                    <Table.Cell textAlign='center' style={{ overflow: "auto", maxWidth: 500 }}>{row.import === null ? "" : row.import}</Table.Cell>

                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          <Message size='mini'>
            Tylko rekordy ze statusem <Icon name="check" color='green' />będą zaimportowane
          </Message>

        </> : "IMPORT DLA DRUŻYN NIE JEST WSPIERANY"}
      </Modal.Content>
      <Modal.Actions>

        <Button size="tiny" color='black' onClick={() => closeModal()}>
          ZAMKNIJ
        </Button>

        <Button size="tiny" floated='left' color='green' disabled={(array && array.length < 1) || loadingFile ? true : false} loading={loadingFile} onClick={() => startImportCSV()}>
          ROZPOCZNIJ IMPORT
        </Button>
      </Modal.Actions>
    </Modal>



  )
}

export default ImportMembers;