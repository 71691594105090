import React, { useEffect,  useState, useRef } from 'react';
import { Button, Grid, Header, Icon, Image, Input, Modal, Table } from 'semantic-ui-react';

import useWindowDimensions from '../hooks/useWindowDimensions';
import { getTabByFiltr, saveCsvTab } from '../myfunctions';

import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';

import { useReactToPrint } from 'react-to-print';

function ShowLIstMembersInTeam(props) {


  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)

  const [listAllM, setlistAllM] = useState({ current: null,  data: null })

  useEffect(() => {

    if (open) {
      createListM()
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.subTournamentJson, open])// eslint-disable-line react-hooks/exhaustive-deps


  function createListM() {
    try {

      let tmparr = []
      let prevName = ""
      if (props.subTournamentJson.members && props.subTournamentJson.members.length > 0) {
        props.subTournamentJson.members.forEach(element => {


          if (element.members_list && element.members_list.length > 0) {

            element.members_list.forEach(element1 => {
              let new_page = 0
              if (prevName !== element.name) {
                prevName = element.name
                new_page = 1
              }
              tmparr.push({ team_name: element.name, member_name: element1.member_name, other_info: element1.jersey_nr ?  "Numer: "+element1.jersey_nr : "", new_page: new_page })

            });
          } else {
            let new_page = 0
            if (prevName !== element.name) {
              prevName = element.name
              new_page = 1
            }
            tmparr.push({ team_name: element.name, member_name: "-", other_info: null, new_page: new_page })
          }

          if (element.staff_list && element.staff_list.length > 0) {
            element.staff_list.forEach(element1 => {
              let new_page = 0
              if (prevName !== element.name) {
                prevName = element.name
                new_page = 1
              }
              tmparr.push({ team_name: element.name, member_name: element1.person_name, other_info: element1.staff_function, new_page: new_page })

            });
          }

        });
      }

      setlistAllM({ current: tmparr,  data: tmparr })
    } catch (e) {
      console.log(e)
    }
  }


  function exitModal() {
    setOpen(false)
  }


  const componentRefPrintUsers = useRef();
  const handlePrintTabUsers = useReactToPrint({
    content: () => componentRefPrintUsers.current,
  });


  return (
    <>
      <Icon name="users" className='cursorPointer tllink' title="LISTA ZAWODNIKÓW W DRUŻYNIE" bordered floated onClick={() => setOpen(true)} />
      <Modal
        open={open}
        centered={width < 900 ? false : true}
        size='large'


      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='left'>
                  <Icon name='users' color="red" />
                  <Header.Content>Lista uczestników

                  </Header.Content>

                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" >
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >
          <Modal.Description >


            {listAllM.current ? <>
              <div ref={componentRefPrintUsers}>
                KATEGORIA: <b>{props.subTournamentJson && props.subTournamentJson.name ? props.subTournamentJson.name : ""}</b>
                <br/> <br/>
                <style type="text/css" media="print">
                                {" @page { size: portrait; } "}
                            </style>
             
                <Grid className='hiddenPrint' style={{margin:0,padding:0}}>
                  <Grid.Row columns={2} style={{margin:0,padding:0}}>
                    <Grid.Column style={{margin:0,padding:0}}>
                    <Input size='mini' style={{ marginBottom: 10 }}  icon='search' onChange={(e, d) => getTabByFiltr(d.value, listAllM, setlistAllM, 10000)} placeholder={"Szukaj... min 3 znaki"} />

                    </Grid.Column>
                    <Grid.Column textAlign='right' style={{margin:0,padding:0}}>
                    <Icon className='cursorPointer tllink' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTabUsers} />
                  {saveCsvTab(listAllM.current, "uczestnicy.csv")}
               
                      </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Table style={{ marginTop: 0 }} singleLine size='small' celled striped compact unstackable >
    <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2} textAlign='center'>DRUŻYNA</Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign='center'>OSOBA</Table.HeaderCell>
                  
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listAllM.current.map((row, i) =>
                      <Table.Row key={i}   >
                       
                        <Table.Cell textAlign='center'>{row.team_name}</Table.Cell>
                        <Table.Cell textAlign='center'>{row.member_name} {row.other_info ? <>({row.other_info})</> : ""} </Table.Cell>


                      </Table.Row>
                    )}

                  </Table.Body>
                </Table>
              </div>
            </> : "BRAK DANYCH"}

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>



          <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ShowLIstMembersInTeam;